import React from 'react'
import PromoBoxBanners from './PromoBoxBanners'
import { sampleSize } from 'lodash'

class PromoBox extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            current_big: 0,
            current_big_instant: 0,
            current_small: 0,
            auto: true,
            move: false,
            bigBanners: this.sampleBigBanners()
        }
    }

    preload_images = (banners) => {
        for (var i = 0; i < banners.length; ++i) {
            var img = new Image();
            img.src = banners[i].image;
        }
    };

    componentDidMount() {
        this.preload_images(this.state.bigBanners)
        this.preload_images(this.props.small_banners)
        this.timer = setInterval(this.tick, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    tick = () => {

        // this.setState({elapsed: new Date() - this.props.start});
        if (this.state.auto) {
            this.handle_next()
        }
    };

    // handlePage: function(idx) {
    //     this.setState({ current: idx})
    // },
    get_next_small = (current_small) => {
      return (current_small == 0) ?  this.props.small_banners.length - 1 : current_small - 1
    };

    handle_next = () => {
        var big = this.state.current_big,
            small = this.state.current_small,
            sum = 0

        this.setState({ move: true })

        big = big == this.state.bigBanners.length - 1 ? 0 : big + 1

        while(sum < 3){
          small = this.get_next_small(small)
          sum += this.props.small_banners[small].spaces
        }


        this.setState({ current_big_instant: big })
        setTimeout(function() {
            this.setState({ current_big: big, current_small: small, move: false })

        }.bind(this), 2000)
    };

    handle_change_big_to = (idx) => {
        this.setState({ current_big: idx, current_big_instant: idx })
    };

    disable_auto = () => {
        this.setState({ auto: false })
    };

    enable_auto = () => {
        this.setState({ auto: true })
    };

    slice_banners = (banners, current, size) => {
        if(current === 0 || banners.length < size)
          return banners;

        var toIdx = banners.slice(0, current)
        var fromIdx = banners.slice(current, banners.length)
        return fromIdx.concat(toIdx)
    };

    slice_big_banners = (banners, current) => {
        if (banners.length <= 1) {
            return banners
        }

        var slice = [],
            next_banner_idx = current < banners.length - 1 ? current + 1 : 0

        slice.push(banners[current])
        slice.push(banners[next_banner_idx])

        return slice
    };

    sampleBigBanners = () => {
        return sampleSize(this.props.big_banners, 10)
    }

    render() {
        const bigBanners = this.state.bigBanners

        if (bigBanners.length < 1 || this.props.small_banners.map(a => a.spaces).reduce((a, b) => a + b, 0) < 3) {
            return null
        }

        var big_banners = this.slice_big_banners(bigBanners, this.state.current_big, 1)
        var small_banners = this.slice_banners(this.props.small_banners, this.state.current_small, 3)

        return (
            <div className="promobox" onMouseEnter={ this.disable_auto } onMouseLeave={ this.enable_auto } >
                <PromoBoxBanners className="promobox-bigs" banners={ big_banners } rotating={ big_banners.length > 1 } move={ this.state.move } current={ this.state.current_big_instant } size={ bigBanners.length } on_change_to={ this.handle_change_big_to } data_layer="homepage/promobox big"/>

                <PromoBoxBanners className="promobox-smalls" banners={ small_banners } rotating={ small_banners.map(a => a.spaces).reduce((a, b) => a + b, 0) >= 6 } move={ this.state.move } data_layer="homepage/promobox small"/>

            </div>
        )
    }
}

export default PromoBox
