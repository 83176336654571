const INTERNAL_HOSTS = [
  'pluginboutique.com',
  'loopcloud.com',
  'loopmasters.com'
]

export default (link) => {
  let url

  try {
    url = new URL(link)
  } catch (_) {
    return false
  }

  let host = url.host.replace('www.', '')

  if (host === window.location.host) {
    return false
  } else {
    return !INTERNAL_HOSTS.includes(host)
  }
}
