import React from 'react'
import UserRateInfo from './UserRateInfo'
import UserRateButton from './UserRateButton'

class UserRate extends React.Component {
  state = { order_item: this.props.order_item, fresh: false };

  componentDidMount() {
    Mess.subscribe('refresh_rating', this.refresh_rating)
    Mess.subscribe('version_changed', this.handleVersionChange)
  }

  handleVersionChange = (data) => {
    this.setState({
      order_item: data
    })
  }

  refresh_rating = (data) => {
    if (data.order_item_id == this.state.order_item.order_item_id) {
      this.setState({ order_item: data, fresh: true })
    }
  };

  handleClick = () => {
    Mess.publish('show_rating_form', this.props.order_item.order_item_id)
  };

  render() {
    const rentToOwn = !!this.props.rentToOwn
    const order_item = this.state.order_item

    const rating = order_item.rating
    const cashUsed = !!order_item.cash_used

    const commonProps = {
      rentToOwn: rentToOwn,
      canEarnVC: order_item.can_earn_vc
    }

    return rating ? (
      <UserRateInfo {...commonProps} value={rating.overall} fresh={this.state.fresh } />
    ) : (
      <UserRateButton {...commonProps} onClick={ this.handleClick } />
    )
  }
}

export default UserRate
