import React from 'react'
import PropTypes from 'prop-types';

class UserProductDownloadVersions extends React.Component {
    static propTypes = {
        order_item: PropTypes.object
    };

    handleChange = (event) => {
        var version = this.refs.version.value

        this.props.onChange(version)
    };

    render() {
        var versions = this.props.order_item.versions,
            selected_marked = false

        return (
            <div className="userproduct-download-versions">
                <form className="lform">
                    <div className="select">
                        <select name="version" ref="version" onChange={ this.handleChange }>
                            { _.map(versions, function(data, idx) {
                                var selected = false
                                if (!selected_marked && !data.new_version) {
                                    selected = true
                                    selected_marked = true
                                }
                                return <option key={ data.version } value={data.version} selected={ selected }>Ver. { data.version } { data.new_version ? ' new!' : '' }</option>
                            }) }
                        </select>
                    </div>
                </form>
            </div>
        )
    }
}

export default UserProductDownloadVersions
