import React from 'react'
import GalleryContentYoutube from './GalleryContentYoutube'
import GalleryContentAudio from './GalleryContentAudio'
import { setFallbackImage } from '../src/actions/setFallbackImage'

class GalleryContent extends React.Component {
  prevent = (event) => {
      // event.stopPropagation()
      // event.preventDefault()
  };

  onErrorImageMissing = (event) => {
      setFallbackImage(event, this.props.fallbackImageSrc)
  };

  render() {
      if (this.props.kind == 'youtube') {
          return <GalleryContentYoutube { ...this.props } />
      }

      if (this.props.kind == 'audio') {
          return <GalleryContentAudio { ...this.props } />
      }

      return (
          <div className="gallery-content" onTouchMove={ this.prevent }>
              <div className="gallery-content-inner">
                  <img src={ this.props.src || '/' } onError={(event) => this.onErrorImageMissing(event)} alt={ this.props.desc }/>
              </div>
          </div>
      )
  }
}

export default GalleryContent
