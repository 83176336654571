import React from 'react'
import PromoBoxPagingItem from './PromoBoxPagingItem'

class PromoBoxPaging extends React.Component {
    render() {
        var clazz, items = [], current = this.props.current, size = this.props.size
        for (var i = 0; i < size; i++) {
            clazz = i == current ? '_selected' : ''
            items.push(<PromoBoxPagingItem key={ i } idx={ i } className={ clazz } on_change_to={ this.props.on_change_to }/>)
        }

        return (
            <ul className={ '_paging' + (this.props.visible ? ' _visible' : '') }>
                { items }
            </ul>
        )
    }
}

export default PromoBoxPaging
