import React from 'react'

class CourseModule extends React.Component {
    state = { expanded: false };

    handlePlay = (event) => {
        event.stopPropagation()
        this.props.onClick(this.props.idx)
    };

    handleToggleExpand = () => {
        this.setState({ expanded: !this.state.expanded })
    };

    render() {
        var clazz = this.props.playing ? ' --playing' : ''
        var module = this.props.module

        var expanderClazz = 'courseplayer-module-expander' + (this.state.expanded ? ' --expanded' : '')
        var expanderDescription = this.state.expanded ? 'hide' : 'info'

        return (
            <li key={ this.props.key } className={ clazz } onClick={ this.handleToggleExpand }>

                { this.props.playing ?
                    <span className="courseplayer-module-playing">playing</span> :
                    <span onClick={ this.handlePlay } className="courseplayer-module-play">play</span> }

                <span className={ expanderClazz } >{ expanderDescription }</span>

                <div className="courseplayer-module-time"></div>

                <div className="courseplayer-module-meta">
                    <h3>{ module.name }</h3>

                    { this.state.expanded ? <div className="courseplayer-module-description" dangerouslySetInnerHTML={{__html: module.description}} /> : null }
                </div>
            </li>
        )
    }
}

export default CourseModule
