import React from 'react'

class UserOrderMeta extends React.Component {
  render() {
      return (
          <div className="userorder-meta">
              <h2>Order No: { this.props.order.order_id}</h2>
              <h3>Order Date: { this.props.order.sold_at }</h3>
          </div>
      )
  }
}

export default UserOrderMeta
