import React from 'react'
import SamplepacksDownloadsItem from './SamplepacksDownloadsItem'
import csrfToken from '../src/csrf_token'

class SamplepacksDownloads extends React.Component {
    state = { downloads: this.props.downloads, processing: false };

    componentDidMount() {
        Mess.subscribe('refresh_samplepacks_download', this.handle_refesh)
    }

    handle_refesh = () => {

        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: `/users/samplepacks/`,
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: (data) => this.setState({ downloads: data, processing: false }),
            error: () => this.setState({ processing: false })
        })
    };

    render() {
        let clazz = 'samplepacksdownloads' + ( this.state.processing ? ' processing' : '' )

        return (
            <ul className={ clazz }>
                {
                    _.map(this.state.downloads, function(item) {
                        return <SamplepacksDownloadsItem item={ item } key={ 'samplepack-' + item.product_id } />
                    }.bind(this))
                }
            </ul>
        )
    }
}

export default SamplepacksDownloads
