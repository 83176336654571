import React from 'react'
import PropTypes from 'prop-types';

class UserUpdateInfo extends React.Component {
    static propTypes = {
        item: PropTypes.object.isRequired
    };

    render() {
        var item = this.props.item

        var content = (item.free === true) ? 'This is a free update. When you click the DOWNLOAD button you will start downloading. The download will match your last OS selection.' : 'This is a paid upgrade. Please click the Add To Cart button and go through the checkout as you would with a Full Version of a product.'

        return (
            <div className="userupdate-info">
                <strong> New Update Version Released: { item.update_version } </strong>
                <p> { content } </p>
            </div>
        )
    }
}

export default UserUpdateInfo
