// based on: https://gist.github.com/Yitsushi/e3b7823f7d4bf34faa4f
global.Mess = (function() {
  var self = this;

  self.queue = {};

  return {
    publish: function (event, data) {
      var queue = self.queue[event];

      if (!queue) {
        return false;
      }

      for (var i = queue.length - 1; i >= 0; i--) {
          (queue[i])(data)
      };

      return true;
    },

    subscribe: function(event, callback) {
      if (typeof self.queue[event] === 'undefined') {
        self.queue[event] = [];
      }

      self.queue[event].push(callback);
    },

    // todo: unsubscribe needed?

  };
}());
