import React from 'react'
import csrfToken from '../src/csrf_token'

class WishlistAddTile extends React.Component {
  state = {
    progress: false,
    in_wishlist: this.props.in_wishlist || false
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidMount() {
    Mess.subscribe('refresh_wishlist', this.refreshWishlistState.bind(this))
  }

  refreshWishlistState(data) {
    const productCourseItems = data.cart.cartItems.map(item => {
      if (item.product !== undefined) {
        return item.product
      } else {
        return item.course
      }
    })

    productCourseItems.map(item => {
      if (item.in_wishlist === true && (item.course_id === this.props.item_id || item.product_id === this.props.item_id)) {
        this.setState({
          in_wishlist: true
        })
      }
    })
  }

  handle_add_to_wishlist(event) {
    event.preventDefault()

    this.setState({ progress: true })

    $.ajax({
      url: '/wishlists/add_to_wishlist',
      type: 'POST',
      dataType: 'json',
      data: {
        id: this.props.item_id
      },
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
        Mess.publish('show_flash', data)
        Mess.publish('refresh_wishlist', data)
        this.setState({ progress: false, in_wishlist: !!data.notice })
        fbq('track', 'AddToWishlist', {
            content_ids: [this.props.item_id]
        })
      }.bind(this),
      error: function(xhr, status, err) {
        console.error(this.props.url, status, err.toString())
      }.bind(this)
    })
  }

  render() {
    var clazz = 'producttile-foot-wishlist' + (this.state.in_wishlist ? ' --in' :'') + (this.state.progress ? ' --progress' : '')

    return (
      this.state.in_wishlist ? <a href="/wishlist" className={ clazz }>In Wishlist</a> :
      <a rel="nofollow"
         href="#add_to_wishlist"
         title={ `Add ${this.props.item_name} to wishlist` }
         className={ clazz }
         onClick={ this.handle_add_to_wishlist.bind(this) }>
         Wishlist
      </a>
    )
  }
}

export default WishlistAddTile
