import React from 'react'

class CourseDownload extends React.Component {
    render() {
        return (
            <li key={ this.props.download.course_download_id } >
                <h3>{ this.props.download.name }</h3>
                <a href={ this.props.download.url } className="button-download" title="Downoad this course">Download</a>
            </li>
        )
    }
}

export default CourseDownload
