import React from 'react'
import csrfToken from '../../src/csrf_token'
import CartProductItem from './CartProductItem'
import CartGiftItem from './CartGiftItem'
import CartRecommendedItem from './CartRecommendedItem'

class CartContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      cartItems: this.props.cartItems,
      modalOpen: false,
      recommendedProducts: [],
      frMeta: {}
    }

    this.refreshCart = this.refreshCart.bind(this)
  }

  componentDidMount() {
    Mess.subscribe('refresh_wishlist', this.refreshCart)
    const { recommendationsService } = this.props

    if (recommendationsService === 'fresh_relevance') {
      this.fetchFreshRelevanceRecommendations()
    }
  }

  refreshCart(data) {
    this.setState({
      cartItems: data.cart.cartItems
    })
  }

  async fetchFreshRelevanceRecommendations() {
    try {
      const { recommendationSlotId } = this.props
      const params = new URLSearchParams({ slot: recommendationSlotId })
      const fullPath = `/api/v1/fr_products?${params}`
      const opts = {
        method: 'GET',
        headers: {
          'X-CSRF-Token': csrfToken(),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }

      const response = await fetch(fullPath, opts)

      if (!response.ok) {
        this.setState({ recommendedProducts: [] })
      } else {
        const data = await response.json()
        this.setState({ recommendedProducts: data.products, frMeta: data.fr_meta })
      }
    } catch (_error) {
      this.setState({ recommendedProducts: [] })
    }
  }


  render() {
    const userName = this.props.userName
    const productsCount = this.state.cartItems.length

    return (
      <div className="CartContainer">
        <div className="CartContainer-title">
          {userName ? `Hey ${userName}, here's your Cart` : 'Your Cart'}
        </div>

        <div className="CartContainer-itemsCount">
          {productsCount} {productsCount === 1 ? 'Item' : 'Items'}
        </div>

        <div className="CartContainer-listHeader">
          <div className="CartContainer-listHeader-columnProduct">Products</div>
          <div className="CartContainer-listHeader-columnPrice">Price</div>
          <a className="CartContainer-listHeader-columnClear" href="/orders/all/remove_items" data-method="delete">Clear cart</a>
        </div>

        <div className="CartContainer-itemsList">
          {
            this.state.cartItems.map(item => {
              return <CartProductItem key={item.order_item_id} item={item} fallbackImageSrc={this.props.fallbackImageSource} />
            })
          }
          {(this.props.gifts.length > 0) && (
            <CartGiftItem fallbackImageSrc={this.props.fallbackImageSource}
                          gifts={this.props.gifts}
                          selectedGift={this.props.selectedGift}
                          totalCash={this.props.totalCash} />
          )}

          <CartRecommendedItem
            fallbackImageSrc={this.props.fallbackImageSource}
            recommendedProducts={this.state.recommendedProducts}
            alreadyInCartProducts={this.state.cartItems}
            fallbackProducts={this.props.fallbackProducts}
            recommendationsService={this.props.recommendationsService}
            frMeta={this.state.frMeta}
          />
        </div>

        <div className="CartContainer-secureButton">
          <a className="CartContainer-secureButton-button button-plain" href="/checkout"><p className='CartContainer-secureButton-lock'></p>Secure Checkout</a>
        </div>
      </div>
    )
  }
}

export default CartContainer
