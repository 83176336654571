import React from 'react'
class AsSeenOnWidget extends React.Component {
  render() {
    return (
      <div className='as_seen_on_widget'>
        <div className='as_seen_on_widget_title'>
          <h3>As seen on</h3>
        </div>
        <div className='as_seen_on_widget_logos_container'>
          <div className='as_seen_on_widget_logos'>
            <p className='as_seen_on_widget_logo_1'></p>
            <p className='as_seen_on_widget_logo_2'></p>
            <p className='as_seen_on_widget_logo_3'></p>
            <p className='as_seen_on_widget_logo_4'></p>
            <p className='as_seen_on_widget_logo_5'></p>
          </div>
        </div>
      </div>
    )
  }
}

export default AsSeenOnWidget
