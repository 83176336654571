import React from 'react'
import PropTypes from 'prop-types'
import CartGiftItemModal from './CartGiftItemModal'
import CartGiftItemMoreInfoModal from './CartGiftItemMoreInfoModal'
import csrfToken from '../../src/csrf_token'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class CartGiftItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isGiftsListModalOpen: false,
      isMoreInfoModalOpen: false,
      selectedGift: this.props.selectedGift
    }

    this.openGiftsListModal = this.openGiftsListModal.bind(this)
    this.closeGiftsListModal = this.closeGiftsListModal.bind(this)
    this.openMoreInfoModal = this.openMoreInfoModal.bind(this)
    this.closeMoreInfoModal = this.closeMoreInfoModal.bind(this)
    this.selectGift = this.selectGift.bind(this)
    this.renderEmptyOrSelectedGift = this.renderEmptyOrSelectedGift.bind(this)
    this.removeGift = this.removeGift.bind(this)
  }

  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  openGiftsListModal(event) {
    event.preventDefault()

    this.setState({
      isGiftsListModalOpen: true
    })
  }

  closeGiftsListModal(event) {
    event.preventDefault()

    this.setState({
      isGiftsListModalOpen: false
    })
  }

  openMoreInfoModal(event) {
    event.preventDefault()

    this.setState({
      isMoreInfoModalOpen: true
    })
  }

  closeMoreInfoModal(event) {
    event.preventDefault()

    this.setState({
      isMoreInfoModalOpen: false
    })
  }

  selectGift(event, gift_id) {
    event.preventDefault()

    $.ajax({
      url: '/api/v1/carts/select_gift',
      dataType: 'json',
      method: 'patch',
      data: { gift_id: gift_id },
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(response) {
        this.setState({
          selectedGift: response.selected_gift,
          isGiftsListModalOpen: false,
          isMoreInfoModalOpen: false
        })
      }.bind(this),

      error: function(xhr, status, error) {
        console.log(error, status, xhr.responseJSON.error)

        this.setState({ errorMsg: xhr.responseJSON.error })
      }.bind(this)
    })
  }

  removeGift(event) {
    this.selectGift(event, '')
  }

  renderEmptyOrSelectedGift() {
    const selectedGift = this.state.selectedGift

    if (selectedGift !== null) {
      const product = selectedGift.product

      return(
        <div className="CartProductItem-listItem">
          <div className="CartProductItem-columnProduct">
            <a href={product.link_to} className="CartProductItem-imgContainer">
              <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
            </a>
          </div>
          <div className="CartProductItem-productData">
            <a className="CartProductItem-productData--name" href={product.link_to}>
              {product.name}
            </a>
            <a href={product.category.link_to} className="CartProductItem-productData--category">
              {product.category.name}
            </a>
            <span className="CartProductItem-productData--by"> by </span>
            <a href={product.manufacturer.link_to} className="CartProductItem-productData--manufacturer">
              {product.manufacturer.name}
            </a>
          </div>
          <div className="CartProductItem-columnPrice">
            <span className="CartProductItem-priceMobileText">Price:</span><span>FREE</span>
          </div>
          <div className="CartProductItem-columnClear CartProductItem-columnClearGift">
            <div onClick={this.removeGift} className="CartProductItem-removeBtn">Remove</div>
            <div className="CartProductItem-wishlistGift">
              <div onClick={e => this.openGiftsListModal(e)} className="CartProductItem-wishlistBtn">Change free gift</div>
            </div>
            <div onClick={e => this.openMoreInfoModal(e)} className="CartProductItem-moreInfoBtn">More information</div>
          </div>

          <CartGiftItemMoreInfoModal isModalOpen={this.state.isMoreInfoModalOpen}
                                     onCloseClick={this.closeMoreInfoModal}
                                     fallbackImageSrc={this.props.fallbackImageSrc}
                                     gift={selectedGift}
                                     selectGift={this.selectGift}
                                     totalCash={this.props.totalCash} />
        </div>
      )
    } else {
      return(
        <div className="CartProductItem-listItem CartProductItem-emptyItem">
          <div className="CartProductItem-productData CartProductItem-productRecommendedData">
            <div className="CartProductItem-productData--name CartRecommendedItem-title">
              Choose your free gift
            </div>
            <div className="CartProductItem-emptyItemText">
              Thanks for being a customer
            </div>
          </div>
          <div className="CartProductItem-columnClear CartProductItem-columnClearEmpty">
            <div onClick={e => this.openGiftsListModal(e)} className="CartProductItem-addGiftBtn">Claim Gift</div>
          </div>
        </div>
      )
    }
  }

  render() {
    return (
      <React.Fragment>
        { this.renderEmptyOrSelectedGift() }

        <CartGiftItemModal isModalOpen={this.state.isGiftsListModalOpen}
                           onCloseClick={this.closeGiftsListModal}
                           gifts={this.props.gifts}
                           fallbackImageSrc={this.props.fallbackImageSrc}
                           selectedGift={this.state.selectedGift}
                           selectGift={this.selectGift}
                           totalCash={this.props.totalCash} />
      </React.Fragment>
    )
  }
}

CartGiftItem.propTypes = {
  gifts: PropTypes.array.isRequired,
  selectedGift: PropTypes.object,
  fallbackImageSrc: PropTypes.string.isRequired
}

export default CartGiftItem
