import React from 'react'
import CompleteItemDownloadOses from './CompleteItemDownloadOses'
import csrfToken from '../src/csrf_token'

class CompleteItemDownload extends React.Component {
    state = {
        order_item: this.props.order_item,
        pc: this.props.order_item.os.current_pc,
        progress: false
    };

    handleOsChnage = (pc) => {
        this.setState({ pc: pc })
    };

    handleDownload = (item) => {
    };

    handleVersionChange = (version) => {
        var data = {
            id: this.state.order_item.order_item_id,
            version: version
        }

        this.setState({ progress: true })


        $.ajax({
            url: '/users/download_box',
            dataType: 'json',
            method: 'post',
            data: data,
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                if (data.error) {
                    Mess.publish('show_error', data.error)

                } else {
                    this.setState({ order_item: data.order_item, pc: data.order_item.os.current_pc })
                }
                this.setState({ progress: false })

            }.bind(this),

            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                Mess.publish('show_error', err.toString())
                this.setState({ progress: false })

            }.bind(this)
        });


    };

    render() {
        var downloads = this.state.pc ? this.state.order_item.downloads.pc : this.state.order_item.downloads.mac

        var clazz = 'completeitem-download' + (this.state.progress ? ' --progress' : '')

        return (
            <div className={clazz}>
                <CompleteItemDownloadOses onChange={ this.handleOsChnage } order_item={ this.state.order_item } pc={ this.state.pc }/>
                <div className="completeitem-download-actions">
                    {
                        _.map(downloads, function(item, idx) {

                            return (item.processing ?
                                <span className="button-download" key={ item + idx}>Processing Download...</span> :

                                // item.url could return external domain so in this case add rel="noopener noreferrer"
                                <a href={ item.url } key={ 'item' + idx} className="button-download" target="_blank" title={ '' } onClick={ this.handleDownload.bind(this, item) }>{ item.name }</a>
                            )
                        }.bind(this))
                    }
                </div>
            </div>
        )
    }
}

export default CompleteItemDownload
