import React from 'react'
import { Card1Logo, Card2Logo, Card3Logo } from './bpsCardsLogos'

class BPSCards extends React.Component {
  render() {
    return(
      <div className='BPSCards'>
        <div className='BPSCards-card BPSCards-card1'>
          <div className='BPSCards-card1-logo'>
            <Card1Logo />
          </div>
          <div className='BPSCards-card-text'>
            "A revolutionary platform that brings a new dimension to music production"
          </div>
        </div>
        <div className='BPSCards-card BPSCards-card2'>
          <div className='BPSCards-card2-logo'>
            <Card2Logo />
          </div>
          <div className='BPSCards-card-text'>
            "An impressive line-up of plugins included"
          </div>
        </div>
        <div className='BPSCards-card BPSCards-card3'>
          <div className='BPSCards-card3-logo'>
            <Card3Logo />
          </div>
          <div className='BPSCards-card-text'>
            "With a range of Frozen State Mode plugins and the continuous influx of new ones each month, Beatport Studio becomes an essential asset in any studio environment"
          </div>
        </div>
      </div>
    )
  }
}

export default BPSCards