import React from 'react'

class BuyBoxTrial extends React.Component {
    handleClick = (event) => {
        if (!this.props.trial.logged) {
            event.preventDefault()
            Mess.publish('show_error', 'You have to sign in first')
        }
        fbq('track', 'Lead');
    };

    render() {
        var trial = this.props.trial

        const clazz = this.props.is_product ? 'buybox-links-wishlist' : 'button-trial'

        return (
            trial.link ?
                <a href={ trial.link } className={clazz} title="Check trial version" onClick={ this.handleClick } data-layer={ trial.data_layer }>Trial Version</a> :
                <span className="button-no-trial"></span>
        )

    }
}

export default BuyBoxTrial
