import React from 'react'
import BPSInfo from './BPSInfo'
import BPSLabels from './BPSLabels'
import BPSFavouritePlugins from './BPSFavouritePlugins'
import BPSContentTiles from './BPSContentTiles'
import BPSPlans from './BPSPlans'
import BPSCards from './BPSCards'
import BPSFaq from './BPSFaq'
import BPSVideoModal from './BPSVideoModal'

class BPSContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isVideoModalOpen: false
    }

    this.favouritesRef = React.createRef()
    this.pricingRef = React.createRef()
    this.faqRef = React.createRef()
    this.pageTopRef = React.createRef()
    this.closeVideoModal = this.closeVideoModal.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
  }

  closeVideoModal() {
    this.setState({
      isVideoModalOpen: false
    })
  }

  openVideoModal() {
    this.setState({
      isVideoModalOpen: true
    })
  }

  handleScroll(ref) {
    const element = ref.current
    this.scrollToAnchor(element)
  }

  scrollToAnchor(element) {
    const headerOffset = document.getElementById('navbar').offsetHeight
    element.scrollIntoView({ top: `-${headerOffset}px`, behavior: "smooth" })
  }

  componentDidMount() {
    const anchorId = new URLSearchParams(new URL(location.href).search).get('section')
    const anchor = document.querySelector(`#${anchorId}`)
    if (!anchor) return

    setTimeout(() => {
      if (window.scrollY < anchor.getBoundingClientRect().top) this.scrollToAnchor(anchor)
    }, 200)
  }

  render() {
    const monthlySubscribeUrl = this.props.monthly_subscribe_url
    const annualSubscribeUrl = this.props.annual_subscribe_url

    return <>
      <BPSVideoModal isOpen={this.state.isVideoModalOpen} closeVideoModal={this.closeVideoModal} />

      <figure className='BPSContainer-body-banner' id='root'>
        <picture>
          <source media="(min-width: 2560px)" srcSet={require(`../../images/beatport_studio/hero/hero_uw@x2.png`)} />
          <source media="(min-width: 1440px)" srcSet={require(`../../images/beatport_studio/hero/hero_l@x2.png`)} />
          <source media="(min-width: 769px)" srcSet={require(`../../images/beatport_studio/hero/hero_m@x2.png`)} />
          <source media="(min-width: 0px)" srcSet={require(`../../images/beatport_studio/hero/hero_sm@x2.png`)} />
          <img
            src={require(`../../images/beatport_studio/hero/hero_m@x2.png`)}
            className='BPSContainer-body-banner-img'
          />'
        </picture>

        <figcaption className='BPSContainer-body-banner-text'>
          <div className='BPSContainer-body-banner-text-title'>
            Everything you need to make music
          </div>
          <div className='BPSContainer-body-banner-text-description'>
            Industry leading plugins, sounds, and DAWs, all included in a flexible music production membership. Anyone can record, produce, mix and master with Beatport Studio.
          </div>
          <div className='BPSContainer-body-banner-text-buttons'>
            <div onClick={() => this.handleScroll(this.pricingRef)} className='BPSContainer-body-banner-text-buttons-btn bps-btn'>
              Try for Free
            </div>
            <div onClick={() => this.openVideoModal()} className='BPSContainer-body-banner-text-buttons-link'>
              <span className='BPSContainer-body-banner-text-buttons-link-desc'>Watch video</span>
              <span className='BPSContainer-body-banner-text-buttons-link-upArrow'></span>
            </div>
          </div>
          <div className='BPSContainer-body-banner-text-checks'>
            <div className='BPSContainer-body-banner-text-checks-check'>
              <span className='BPSContainer-body-banner-text-checks-check-icon'></span>
              <span className='BPSContainer-body-banner-text-checks-check-text'>Plugins</span>
            </div>
            <div className='BPSContainer-body-banner-text-checks-check'>
              <span className='BPSContainer-body-banner-text-checks-check-icon'></span>
              <span className='BPSContainer-body-banner-text-checks-check-text'>Sounds</span>
            </div>
            <div className='BPSContainer-body-banner-text-checks-check'>
              <span className='BPSContainer-body-banner-text-checks-check-icon'></span>
              <span className='BPSContainer-body-banner-text-checks-check-text'>DAWs</span>
            </div>
          </div>
        </figcaption>
      </figure>

      <BPSInfo handleScroll={this.handleScroll} pricingRef={this.pricingRef} />
      <BPSLabels />
      <BPSFavouritePlugins reference={this.favouritesRef} />
      <BPSContentTiles handleScroll={this.handleScroll} pricingRef={this.pricingRef} />
      <BPSPlans reference={this.pricingRef} monthlySubscribeUrl={monthlySubscribeUrl} annualSubscribeUrl={annualSubscribeUrl} />
      <BPSCards />
      <BPSFaq reference={this.faqRef} />
    </>
  }
}

export default BPSContainer
