import React from 'react'
import PropTypes from 'prop-types'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class CartGiftItemModal extends React.Component {
  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  giftItem(gift) {
    const product = gift.product

    return(
      <div key={product.product_id} className="CartGiftItemModal-item">
        <a href={product.link_to} className="CartGiftItemModal-item-img">
          <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
        </a>

        <div className="CartGiftItemModal-item-info">
          <a className="CartGiftItemModal-item-info--name" href={product.link_to}>
            {product.name}
          </a>
          <a href={product.manufacturer.link_to} className="CartGiftItemModal-item-info--manufacturer">
            {product.manufacturer.name}
          </a>
          <a href={product.category.link_to} className="CartGiftItemModal-item-info--category">
            {product.category.name}
          </a>
        </div>

        <div className="CartGiftItemModal-item-price">
          <div className="CartGiftItemModal-item-price-free">
            £0.00
          </div>
          <div className="CartGiftItemModal-item-price-real">
            {gift.value_with_currency}
          </div>
        </div>
        { parseFloat(this.props.totalCash) > 0 && (
          <div className="CartGiftItemModal-item-buttons">
            <div className="CartGiftItemModal-item-buttons">
              <button onClick={(e) => this.props.selectGift(e, gift.id)} className="CartGiftItemModal-item-buttons-add">Add to bag</button>
            </div>
          </div>
        ) }
      </div>
    )
  }

  render() {
    if (!this.props.isModalOpen) {
      return null
    }

    return (
      <div className="CartGiftItemModal">
        <div className="CartGiftItemModal-content">
          <div className="CartGiftItemModal-content-header">
            <div className="CartGiftItemModal-content-header-title">
              Select your free gift
            </div>

            <div className="CartGiftItemModal-content-header-cross" onClick={e => { this.props.onCloseClick(e) }}></div>
          </div>

          <div className="CartGiftItemModal-giftsList">
            {
              this.props.gifts.map(gift => {
                return this.giftItem(gift)
              })
            }
          </div>
          { parseFloat(this.props.totalCash) === 0 && (
            <div className="CartGiftItemModal-content-disclaimer">
              *Not applicable for free orders
            </div>
          )}
        </div>
      </div>
    )
  }
}

CartGiftItemModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  gifts: PropTypes.array.isRequired,
  fallbackImageSrc: PropTypes.string.isRequired
}

export default CartGiftItemModal
