import React, { useState, useEffect, useCallback } from "react"
import csrfToken from "../src/csrf_token"
import trackProductTileClick from "../utils/fresh-relevance/trackProductTileClick"
import DummyProductTile from "./DummyProductTile"

const FR_RECOMMENDATIONS_PATH = "/api/v1/fr_products"
const FALLBACK_PATH = "/api/v1/fr_fallback"

const logToNewRelic = (message, level, customAttributes) => {
  if (!window.newrelic) return

  window.newrelic.log(message, { level: level, customAttributes: customAttributes })
}

const CartPlaceholder = ({ productsCount }) => (
  <div className='mv4 mv5-l cf'>
    <h1 className='bigtitle bigtitle-full mb0'>
      <span className='f5 empty'></span>
    </h1>

    { [...Array(productsCount).keys()].map((i) => <DummyProductTile key={i} />) }
  </div>
)

const fetchTiles = async ({ path, urlParams = {}, slotParams = {}, timeout = null }) => {
  try {
    const searchParams = new URLSearchParams({
      ...urlParams,
      ...slotParams
    });
    const fullPath = `${path}?${searchParams}`;
    const opts = {
      method: "GET",
      headers: {
        "X-CSRF-Token": csrfToken(),
        "Content-Type": "text/html",
      },
    }
    if (timeout) opts.signal = AbortSignal.timeout(timeout)

    const response = await fetch(fullPath, opts)

    if (!response.ok) {
      logToNewRelic(
        'Failed to fetch Fresh Relevance recommendations - invalid response',
        'error',
        { slot: urlParams.slot, partial: urlParams.partial }
      )
      return null
    }

    const tilesHtml = await response.text()
    return tilesHtml
  } catch (_error) {
    return null
  }
}

const HIDEABLE_SLOTS = ['product2']

const FreshRelevanceRecommendations = (props) => {
  const { slot, partial, slotParams = {} } = props
  const [productTilesHtml, setProductTilesHtml] = useState(null)
  const [usingRecommendations, setUsingRecommendations] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const frTimeout = isNaN(parseInt(props.frTimeout))
    ? null
    : parseInt(props.frTimeout)
  const productsCount = isNaN(parseInt(props.productsCount))
    ? null
    : parseInt(props.productsCount)
  const [productTilesContainer, setProductTilesContainer] = useState(null)
  const attachTilesContainer = useCallback((node) => setProductTilesContainer(() => node), [])
  const [isHidden, setIsHidden] = useState(false)

  const fetchHtmlCleanup = () => {
    setProductTilesHtml(() => null)
    setUsingRecommendations(() => false)
    setIsLoading(() => false)
    setIsHidden(() => false)
  }

  const fetchRecommendations = () =>
    fetchTiles({
      path: FR_RECOMMENDATIONS_PATH,
      urlParams: { slot: slot, partial: partial },
      slotParams: slotParams,
      timeout: frTimeout,
    })

  const fetchFallback = () =>
    fetchTiles({
      path: FALLBACK_PATH,
      urlParams: { slot: slot },
      slotParams: slotParams,
      timeout: null,
    })

  const fetchHtml = async () => {
    const recommendations = await fetchRecommendations()
    if (recommendations) {
      setProductTilesHtml(() => recommendations)
      setUsingRecommendations(() => true)
      setIsLoading(() => false)
      return fetchHtmlCleanup
    } else {
      logToNewRelic('Failed to fetch Fresh Relevance recommendations', 'warn', { slot: slot, partial: partial })
    }

    const fallback = await fetchFallback()
    if (fallback === '' && HIDEABLE_SLOTS.includes(slot)) {
      setIsHidden(() => true)
      return fetchHtmlCleanup
    }

    if (fallback) {
      setProductTilesHtml(() => fallback)
      setIsLoading(() => false)
      return fetchHtmlCleanup
    } else {
      logToNewRelic('Failed to fetch Fresh Relevance fallbacks', 'error', { slot: slot, partial: partial })
    }

    Mess.publish('show_error', 'Failed to fetch products. Please reload the page.')
    return fetchHtmlCleanup
  }

  useEffect(() => fetchHtml(), [])

  useEffect(() => {
    if (!window.ReactRailsUJS) return

    window.ReactRailsUJS.mountComponents()

    const getTrackableElements = () =>
      productTilesContainer.querySelectorAll(
        ".producttile-title > a, .producttile-picture > a"
      )

    if (productTilesContainer && usingRecommendations) {
      getTrackableElements().forEach((el) =>
        el.addEventListener("click", trackProductTileClick)
      )
    }

    return () => {
      if (productTilesContainer && usingRecommendations) {
        getTrackableElements().forEach((el) =>
          el.removeEventListener("click", trackProductTileClick)
        )
      }
    }
  }, [productTilesHtml, productTilesContainer, usingRecommendations])

  if (isHidden) return null
  if (isLoading && slot === 'cart1') return <CartPlaceholder productsCount={productsCount} />

  return <div>
    {
      isLoading && (slot === 'product1' || slot === 'product2' || slot === 'product3') && (
        <h2 className='empty-recommendation-heading'></h2>
      )
    }

    <div className={`${isLoading ? 'flex flex-wrap' : ''}`}>
      <>
        {productTilesHtml && (
          <div
            ref={attachTilesContainer}
            dangerouslySetInnerHTML={{ __html: productTilesHtml }}
          ></div>
        )}
      </>

      {
        isLoading && [...Array(productsCount).keys()].map((i) => <DummyProductTile key={i} />)
      }
    </div>
  </div>
}

export default FreshRelevanceRecommendations
