import React from 'react'

class UserOrderActions extends React.Component {
  render() {
      const url = /orders/ + this.props.order.order_id + "/print_invoice"

      return (
          <div className="userorder-actions">
              { this.props.order.printable ? <a href={ url } target="_blank" title={ '' } className="button-printinvoice">Print Invoice</a> : null }
          </div>
      )
  }
}

export default UserOrderActions
