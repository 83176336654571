import React from 'react'

const DistributorProductsActivator = ({ onClick }) => (
    <a className="distributorproducts-activator"
        href="#show"
        title="Show distributor products"
        onClick={ event => {
            event.preventDefault()
            onClick()
        }}>products</a>
)
export default DistributorProductsActivator
