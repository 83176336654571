import React from 'react'
import _ from '../src/lodash'
import NavigationTopItem from './NavigationTopItem'

class NavigationTop extends React.Component {
  render() {
      var opened_item_id = this.props.opened_item_id,
          clazz = "navigation-top" + (opened_item_id ? ' _sthopened' : '')

      return (
          <ul className={ clazz }>
              {
                  _.map(this.props.items, function(el) {
                      return <NavigationTopItem key={ el.id } item={ el } opened={ el.id == opened_item_id } has_children={ !!el.children } onClick={ this.props.onClick }/>
                  }.bind(this))
              }
          </ul>
      )
  }
}
export default NavigationTop
