import React from 'react'
import RatingInfoDetailsItem from './RatingInfoDetailsItem'
import RatingStars from './RatingStars'

class RatingInfoDetails extends React.Component {
    render() {
        if (!this.props.rating) {
           return null
        }

        const count = parseInt(this.props.rating.count) || 0
        if (count < 1) {
            return null
        }

        var votes = '' + count + (count === 1 ? ' vote' : ' votes')

        return (
            <div className="ratinginfo-details">
                <RatingInfoDetailsItem name="sound quality" value={ this.props.rating.sound_quality } />
                <RatingInfoDetailsItem name="value for money" value={ this.props.rating.value_for_money } />
                <RatingInfoDetailsItem name="ease of use" value={ this.props.rating.ease_of_use } />
                <RatingInfoDetailsItem name="features" value={ this.props.rating.features } />
                <RatingInfoDetailsItem name="presets" value={ this.props.rating.presets } />

                <div className="ratinginfo-details-average">
                    Average rating ({votes}):
                    <RatingStars value={ this.props.rating.overall } />
                </div>
            </div>
        )

    }
}

export default RatingInfoDetails
