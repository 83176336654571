import React from 'react'

class ListPager extends React.Component {

    _renderPage(i) {
        const { page, onChange } = this.props

        if (i == page) {
            return <strong key={i}>{ i }</strong>
        }

        return (
            <a key={i} title={ '' } onClick={ () => onChange(i) }>{ i }</a>
        )
    };

    _renderPaginationArray(page, max) {
        const arr = Array.from(Array(max).keys()).map((e) => e + 1)
        const pageIndex = page - 1

        const maxOnView = 10
        const maxOnLeft = 3
        const maxOnRight = maxOnView - maxOnLeft

        if (arr.length <= maxOnView || pageIndex - maxOnLeft <= 0) { return arr.slice(0, maxOnView) }

        if (pageIndex + maxOnRight >= arr.length) { return arr.slice(-maxOnView) }

        return arr.slice(pageIndex - maxOnLeft, pageIndex + maxOnRight)
    }

    _prev = () => {
        const { page, onChange } = this.props
        onChange(page - 1)
    };

    _next = () => {
        const { page, onChange } = this.props
        onChange(page + 1)
    };

    render() {
        const { page, max, processing } = this.props
        const firstPage = page == 1
        const lastPage = page == max
        const clazz = 'paging'  + (processing ? '_processing' : '')

        if (max == 1) {
            return null
        }

        return (

            <div className={ clazz } key={ '' + page + '-' + max }>
                <div className={ 'myproducts-paging' }>
                    { firstPage ?
                        <span className="paging-prev">Prev</span> :
                        <a className="paging-prev" title="Go to the previous page" onClick={ this._prev }>Prev</a>
                    }
                    { this._renderPaginationArray(page, max).map((p, index) => this._renderPage(p)) }
                    { lastPage ?
                        <span className="paging-next">Next</span> :
                        <a className="paging-next" title="Go to the next page" onClick={ this._next }>Next</a>
                    }
                </div>
            </div>
        )
    }
}

export default ListPager
