import React from 'react'

class SamplepacksDownloadsItem extends React.Component {
    render() {
        var item = this.props.item

        return (
            <li className="samplepacksdownloads-item">
                <a href={ item.link_to } className="_picture" title={ item.name }><img src={ item.image_url } alt={ item.image_description }/></a>

                <a href={ item.link_to } className="_title" title={ item.name }>{ item.name }</a>

                <a href={ item.download_url } target="_blank" className="button-download _download" title={ '' }>Download free pack</a>
            </li>
        )
    }
}

export default SamplepacksDownloadsItem
