import React from 'react'
import RatingStarsBig from './RatingStarsBig'

class UserRateInfo extends React.Component {
    state = { fresh: this.props.fresh };

    componentDidMount() {
        if (this.props.fresh) {
            setTimeout(this.handle_fresh, 200)
        }
    }

    handle_fresh = () => {
        this.setState({fresh: false})
    };

    render() {
        const rentToOwn = this.props.rentToOwn
        const userRate = 'userrate-info' + (this.state.fresh ? ' _fresh' : '')
        const clazz = rentToOwn ? 'userrate-rentToOwn' : userRate

        return (
            <div className={clazz}>
                { !rentToOwn && (
                    <RatingStarsBig value={ this.props.value } />
                )}
                <p>Rated - Thank You!</p>
                { this.props.canEarnVC && <p>We added £0.20 to your account</p> }
            </div>
        )
    }
}

export default UserRateInfo
