import React from 'react'
import CourseDownload from './CourseDownload'

class CourseDownloads extends React.Component {
    render() {
        return (
            <div className="courseplayer-downloads">
                <h2>Course Downloads</h2>

                <ul>
                    { _.map(this.props.downloads, function(item, idx) {
                        return <CourseDownload key={ item.course_download_id } download={ item } />
                    }.bind(this)) }
                </ul>
            </div>
        )
    }
}

export default CourseDownloads
