import React from 'react'
import csrfToken from '../src/csrf_token'
import trackProductTileClick from './../utils/fresh-relevance/trackProductTileClick'

class BuyBoxAdd extends React.Component {
    state = {
        in_cart: this.props.in_cart,
        progress: false,
        can_add_to_cart: this.props.can_add_to_cart,
        rejection_description: this.props.rejection_description,
        rejection_link: this.props.rejection_link
    };

    handle_buy = (event) => {
        event.preventDefault()
        this.setState({ progress: true })

        const url = this.props.update ? '/orders/add_update_to_cart' : '/orders/add_to_cart'

        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: {
              id: this.props.item_id
            },
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ in_cart: true, progress: false })

                Mess.publish('refresh_cart', data)

                fbq('track', 'AddToCart', {
                    content_type: 'product',
                    content_ids: [this.props.item_id]
                });

                trackProductTileClick(event)
            }.bind(this),
            error: function(xhr, status, err) {
                if (this.props.onApiError !== undefined) {
                  this.props.onApiError(xhr)
                }

                console.error(this.props.url, status, err.toString());
                this.setState({ progress: false })
            }.bind(this)
        });
    };

    enableProgress = () => {
      this.setState({ progress: true })
    };

    render() {
        if (this.state.in_cart) {
            return <a href="/cart" className={`button-buy _in ${this.props.with_new_styles === true ? 'button-buy-new' : ''}`} title="Check you cart">In Cart</a>
        }

        var clazz = `button-buy ${this.state.progress ? ' _progress' : ''} ${this.props.with_new_styles === true ? 'button-buy-new' : ''}`

        if (typeof(this.state.can_add_to_cart) != 'undefined' && !this.state.can_add_to_cart) {
          return (
            <div className="cant-buy" title={ this.state.rejection_description }>
              <a href={ this.state.rejection_link } style={ {color: '#ffffff'} }>{ this.state.rejection_description }</a>
            </div>
          )
        } else {
          return (
            <a href="#add_to_cart" className={ clazz } title="Add product to cart" data-layer={ this.props.data_layer } onClick={ this.handle_buy }>
              add to cart
            </a>
          )
        }
    }
}

export default BuyBoxAdd
