export const THEMES = {
  dark: 'dark-theme',
  light: 'light-theme'
}

export function setTheme (theme) {
  document.body.classList.remove(...Object.values(THEMES))
  document.body.classList.add(theme)

  const isDark = theme === THEMES.dark
  const yearInAdvance = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()
  document.cookie = `dark_theme=${isDark};expires=${yearInAdvance}`
}

export function toggleTheme() {
  const theme = document.body.classList.contains(THEMES.dark) ? THEMES.light : THEMES.dark
  setTheme(theme)
}
