import React from 'react'

class BuyBoxPrice extends React.Component {
  render() {
    return (
      <dl className='buybox-price'>
        <dt>
          Your Price
          { this.props.with_vat && ' (Inc TAX)' }
        </dt>
        <dd>{ this.props.value }</dd>
      </dl>
    )
  }
}

export default BuyBoxPrice
