import React from 'react'
import PropTypes from 'prop-types'

const BuyBoxVirtualBalanceWithRewards = ({ virtualCashBalance, virtualCash, rewardTokens}) => (
  <div className='buybox-listValues'>
    {
      virtualCashBalance && (
        <div className='buybox-listValues-vcBalance'>
          <div className='buybox-listValues-vcBalance-text'>
            <span className='buybox-listValues-vcBalance-text-icon'></span>
            <span>Your Virtual Cash balance</span>
          </div>
          <div className='buybox-listValues-vcBalance-value'>
            { virtualCashBalance }
          </div>
        </div>
      )
    }
    <div className='buybox-listValues-vcEarn'>
      <div className='buybox-listValues-vcEarn-text'>
        <span className='buybox-listValues-vcEarn-text-icon'></span>
        <span>Virtual Cash you will earn</span>
      </div>
      <div className='buybox-listValues-vcEarn-value'>
        { virtualCash }
      </div>
    </div>
    {
      (rewardTokens > 0) && (
        <div className='buybox-listValues-tokens'>
          <div className='buybox-listValues-tokens-text'>
            <span className='buybox-listValues-tokens-text-iconTokens'></span>
            <span>Rewards+ Tokens</span>
            <a href='/rewards/about' target="_blank" rel="noopener noreferrer" className='buybox-listValues-tokens-text-iconQuestion'></a>
          </div>
          <div className='buybox-listValues-tokens-value'>
            { rewardTokens }
          </div>
        </div>
      )
    }
  </div>
)

BuyBoxVirtualBalanceWithRewards.propTypes = {
  virtualCashBalance: PropTypes.number.isRequired,
  virtualCash: PropTypes.number.isRequired,
  rewardTokens: PropTypes.number.isRequired
};

export default BuyBoxVirtualBalanceWithRewards
