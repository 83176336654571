import React from 'react'

class GalleryContentYoutube extends React.Component {
  render() {
      return (
          <div className="gallery-content --youtube">
              <div className="gallery-content-inner">
                  <iframe src={ this.props.src } frameBorder="0" allowFullScreen></iframe>
              </div>
          </div>
      )
  }
}

export default GalleryContentYoutube
