import React from 'react'
import CourseMovie from './CourseMovie'
import CourseModules from './CourseModules'
import CourseDownloads from './CourseDownloads'
import csrfToken from '../src/csrf_token'

class CoursePlayer extends React.Component {
    state = {
      course: null,
      moduleIdx: 0
    }

    componentDidMount() {
        Mess.subscribe('show_course', this.showCourse)

        if (this.props.courseId !== null) {
          this.showCourse(this.props.courseId, null)
        }
    }

    showCourse = (courseId, moduleIdx) => {
        moduleIdx = moduleIdx ? moduleIdx : 0
        scroll = courseId ? true : false
        courseId = courseId ? courseId : this.state.course.course_id

        if (!courseId) {
            return
        }

        $.ajax({
            url: '/users/course/' + courseId,
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                if (data.error) {
                    Mess.publish('show_error', data.error)

                } else {
                    this.setState({ course: data, moduleIdx: moduleIdx })

                    if (scroll) { // scroll only on course init
                        window.scrollTo(0, 200)
                    }
                }

            }.bind(this),

            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });

    };

    handleModuleChange = (idx) => {
        this.showCourse(null, idx)
    };

    handleClose = () => {
        this.setState({ course: null })
    };

    render() {
        if (!this.state.course) {
            return null
        }

        return (
            <div className="courseplayer">
                <div className="courseplayer-content">
                    <h1>{ this.state.course.name }</h1>

                    <a href="#close" className="courseplayer-close" title="exit" tutorial onClick={ this.handleClose }>exit tutorial</a>

                    <CourseMovie module={ this.state.course.course_modules[this.state.moduleIdx] } key={ 'm-' + this.state.course.course_modules[this.state.moduleIdx].course_module_id}/>

                    <CourseModules modules={ this.state.course.course_modules } activeIdx={ this.state.moduleIdx } onModuleChange={ this.handleModuleChange }/>

                    <CourseDownloads downloads={ this.state.course.course_downloads } />
                </div>
            </div>
        )
    }
}

export default CoursePlayer
