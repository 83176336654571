import React from 'react'

class BPSPlans extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activePlan: 'monthly'
    }
  }

  setActive(paln_name) {
    this.setState({ activePlan: paln_name })
  }

  render() {
    const activePlanClass = 'BPSPlans-switch-btn-active'
    const activePlan = this.state.activePlan
    const isMonthly = activePlan === 'monthly'
    const planTitle = isMonthly ? 'Monthly' : 'Annual'
    const planPrice = isMonthly ? '$11.99 / month' : '$119.99 / year'
    const btnUrl = isMonthly ? this.props.monthlySubscribeUrl : this.props.annualSubscribeUrl
    const btnText = isMonthly ? 'Try Free for 14 Days' : 'Join Now'
    // additional promo tick info that might be useful in the future
    // const promoTickText = 'Cubase LE 13 FREE'

    return(
      <div className='BPSPlans'>
        <div className='BPSPlans-id-wrapper'>
          <div id='pricing'></div>
        </div>
        <div className='BPSPlans-header' ref={this.props.reference}>Pick your perfect plan</div>
        <div className='BPSPlans-switch'>
          <div onClick={() => this.setActive('monthly')} className={`BPSPlans-switch-btn ${ isMonthly && activePlanClass}`}>Monthly</div>
          <div onClick={() => this.setActive('annual')}  className={`BPSPlans-switch-btn ${ !isMonthly && activePlanClass}`}>Annual (Save 15%)</div>
        </div>

        <div className='BPSPlans-body BPSPlans-body-desktop'>
          <div className='BPSPlans-body-content'>
            <div className='BPSPlans-body-content-title'>{planTitle}</div>
            <div className='BPSPlans-body-content-price'>{planPrice}</div>
            <ul className='BPSPlans-body-content-list'>
              {/* <li><span className="BPSPlans-body-content-list-checkmark"></span><span className='BPSPlans-body-content-list-highlight'>{promoTickText}</span></li> */}
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>10 plugin selections each month</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Up to 100 sounds each month</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Free Cubase LE + Bitwig 8-Track DAWs</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Frozen State Mode plugin technology</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Cancel or pause at any time</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>5% Virtual Cash every month to spend at Plugin Boutique</span></li>
            </ul>
            <a
              className='BPSPlans-body-content-btn bps-btn'
              href={btnUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {btnText}
            </a>
          </div>
          <div className='BPSPlans-body-img'>
            <picture>
              <source media="(min-width: 2560px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/plans/bg_uw@x2.png`)} />
              <source media="(min-width: 2560px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/plans/bg_uw@x1.png`)} />
              <source media="(min-width: 1024px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/plans/bg_m@x2.png`)} />
              <source media="(min-width: 1024px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/plans/bg_m@x1.png`)} />
              <source media="(min-width: 0px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/plans/bg_sm@x1.png`)} />
              <img
                src={require(`../../images/beatport_studio/plans/bg_sm@x1.png`)}
              />
            </picture>
          </div>
        </div>

        <div className='BPSPlans-body BPSPlans-body-mobile'>
          <div className='BPSPlans-body-content'>
            <div className='BPSPlans-body-content-title'>Monthly</div>
            <div className='BPSPlans-body-content-price'>$11.99 / month</div>
            <ul className='BPSPlans-body-content-list'>
              {/* <li><span className="BPSPlans-body-content-list-checkmark"></span><span className='BPSPlans-body-content-list-highlight'>{promoTickText}</span></li> */}
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>10 plugin selections each month</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Up to 100 sounds each month</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Free Cubase LE + Bitwig 8-Track DAWs</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Frozen State Mode plugin technology</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Cancel or pause at any time</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>5% Virtual Cash every month to spend at Plugin Boutique</span></li>
            </ul>
            <a
              className='BPSPlans-body-content-btn bps-btn'
              href={this.props.monthlySubscribeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Try Free for 14 Days
            </a>
          </div>
        </div>

        <div className='BPSPlans-body BPSPlans-body-mobile'>
          <div className='BPSPlans-body-content'>
            <div className='BPSPlans-body-content-title'>Annual (Save 15%)</div>
            <div className='BPSPlans-body-content-price'>$119.99 / year</div>
            <ul className='BPSPlans-body-content-list'>
              {/* <li><span className="BPSPlans-body-content-list-checkmark"></span><span className='BPSPlans-body-content-list-highlight'>{promoTickText}</span></li> */}
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>10 plugin selections each month</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Up to 100 sounds each month</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Free Cubase LE + Bitwig 8-Track DAWs</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Frozen State Mode plugin technology</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>Cancel or pause at any time</span></li>
              <li><span className="BPSPlans-body-content-list-checkmark"></span><span>5% Virtual Cash every month to spend at Plugin Boutique</span></li>
            </ul>
            <a
              className='BPSPlans-body-content-btn bps-btn'
              href={this.props.annualSubscribeUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Join Now
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default BPSPlans