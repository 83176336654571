import React from 'react'
import DistributorProduct from './DistributorProduct'

class DistributorProductsContent extends React.Component {
    render() {
        const { products, onToggleProduct } = this.props

        return (
            <ul className="distributorproducts-content">
                { products.map((item) => (
                    <DistributorProduct
                        { ...item }
                        key={ item.item_id }
                        onToggleProduct={ onToggleProduct }/>
                ))}
            </ul>
        )
    }
}

export default DistributorProductsContent





