import React from 'react'
import ProcessLine from './ProcessLine'

class DistributorApiKey extends React.Component {
    constructor(props) {
        super(props)
        this.state = { api_key: props.api_key, processing: false }
    }

    // handle_toggle() {
    //     if (this.state.processing) {
    //         return
    //     }

    //     this.setState({ processing: true })
    //     var key_id = this.props.api_key.key_id

    //     $.ajax({
    //         url: /distributor/toggle_api_key/ + key_id,
    //         dataType: 'json',
    //         method: 'post',
    //         success: (data) => this.setState({ processing: false, api_key: data }),
    //         error: (xhr) => this.setState({ processing: false })
    //     })
    // }

    render() {
        var key = this.state.api_key,
            active = key.active,
            clazz = 'distributorkey' + (active ? "" :  " _nonactive")
            // handle_toggle = this.handle_toggle.bind(this)

        return (
            <li className={ clazz }>
                <ProcessLine processing={ this.state.processing }/>

                <span className="_date">{ key.created_at }</span>
                <span className="_token">{ key.token }</span>
                <span className="_active">{ active ? "Active" : "Non Active" }</span>
            </li>
        )
    }
}


export default DistributorApiKey
