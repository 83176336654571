import React from "react";
import '../../src/mess'

class KlarnaWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedPaymentMethod: this.props.paymentMethodCategories[0]
    }

    this.paymentMethodChanged = this.paymentMethodChanged.bind(this);
  }

  paymentMethodChanged(paymentMethod) {
    const chosenPaymentMethod = this.props.paymentMethodCategories.find( (el) => el.identifier === paymentMethod.target.value )
    this.setState({selectedPaymentMethod: chosenPaymentMethod})
    Mess.publish('klarna_payment_method_change', chosenPaymentMethod.identifier)
  }

  initPaymentMethod(paymentMethod) {
    if (!paymentMethod.notAllowed) {
      Klarna.Payments.load({
        container: '.klarna-payment-method#' + paymentMethod.identifier,
        payment_method_category: paymentMethod.identifier,
        show_form: true
      }, function (res) {
      });
    }
  }

  componentDidMount() {
    Klarna.Payments.init({
      client_token: this.props.clientToken
    });

    this.props.paymentMethodCategories.forEach(this.initPaymentMethod);
  }

  render() {
    if(this.props.clientToken){
      if(this.props.paymentMethodCategories.length > 0){
        return (
          <div className="klarna-payment-methods">
            <div className="accordion">
              {
                this.props.paymentMethodCategories.map((paymentMethod) =>
                    [!paymentMethod.notAllowed && <input type="radio" name="klarna_payment_method" id={ 'klarna_payment_method_' + paymentMethod.identifier } value={ paymentMethod.identifier } className="accordion-select" onChange={ this.paymentMethodChanged } checked={ paymentMethod === this.state.selectedPaymentMethod }/>,
                    <div key={ paymentMethod.identifier } className="accordion-title">
                      <span>
                        <span style={{ display: 'contents' }}>
                          { `${paymentMethod.name}${paymentMethod.notAllowed ? ` ${paymentMethod.notAllowedMessage}` : ''}` }
                        </span>
                        <img className="klarna-icon" src={ paymentMethod.asset_urls.standard } alt="Klarna"/>
                      </span>
                    </div>,
                    <div className="accordion-content klarna-payment-method" id={ paymentMethod.identifier }></div>]
                  )
              }
            </div>
            <input type="hidden" name="authorization_token" id="authorization_token"/>
            <p class="klarna-notice">Please note: Klarna supports payments only in your local currency.</p>
          </div>
        )
      } else {
        return (
          <div className="klarna-payment-methods">
            <div className="accordion">
              <div>
                We are sorry, but Klarna Payments is not available in your country.
              </div>
            </div>
          </div>
        )
      }
    } else {
      return (
        <div className="klarna-payment-methods">
          <div className="accordion">
            <div>
              Klarna Payments is not available now. Please try again later or contact our support.
            </div>
          </div>
        </div>
      )
    }
  }
}

export default KlarnaWidget;
