import React from 'react'
import PropTypes from 'prop-types';
import NavigationMenuItem from './NavigationMenuItem'
import NavigationMenuLink from './NavigationMenuLink'
import NavigationLinks from './NavigationLinks'


class NavigationMenu extends React.Component {

  static propTypes = {
      effectsCategoryId: PropTypes.number.isRequired
  }

  state = {
    openedItemId: this.shouldOpenEffects(this.props) ? this.props.effectsCategoryId : null
  }

  componentWillReceiveProps(nextProps) {
      this.setState({openedItemId: this.shouldOpenEffects(nextProps) ? this.props.effectsCategoryId : null })
  }

  shouldOpenEffects(props) {
    return props.item && (props.item.id == 'software' || props.item.id == 'free')
  }

  subMenuData() {
      var id = this.state.openedItemId
      return _.find(this.props.item.children, function(el) { return el.id == id })
  }

  handleItemClick(id) {
      this.setState({ openedItemId: id })
  }

  handleContentClick(event) {
      event.stopPropagation()
  }

  render() {
      if (!(this.props.item && this.props.item.children)) {
          return null
      }

      return (
          <div className={ 'navigation-board navigationmenu-' + this.props.item.id } onClick={ this.props.onClose } >
              <div className="navigation-content" onClick={ this.handleContentClick }>
                  <ul className="navigation-menu">
                      {
                          _.map(this.props.item.children, function(child, i) {
                              return (
                                  child.children ?
                                      <NavigationMenuItem
                                          key={ 'navmenuitem' + i + child.free }
                                          item={ child }
                                          opened={ child.id == this.state.openedItemId }
                                          onClick={ this.handleItemClick.bind(this) } /> :

                                      <NavigationMenuLink
                                          key={ 'navmenulink' + i + child.free }
                                          item={ child } />
                              )
                          }.bind(this))
                      }
                  </ul>

                  { this.state.openedItemId ? <NavigationLinks item={ this.subMenuData() } /> : null }
              </div>
          </div>
      )
  }

}
export default NavigationMenu
