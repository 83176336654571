import React from 'react'

class UserOrderTableItem extends React.Component {
  render() {
      return (
          <tr className={ this.props.className }>
              <th>{ this.props.name }</th>
              <td>{ this.props.value }</td>
          </tr>
      )
  }
}

export default UserOrderTableItem
