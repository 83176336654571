import React from 'react'
import '../src/mess'

class Flash extends React.Component {
    state = {
        kind: this.props.kind,
        msg: this.props.msg,
        visible: this.props.kind ? true : false
    };

    componentDidMount() {
        Mess.subscribe('show_error', this.show_error)
        Mess.subscribe('show_warning', this.show_warning)
        Mess.subscribe('show_notice', this.show_notice)
        Mess.subscribe('show_flash', this.show_flash)

        if (this.state.kind == 'notice') {
            window.setTimeout(this.handle_close, 5000)
        }
    }

    show_notice = (msg) => {
        this.setState({ kind: 'notice', msg: msg, visible: true })
        window.setTimeout(this.handle_close, 5000)
    };

    show_warning = (msg) => {
        this.setState({ kind: 'warning', msg: msg, visible: true })
    };

    show_error = (msg) => {
        this.setState({ kind: 'error', msg: msg, visible: true })
    };

    show_flash = (data) => {
        if (data.error) {
            this.show_error(data.error)
        }
        if (data.warning) {
            this.show_warning(data.warning)
        }
        if (data.notice) {
            this.show_notice(data.notice)
        }
    };

    handle_close = (event) => {
        this.setState({ visible: false })

        if (event) {
            event.preventDefault()
        }
    };

    rawMessage = () => {
      return {__html: this.state.msg}
    };

    render() {
        return this.state.visible ? (
            <div className={ 'flash _' + this.state.kind } >
                <div className="_content">
                    <p>
                        <span dangerouslySetInnerHTML={this.rawMessage()} />
                        <a className="close" href="#close" title="Click to close this information" onClick={ this.handle_close }>click to close</a>
                    </p>
                </div>
            </div>
        ) : null
    }
}


// todo: timer, Mess.publish('show_error', 'Foo...')
export default Flash
