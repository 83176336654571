export const addToWishlist = (product, subscriptionId = null) => {
  const id = product.product_id || product.course_id
  const data = subscriptionId ? { id: id, subscription_id: subscriptionId } : { id: id }

  $.ajax({
    url: '/wishlists/add_to_wishlist',
    type: 'POST',
    data: data,
    dataType: 'json',
    success: function(data) {
      Mess.publish('refresh_wishlist', data)

      fbq('track', 'AddToWishlist', {
        content_ids: [id]
      })
    }.bind(this),
    error: function(xhr, status, err) {
      console.error(product, status, err.toString())
    }.bind(this)
  })
}
