import React from 'react'
import UserOrderShort from './UserOrderShort'
import UserOrderMeta from './UserOrderMeta'
import UserOrderItem from './UserOrderItem'
import UserOrderTableItem from './UserOrderTableItem'
import UserOrderActions from './UserOrderActions'

class UserOrder extends React.Component {
    state = { expanded: this.props.expanded || false };

    handleExpand = () => {
        this.setState({ expanded: true })
    };

    handleCollapse = () => {
        this.setState({ expanded: false })
    };

    render() {
        if (!this.state.expanded) {
            return <UserOrderShort order={ this.props.order } onExpand={ this.handleExpand } />
        }

        return (
            <div className="userorder">
                <UserOrderMeta order={ this.props.order } />

                <table className="userorder-details">
                    {
                        _.map(this.props.order.order_items, function(item, index) {
                            return <UserOrderItem key={index} order_item={ item } />
                        })
                    }

                    <UserOrderTableItem name="Coupon Code Discount" value={ this.props.order.coupon_value } className="userorder-cc" />
                    <UserOrderTableItem name="Virtual Cash Discount" value={ this.props.order.vc_value } className="userorder-vc" />

                    { this.props.order.vat ? <UserOrderTableItem name="VAT" value={ this.props.order.vat } className="userorder-vat"/> : null }

                    <UserOrderTableItem name="Total Paid" value={ this.props.order.cash } className="userorder-total"/>
                </table>

                <a href="#collapse" title={ '' } onClick={ this.handleCollapse } className="userorder-collapser button-up" button-up>Hide Details</a>

                <UserOrderActions order={ this.props.order } />
            </div>
        )
    }
}
export default UserOrder
