import React from 'react'
import UserProductMeta from './UserProductMeta'
import UserProductDownload from './UserProductDownload'
import UserProductExpander from './UserProductExpander'

class UserProductShort extends React.Component {
    render() {
        return (
            <div className="userproduct userproduct-short">
                <a name={ 'item-' + this.props.order_item.product.product_id } title={ this.props.order_item.product.name }></a>
                <UserProductMeta product={ this.props.order_item.product } />
                <UserProductDownload order_item={ this.props.order_item} expanded={ false } />
                <UserProductExpander name={ this.props.order_item.product.name } onClick={ this.props.onExpand }/>
            </div>
        )
    }
}

export default UserProductShort
