import React from 'react'
import PropTypes from 'prop-types';
import csrfToken from '../../src/csrf_token'
import Loadable from 'react-loadable';
import _ from '../../src/lodash'
import '../../src/mess'
import ShippingInfo from './ShippingInfo'

const LoadableSelect = Loadable({
  loader: () => import('react-select'),
  loading() {
    return <div>Loading...</div>
  }
});

class BillingInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: this.props.address.first_name,
      last_name: this.props.address.last_name,
      phone_number: this.props.address.phone_number || '',
      phone_prefix: this.props.address.phone_prefix || this.props.address.country,
      address_1: this.props.address.address_1,
      address_2: this.props.address.address_2,
      postal_code: this.props.address.postal_code,
      city: this.props.address.city,
      province: this.props.address.province,
      country: this.props.address.country,
      full_address: null,
      autocomplete: this.shouldRenderAutocomplete(),
      company_name: this.props.address.company_name,
      vat_number: this.props.address.vat_number,
      store: this.props.address.store,
      storeShipping: ((this.props.shippingInfo||{}).address||{}).store,
      sameAddress: this.props.sameAddress,
      errors: this.props.errors,
      dark_theme: this.props.dark_theme,
      aDisabled: false
    }

    this.handleChange = this.handleChange.bind(this);
    this.manuallyEnterAddress = this.manuallyEnterAddress.bind(this);
    this.resetBillingInfo = this.resetBillingInfo.bind(this);
    this.validatePresence = this.validatePresence.bind(this);
    this.renderShippingInfoPart = this.renderShippingInfoPart.bind(this)
    this.handleScrollToError = this.handleScrollToError.bind(this)
    this.openManualEnterAdress = this.openManualEnterAdress.bind(this)

    this.child = React.createRef()
    this.firstScrollRef = React.createRef()
    this.secondScrollRef = React.createRef()
    this.thirdScrollRef = React.createRef()
  }

  shouldRenderAutocomplete() {
    if(this.props.errors && this.props.errors[0]) {
      return !['address_1', 'address_2', 'postal_code', 'city', 'province', 'country'].some((val) => this.props.errors[0][val])
    }
    return true
  }

  validatePresence(event) {
    const fieldsWithPresence = ['first_name', 'last_name', 'postal_code', 'address_1', 'city', 'country', 'province']
    const fieldsWithMaxLength = [...fieldsWithPresence, 'company_name', 'vat_number', 'address_2', 'phone_number']

    let billingFieldsWithErrors = {}
    let shippingFieldsWithErrors = {}

    fieldsWithPresence.map(field => {
      if ((this.state[field] === null) || (this.state[field] === '')) {
        billingFieldsWithErrors[field] = 'This information is required'
      }
    })

    fieldsWithMaxLength.forEach(field => {
      if ((this.state[field] ?? '').length > 255) {
        billingFieldsWithErrors[field] = 'This information is too long (maximum is 255 characters)'
      }
    })

    this.setState({
      errors: [billingFieldsWithErrors]
    })

    if (this.props.shippingInfo && this.props.shippingInfo.address) {
      shippingFieldsWithErrors = this.child.current.validateInputs()
    }

    if (!_.isEmpty(_.omit(billingFieldsWithErrors, 'first_name', 'last_name'))) {
      this.openManualEnterAdress()
    }

    if (!_.isEmpty(shippingFieldsWithErrors)) {
      this.child.current.openManualEnterAdress()
    }

    if (!_.isEmpty(billingFieldsWithErrors) || !_.isEmpty(shippingFieldsWithErrors)) {
      event.preventDefault()
      this.handleScrollToError(billingFieldsWithErrors, shippingFieldsWithErrors)
    }
  }

  errorFor(field) {
    if(this.state.errors && this.state.errors[0]) {
      return this.state.errors[0][field];
    }
  }

  componentDidMount () {
    // eslint-disable-next-line no-undef
    Mess.subscribe('disable_navigation', (value) => {
      this.setState({ aDisabled: value })
      const backButton = document.getElementsByClassName('back-to-prev')[0]
      value ? backButton.classList.add('a-disabled') : backButton.classList.remove('a-disabled')
    });


    let thus = this;
    // See LOQATE initialization in application.html.erb
    // eslint-disable-next-line no-undef
    pca.on("load", function(type, id, control) {
      // eslint-disable-next-line no-undef
      pca.on("options", function(type, key, options) {
        options.suppressAutocomplete = false;
      });

      control.listen("populate", function(address) {
        if(this.autocomplete.field.id.includes('billing')) {
          thus.setState({
            full_address: address.Label.replace(/\n/g, ', '),
            address_1: address.Line1,
            address_2: address.Line2,
            postal_code: address.PostalCode,
            city: address.City,
            province: address.ProvinceName,
            country: address.CountryIso2
          });
        }
      });
    });
  }

  handleScrollToError(billingErrors, shippingErrors) {
    const billingErrorKeys = Object.keys(billingErrors)
    const shippingErrorKeys = Object.keys(shippingErrors)

    const opts = { behavior: 'smooth' }

    if (billingErrorKeys.includes('first_name') || billingErrorKeys.includes('last_name') || (this.state.autocomplete && billingErrorKeys.length > 0)) {
      this.firstScrollRef.current.scrollIntoView(opts)
    } else if (billingErrorKeys.length > 0) {
      this.secondScrollRef.current.scrollIntoView(opts)
    } else if (shippingErrorKeys.length > 0) {
      this.thirdScrollRef.current.scrollIntoView(opts)
    }
  }

  handleChange(event, attr_name) {
    const object = {}

    object[attr_name] = event.target ? event.target.value : event.value

    if(['store', 'storeShipping', 'sameAddress'].includes(attr_name)){
      object[attr_name] = event.target.checked
    }

    this.setState(object);
  }

  openManualEnterAdress() {
    this.setState({ autocomplete: false })
  }

  manuallyEnterAddress(event) {
    event.preventDefault();
    this.setState({ autocomplete: !this.state.autocomplete });
  }

  resetBillingInfo(event) {
    event.preventDefault();
    this.setState({ autocomplete: true, full_address: null });
    // NOTE: ignoring props.kind and keeping "order" prefix for IDs so autocomplete works
    const autocompleteElement = `order_billing_info_attributes_address_lookup`

    setTimeout(function(){
      document.getElementById(autocompleteElement).focus();
      document.getElementById(autocompleteElement).select();
    }, 250);
  }

  textInput(label_text, attr_name, attr_value) {
    const errorMessage = this.errorFor(attr_name);
    const inputClassName = errorMessage ? 'red-border' : ''

    return (
      <div className="address-input-wrapper">
        <label className="label-address" htmlFor={`${this.props.kind}_${attr_name}`} dangerouslySetInnerHTML={{ __html: _.unescape(label_text) }}>
        </label>

        { errorMessage && (<div className="error-container"><div className="error-text" dangerouslySetInnerHTML={{ __html: _.unescape(errorMessage) }}></div></div>) }

        <input
          className={inputClassName + " input-address"}
          type="text"
          value={attr_value}
          autoComplete="off"
          name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][${attr_name}]`}
          id={`order_billing_info_attributes_${attr_name}`}
          onChange={(event) => this.handleChange(event, attr_name)}
        />
      </div>
    )
  }

  isExpandedFormErrorsPresent(errors) {
    const expandedFields = ['postal_code', 'address_1', 'city', 'country', 'province']

    return errors[0] ? !_.isEmpty(_.intersection(expandedFields, Object.keys(errors[0]))) : false
  }

  renderAddressAutocomplete() {
    const addressLookupErrorsPresent = this.isExpandedFormErrorsPresent(this.state.errors)
    const inputErrorClassName = addressLookupErrorsPresent ? 'red-border' : ''

    return (
      <div className={ this.state.autocomplete ? '' : 'dn' }>
        <div>
          <span className="address-autocomplete-box">
            <label className="label-address label-autocomplete-box" htmlFor={`${this.props.kind}_address_lookup`}>
              Billing Address Lookup:&nbsp;
              <span className="red-asterisk">*</span>
            </label>
          </span>

          { addressLookupErrorsPresent && (<div className="error-container"><div className="error-text">{ 'This information is required' }</div></div>) }

          <input
                  className={ `${inputErrorClassName} input-address` }
                  type="text"
                  autoComplete="off"
                  placeholder="Start typing your address"
                  disabled={this.state.full_address}
                  value={this.state.full_address}
                  id="order_billing_info_attributes_address_lookup"/>

        </div>
        { (this.state.full_address || !this.props.address['new_record?']) && (<div className="full-address">
          <div>{ this.state.address_1 }</div>
          <div>{ this.state.address_2 }</div>
          <div>{ this.state.postal_code }&nbsp;{ this.state.city }</div>
          <div>{ this.state.province }</div>
          <div>{ this.countryName() }</div>
          <hr/>
          <a href="#" onClick={ this.resetBillingInfo }>Edit</a>
        </div>)
        }
        <a className="enter-manually" href="#" onClick={ this.manuallyEnterAddress }>Or Manually enter Billing address</a>
      </div>
    )
  }

  renderManualAddressCompletion() {
    return (
      <div className={ this.state.autocomplete ? 'dn' : '' }>
        <span className={ this.state.autocomplete ? 'address-autocomplete-box' : 'address-autocomplete-box label-autocomplete-box' } ref={this.secondScrollRef}>
          <a href="#" onClick={ this.manuallyEnterAddress }>Back to Auto Address lookup</a>
        </span>

        { this.textInput('Postal Code/Zip Code: <span class="red-asterisk">*</span>', 'postal_code', this.state.postal_code) }

        { this.textInput('Address 1: <span class="red-asterisk">*</span>', 'address_1', this.state.address_1) }

        { this.textInput('Address 2: ', 'address_2', this.state.address_2) }

        { this.textInput('City: <span class="red-asterisk">*</span>', 'city', this.state.city) }

        { this.renderProvinceSelect() }

        { this.renderCountryInput() }
      </div>
    )
  }

  prefixNumber() {
    return this.props.countriesTelephonePrefixes.find(element => element[1] === this.state.phone_prefix)[2]
  }

  renderTelephoneInput(){
    const options = this.props.countriesTelephonePrefixes.map((val) => {
      // eslint-disable-next-line no-undef
      let labelImg = (<span dangerouslySetInnerHTML={{ __html: `<img class="country_flag" src="${require(`../../images/flags/${val[1].toLowerCase()}.svg`)}" alt="${val[0]} flag">${val[1]} (${val[2]})` }}/>)
      return { value: val[1], label: labelImg }
    });

    const styles = {
      control: (provided) => ({
        ...provided,
        minHeight: '29px',
        height: '29px',
        borderColor: '#8c8c8c',
        fontSize: '14px'
      }),
      menu: (provided) => ({
        ...provided,
        width: '200%'
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0 3px',
        display: 'none'
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),

    }

    const dark_styles = {
      ...styles,
      control: (provided) => ({
        ...provided,
        minHeight: '29px',
        height: '29px',
        fontSize: '14px',
        borderColor: '#525252',
        backgroundColor: '#242424'
      }),
      menu: (provided) => ({
        ...provided,
        width: '200%',
        backgroundColor: '#3b3b3b'
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' :'#ffffff',
        backgroundColor: state.isSelected ? '#01ff95' : '#3b3b3b',
        '&:hover': {
          backgroundColor: '#01ff95',
          color: 'black'
        }
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#a8a8a8'
      }),
      input: (provided) => ({
        ...provided,
        color: '#a8a8a8'
      })
    }

    const errorMessage = this.errorFor('phone_number');
    const errorClassName = errorMessage ? 'red-border' : ''

    return (
      <div>
        <label className="label-address" htmlFor={`${this.props.kind}_phone_number`}>
          Mobile Number: (optional)
        </label>

        { errorMessage && (<div className="error-container"><div className="error-text" dangerouslySetInnerHTML={{ __html: _.unescape(errorMessage) }}></div></div>) }

        <div className="phone-box">
          <LoadableSelect
            className='prefix_select'
            name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][phone_prefix]`}
            value={ options.find(element => element.value === this.state.phone_prefix) }
            onChange={(event) => this.handleChange(event, 'phone_prefix')}
            options={options}
            styles={this.state.dark_theme ? dark_styles : styles}
          />

          <input
                className={`input-address ${errorClassName}`}
                type="text"
                value={ this.state.phone_number }
                autoComplete="off"
                name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][phone_number]`}
                id={`${this.props.addressOwner}_${this.props.kind}_info_attributes_phone_number`}
                onChange={(event) => this.handleChange(event, 'phone_number')} />
        </div>
      </div>
    )
  }

  renderCountryInput(){
    const options = this.props.countriesList.map((val) => {
      // eslint-disable-next-line no-undef
      let labelImg = (<span dangerouslySetInnerHTML={{ __html: `<img class="country_flag" src="${require(`../../images/flags/${val[0].toLowerCase()}.svg`)}" alt="${val[1]} flag"><span class="country_label">${val[1]}</span>` }}/>)
      return { value: val[0], label: labelImg, search: val[1] }
    });

    const filter = (option, searchText) => {
      return option.data.search.toLowerCase().includes(searchText.toLowerCase())
        || option.data.value.toLowerCase().includes(searchText.toLowerCase())
    }

    const normalBorderColor = this.state.dark_theme ? '#525252' : '#8c8c8c'
    const errorBorderColor = this.state.dark_theme ? '#ff4e08' : '#d45e69'
    const borderStyles = this.errorFor('country') ? `${errorBorderColor} !important` : normalBorderColor

    const styles = {
      control: (provided) => ({
        ...provided,
        minHeight: '29px',
        height: '29px',
        borderColor: borderStyles,
        fontSize: '14px'
      }),
      menu: (provided) => ({
        ...provided,
        width: '100%'
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        padding: '0 3px',
        display: 'none'
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
      }),
    }

    const dark_styles = {
      ...styles,
      control: (provided) => ({
        ...provided,
        minHeight: '29px',
        height: '29px',
        fontSize: '14px',
        borderColor: borderStyles,
        backgroundColor: '#242424'
      }),
      menu: (provided) => ({
        ...provided,
        width: '100%',
        backgroundColor: '#3b3b3b'
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' :'#ffffff',
        backgroundColor: state.isSelected ? '#01ff95' : '#3b3b3b',
        '&:hover': {
          backgroundColor: '#01ff95',
          color: 'black'
        }
      }),
      singleValue: (provided) => ({
        ...provided,
        color: '#a8a8a8'
      }),
      input: (provided) => ({
        ...provided,
        color: '#a8a8a8'
      })
    }

    return (
      <div>
        <label className="label-address" htmlFor={`${this.props.kind}_country`}>
          Country: <span className="red-asterisk">*</span>
        </label>
        <div className="country-box">
          { this.errorFor('country') && (<div className="error-container"><div className="error-text">{ this.errorFor('country') }</div></div>) }
          <LoadableSelect
            filterOption={filter}
            className={"country_select"}
            name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][country]`}
            value={ options.find(element => element.value === this.state.country) }
            onChange={(event) => this.handleChange(event, 'country')}
            options={options}
            styles={this.state.dark_theme ? dark_styles : styles}
          />
        </div>
      </div>
    )
  }

  renderProvinceSelect(){
    if(!['US', 'CA'].includes(this.state.country)){
      return this.textInput('County / State: <span class="red-asterisk">*</span>', 'province', this.state.province)
    } else {
      const list = this.state.country === 'US' ? this.props.usStatesList : this.props.canadaStatesList
      const options = list.map((val) => {
        return { value: val[1].name, label: val[1].name, search: val[1].name }
      });

      const filter = (option, searchText) => {
        return option.data.search.toLowerCase().includes(searchText.toLowerCase())
          || option.data.value.toLowerCase().includes(searchText.toLowerCase())
      }

      const normalBorderColor = this.state.dark_theme ? '#525252' : '#8c8c8c'
      const errorBorderColor = this.state.dark_theme ? '#ff4e08' : '#d45e69'
      const borderStyles = this.errorFor('province') ? `${errorBorderColor} !important` : normalBorderColor

      const styles = {
        control: (provided) => ({
          ...provided,
          minHeight: '29px',
          height: '29px',
          borderColor: borderStyles,
          fontSize: '14px'
        }),
        menu: (provided) => ({
          ...provided,
          width: '100%'
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 3px',
          display: 'none'
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none'
        }),

      }

      const dark_styles = {
        ...styles,
        control: (provided) => ({
          ...provided,
          minHeight: '29px',
          height: '29px',
          fontSize: '14px',
          borderColor: borderStyles,
          backgroundColor: '#242424'
        }),
        menu: (provided) => ({
          ...provided,
          width: '100%',
          backgroundColor: '#3b3b3b'
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'black' :'#ffffff',
          backgroundColor: state.isSelected ? '#01ff95' : '#3b3b3b',
          '&:hover': {
            backgroundColor: '#01ff95',
            color: 'black'
          }
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#a8a8a8'
        }),
        input: (provided) => ({
          ...provided,
          color: '#a8a8a8'
        })
      }

      return (
        <div>
          <label className="label-address" htmlFor={`${this.props.kind}_province`}>
            County / State: <span className="red-asterisk">*</span>
          </label>
          <div className="province-box">
            { this.errorFor('province') && (<div className="error-container"><div className="error-text">{ this.errorFor('province') }</div></div>) }
            <LoadableSelect
              filterOption={filter}
              className='province_select'
              name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][province]`}
              value={ options.find(element => element.value === this.state.province) }
              onChange={(event) => this.handleChange(event, 'province')}
              options={options}
              styles={this.state.dark_theme ? dark_styles : styles}
            />
          </div>
        </div>
      )
    }
  }


  renderNameCompanyMobile() {
    return (
      <div>
        { this.textInput('First Name: <span class="red-asterisk">*</span>', 'first_name', this.state.first_name) }

        { this.textInput('Last Name: <span class="red-asterisk">*</span>', 'last_name', this.state.last_name) }

        { this.textInput('Company Name:', 'company_name', this.state.company_name) }

        { this.textInput('VAT number: (optional)', 'vat_number', this.state.vat_number) }

        { this.renderTelephoneInput() }

        <p className='tc br3 blue-label'>Add your number for updates & offers by text.</p>
      </div>
    )
  }

  renderShippingInfoPart () {
    return(
      <div>
        <div className='lform'>
          <input id="same-shipping" name={`${this.props.addressOwner}[shipping_info_attributes][same]`} type="checkbox" value='1' checked={ this.state.sameAddress } onChange={(event) => this.handleChange(event, 'sameAddress')}/>
          <label htmlFor="same-shipping">Shipping Address is the same as Billing Address</label>
        </div>
        { !this.state.sameAddress && <ShippingInfo ref={this.child} {...this.props.shippingInfo} /> }
      </div>
    )
  }

  renderFullForm() {
    return (
      <div>
        { this.renderNameCompanyMobile() }
        { this.renderAddressAutocomplete() }
        { this.renderManualAddressCompletion() }
        <div className='lform'>
          <input name={`${this.props.addressOwner}[billing_info_attributes][store]`} type="hidden" value="0"/>
          <input id="store-billing" name={`${this.props.addressOwner}[billing_info_attributes][store]`} type="checkbox" value='1' checked={ this.state.store } onChange={(event) => this.handleChange(event, 'store')}/>
          <label htmlFor="store-billing">Store my Billing Address in My Account</label>
        </div>

        { this.props.shippingInfo && this.props.shippingInfo.address &&
          (<div className="shipping-info" ref={this.thirdScrollRef}>
            <p className='tc br3 blue-label'>Your purchase includes physical item(s). Please choose shipping preferences.</p>
            { this.renderShippingInfoPart() }
            <div className='lform'>
              <input name={`${this.props.addressOwner}[shipping_info_attributes][store]`} type="hidden" value="0"/>
              <input id="store-shipping" name={`${this.props.addressOwner}[shipping_info_attributes][store]`} type="checkbox" value='1' checked={ this.state.storeShipping } onChange={(event) => this.handleChange(event, 'storeShipping')}/>
              <label htmlFor="store-shipping">Store my Shipping Address in My Account</label>
            </div>
          </div>) }

        <div className="billing-info-actions">
          <button className="btn-continue-checkout button-plain" onClick={this.validatePresence}>Continue</button>
          { this.props.backToStartLink && <a href="/checkout/transition?transition=back_to_start" className="back-to-prev">&lt; Back To Previous</a> }
        </div>
      </div>
    )
  }

  countryName() {
    return this.state.country && this.props.countriesList.find(el => el[0] === this.state.country)[1]
  }

  renderReadOnlyView() {
    return (
      <div>
        <div className="full-address">
          <div>{ this.state.first_name } { this.state.last_name }</div>
          <div>{ this.state.phone_number ? this.prefixNumber() : '' } { this.state.phone_number }</div>
          <div>{ this.state.address_1 }</div>
          <div>{ this.state.address_2 }</div>
          <div>{ this.state.postal_code }&nbsp;{ this.state.city }</div>
          <div>{ this.state.province }</div>
          <div>{ this.countryName() }</div>
          <hr/>
          <a href={this.props.editAddressPath || "/checkout/transition?transition=edit_billing_info"} className={ this.state.aDisabled ? 'a-disabled' : '' }>Edit</a>
        </div>
        { this.props.shippingInfo && this.props.shippingInfo.address && <ShippingInfo readOnly={true} {...this.props.shippingInfo}/> }
      </div>
    )
  }

  render() {
    return (
      <div className="step2-content">
        <div className="step2-billing-info" ref={this.firstScrollRef}>
          <h3 className="billing-address-title">Billing Information</h3>
          { this.props.readOnly && (<h4 className="billing-address-title-light">Please double check your details before checking out.</h4>) }
          <form action={this.props.submitPath || "/checkout/transition"} method="post" className="billing-info-content billing-info-form">
            <input type="hidden" name="transition" value="update_billing_info"/>
            <input type="hidden" name="authenticity_token" value={ csrfToken() }/>
            <input type="hidden" name={`${this.props.addressOwner}[billing_info_attributes][from_form]`} value="true"/>
            <div className="email-box">
              <div className="text-address-wrapper">
                <label className="label-address">Email Address: <span className="red-asterisk">*</span></label>
                { this.props.readOnly && (<i className="icon-blue-info"></i>) }
                <p className="text-address">{ this.props.user.email }</p>
              </div>
              { this.props.backToStartLink && <a href="/checkout/transition?transition=back_to_start" className={ 'button-plain button-edit' + (this.state.aDisabled ? ' a-disabled' : '') }>Edit</a> }
            </div>
            { (this.props.readOnly ? this.renderReadOnlyView() : this.renderFullForm()) }
          </form>
        </div>
      </div>
    )
  }
}

BillingInfo.propTypes = {
  user: PropTypes.object.isRequired,
  address: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  shippingInfo: PropTypes.object,
  backToStartLink: PropTypes.bool,
  submitPath: PropTypes.string,
  editAddressPath: PropTypes.string,
  addressOwner: PropTypes.string,
  countriesTelephonePrefixes: PropTypes.array,
  countriesList: PropTypes.array,
  kind: PropTypes.string,
  usStatesList: PropTypes.array,
  canadaStatesList: PropTypes.array,
  errors: PropTypes.object,
  dark_theme: PropTypes.bool,
  sameAddress: PropTypes.bool
}

export default BillingInfo
