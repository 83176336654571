import React from 'react'
import PropTypes from 'prop-types'
import PasswordMeter from './PasswordMeter'

class PasswordWithMeter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      errors: this.props.errors,
      password: ''
    }

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.setState({
      password: event.target.value
    })
  }

  render() {
    const { errors, password } = this.state

    return (
      <div className="password-with-meter">
        <div className="password-with-meter-input">
          <input
            className={errors ? 'error' : null}
            type="password"
            id="user_password"
            autoComplete="off"
            placeholder="password"
            name="user[password]"
            value={password}
            onChange={this.handleChange}
          />
          { errors ?
            <span className="formError">{errors}</span> :
            null
          }
        </div>
        <PasswordMeter password={password}/>
      </div>
    )
  }
}

PasswordWithMeter.propTypes = {
  errors: PropTypes.string.isRequired
}

export default PasswordWithMeter
