import React from 'react'

class BPSInfo extends React.Component {
  render() {
    return (
      <div className='BPSInfo'>
        <div className='BPSInfo-content'>
          <div className='BPSInfo-content-body'>
            <div className='BPSInfo-content-body-section'>
              <div className='BPSInfo-content-body-section-controlIcon'>

              </div>
              <div className='BPSInfo-content-body-section-title'>
                90+ plugins to choose from
              </div>
              <div className='BPSInfo-content-body-section-desc'>
                Choose up to 10 plugins every month from an ever-expanding selection, for constant experimentation and ultimate flexibility.
              </div>
            </div>
            <div className='BPSInfo-content-body-section'>
              <div className='BPSInfo-content-body-section-soundsIcon'>

              </div>
              <div className='BPSInfo-content-body-section-title'>
                Half a million sounds
              </div>
              <div className='BPSInfo-content-body-section-desc'>
                Streamline your sound discovery and find your perfect sound with a deep library of carefully curated royalty-free samples by iconic artists.
              </div>
            </div>
            <div className='BPSInfo-content-body-section'>
              <div className='BPSInfo-content-body-section-chipIcon'>

              </div>
              <div className='BPSInfo-content-body-section-title'>
                Own your sound forever
              </div>
              <div className='BPSInfo-content-body-section-desc'>
                No need to worry when cancelling, pausing or choosing new plugins. Previous selections will still work in your DAW using the settings you saved, forever.
              </div>
            </div>
          </div>
          <div onClick={() => this.props.handleScroll(this.props.pricingRef)} className='BPSInfo-content-btn bps-btn'>
            Try for Free
          </div>
        </div>
      </div>
    )
  }
}

export default BPSInfo