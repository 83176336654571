import React from 'react'
import PropTypes from 'prop-types';

class UserRateDetailsSection extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        name: PropTypes.string.isRequired,
        order_item_id: PropTypes.number.isRequired
    };

    render() {
        var prefix = 'rate-' + this.props.order_item_id + '-' + this.props.name + '-'
        return (
            <div className="userrate-section">
                <h4>{ this.props.title }</h4>

                <div className="userrate-stars">
                    <input type="radio" name={this.props.name} id={ prefix + 5} value="5" />
                    <label htmlFor={ prefix + 5 }>5</label>

                    <input type="radio" name={this.props.name} id={ prefix + 4} value="4" />
                    <label htmlFor={ prefix + 4 }>4</label>

                    <input type="radio" name={this.props.name} id={ prefix + 3} value="3" />
                    <label htmlFor={ prefix + 3 }>3</label>

                    <input type="radio" name={this.props.name} id={ prefix + 2} value="2" />
                    <label htmlFor={ prefix + 2 }>2</label>

                    <input type="radio" name={this.props.name} id={ prefix + 1} value="1" />
                    <label htmlFor={ prefix + 1 }>1</label>
                </div>
            </div>
        )
    }
}

export default UserRateDetailsSection
