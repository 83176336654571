import React from 'react'
import UserProductSerial from './UserProductSerial'

class UserProductSerials extends React.Component {
  render() {
    var serial_numbers = this.props.order_item.serial_numbers
    var item_need_serial = this.props.order_item.product.needs_serial_number
    const kajabiText = 'Please check your mailbox. You should receive an email from School of Synthesis to confirm your registration on their platform. You can access your course there!'
    const noSerialText = 'No further authorisation of this software is required. Download to your computer and install'
    const noSerialKajabiText = this.props.order_item.is_kajabi_order_item ? kajabiText : noSerialText

    if ((!serial_numbers || serial_numbers.length == 0 ) && !item_need_serial ) {
      return (
        <div className="userproduct-serials">
          <h4>{noSerialKajabiText}</h4>
        </div>
      )
    }

    return (
      <div className="userproduct-serials">
        { _.map(serial_numbers, function(serial) {
          return <UserProductSerial key={ 'ps' + serial.serial_number_id } serial_number={ serial } />
        }) }
      </div>
    )
  }
}

export default UserProductSerials
