import React from 'react'
import RatingStars from './RatingStars'

class RatingInfoDetailsItem extends React.Component {
    render() {

        return (
            <div className="ratinginfo-details-item">
                { this.props.name }
                <RatingStars value={ this.props.value } />
            </div>
        )
    }
}

export default RatingInfoDetailsItem
