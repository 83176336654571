import React from "react"
import PropTypes from "prop-types"
import '../src/dtl'
import '../src/mess'

class WishlistCounter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: this.props.count || 0
        };
    }

    componentDidMount () {
        Mess.subscribe('refresh_wishlist', this.refreshWishlist.bind(this))
        Mess.subscribe('refresh_wishlist', this.refreshWishlist.bind(this))
    }

    refreshWishlist (data) {
        this.setState({ count: data.wishlistCount })
    }

    render () {
        let x = dtl('a','b')
        return (
            this.state.count > 0 ?
            <a href="/wishlist" title="Wishlist">Wishlist<em>{ this.state.count }</em></a> :
            <a href="/wishlist" title="Wishlist" className="--empty">Wishlist</a>
        )
    }

};

WishlistCounter.propTypes = {
    count: PropTypes.number
};

export default WishlistCounter
