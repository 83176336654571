import React from 'react'

class NavigationLinks extends React.Component {

  chunkValue() {
      var size = this.props.item.children.length
      var cols = this.props.cols || 4
      return  size <= 10 * cols ? 10 : Math.ceil(size / cols)
  }

  render() {
      var allLinks = this.props.item.children

      return (
          <div className="navigation-links">
              {
                  _.map(_.chunk(allLinks, this.chunkValue()), function(columnLinks) {

                      return (
                          <ul>
                              {
                                  _.map(columnLinks, function(el, i) {
                                      return <a key={ 'link' + i } href={ el.path } title={ el.title } data-layer={ el.data_layer }>{ el.title }</a>
                                  })
                              }
                          </ul>
                      )
                  })
              }
          </div>
      )
  }
}
export default NavigationLinks
