import React from 'react'

class GalleryActivator extends React.Component {
  handleClick = () => {
      Mess.publish('toggle_gallery')
  };

  render() {
      return (
          <div className="gallerybutton" onClick={ this.handleClick }>media gallery</div>
      )
  }
}

export default GalleryActivator
