import React from 'react'
import csrfToken from '../../src/csrf_token'
import Loadable from 'react-loadable';

const LoadableReCAPTCHA = Loadable({
  loader: () => import('react-google-recaptcha'),
  loading() {
    return <div>Loading...</div>
  }
});


class CartLoginEmail extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: this.props.email || '',
      error: false,
      captchaToken: null
    }

    this.updateInputValue = this.updateInputValue.bind(this)
    this.continue = this.continue.bind(this)
    this.captchaonChange = this.captchaonChange.bind(this)
  }

  updateInputValue(event) {
    this.setState({
      inputValue: event.target.value
    })
  }

  captchaonChange(token) {
    this.setState({
      captchaToken: token
    })
  }

  continue() {
    const { inputValue, captchaToken } = this.state
    const { toStepTwo } = this.props

    if (this.validate_regexp(inputValue) === false) {
      this.setState({
        error: true
      })

      return false
    }

    if (!captchaToken) {
      this.setState({
        captchaError: true
      })

      return false
    }

    this.setState({
      error: false
    })

    // make call to check if user exist
    $.ajax({
      url: '/users/user_exists?email=' + encodeURIComponent(inputValue) + '&g-recaptcha-response=' + captchaToken,
      dataType: 'json',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
        if(data.exists)
          toStepTwo(inputValue)
        else
        location.href = '/checkout/transition?transition=login_user'
      }.bind(this)
    });
  }

  validate_regexp(email) {
    // regex taken from this site: http://emailregex.com/
    const email_regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (email_regexp.test(email)) return true

    return false
  }

  render() {
    const { inputValue, error, captchaError } = this.state

    return (
      <div className="cart-login-email">
        <div className="cart-login-email-text-container">
          <div className="cart-login-email-text">
            Type in your email to get started
          </div>
        </div>

        <div className="cart-login-email-email-container">
          <div className="cart-login-email-email-label">
            <label htmlFor="email-input">Email Address:<span className="red-asterisk">*</span></label>
          </div>
          {error ? (
            <div className="error-container">
              <div className="error-text">
                Wrong email
              </div>
            </div>
          ) : null}
          <div className="cart-login-email-email-input">
            <input
              type="email"
              id="email-input"
              value={inputValue}
              onChange={this.updateInputValue}
              className={error ? 'red-border' : ''}
            />
          </div>
        </div>

        <div className="cart-login-password-recaptcha-hint">
          <div className="cart-login-password-recaptcha-hint-text">
            Please check the box below to proceed.
          </div>
        </div>

        <div className="cart-login-password-recaptcha-container">
          <div className="cart-login-password-recaptcha">
            <LoadableReCAPTCHA
              sitekey={process.env.RECAPTCHA_SITE_KEY}
              onChange={this.captchaonChange}
            />
            { captchaError && (
              <div className="error-container">
                <div className="error-text">
                  This field is required
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="cart-login-email-continue-button-container">
          <div
            className="cart-login-email-continue-button"
            onClick={this.continue}
          >
            <div className="cart-login-email-continue-button-text">
              Continue
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CartLoginEmail
