import React from 'react'

class UserCourseExpander extends React.Component {
    render() {
        return (
            <div className="userproduct-expander">
                <a href="#expand" title={ '' } onClick={ this.props.onClick } className="button-down">Course Details</a>
            </div>
        )
    }
}

export default UserCourseExpander
