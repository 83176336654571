import React from 'react'

class BPSContentTiles extends React.Component {
  render() {
    return(
      <div className='BPSContentTiles'>
        <div className='BPSContentTiles-content'>
          <picture>
            <source media="(min-width: 768px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile1_m@x2.png`)} />
            <source media="(min-width: 768px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile1_m@x1.png`)} />
            <source media="(min-width: 0px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile1_sm@x2.png`)} />
            <source media="(min-width: 0px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile1_sm@x1.png`)} />
            <img src={require(`../../images/beatport_studio/content_tiles/content_tile1_m@x2.png`)} className='BPSContentTiles-content-headerImage' loading="lazy" />
          </picture>

          <video
            autoPlay
            loop
            muted
            preload="none"
            poster={require(`../../images/beatport_studio/content_tiles/content_tile1_m@x2.png`)}
            className='BPSContentTiles-content-headerVideo'
          >
            <source src={require(`../../images/beatport_studio/content_tiles/content_tile1.webm`)} type="video/webm" />
          </video>
          <div className='BPSContentTiles-content-body'>
            <div className='BPSContentTiles-content-body-title'>
              Creativity and flexibility without limits
            </div>
            <div className='BPSContentTiles-content-body-desc'>
              <div className='BPSContentTiles-content-body-desc-text'>
                <div>Choose a new set of plugins <span className='BPSContentTiles-content-body-desc-text-highlight'>monthly</span> from <span className='BPSContentTiles-content-body-desc-text-highlight'>90+ Instruments, Effects and Utilities</span> available.<br /><br />With Beatport Studio, you will always have access to new tools to develop your sound and achieve new levels of creativity.
                </div>
              </div>
              <div onClick={() => this.props.handleScroll(this.props.pricingRef)} className='BPSContentTiles-content-body-desc-btn bps-btn'>
                Try for Free
              </div>
            </div>
          </div>
        </div>
        <div className='BPSContentTiles-content'>
          <picture>
            <source media="(min-width: 768px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile2_m@x2.png`)} />
            <source media="(min-width: 768px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile2_m@x1.png`)} />
            <source media="(min-width: 0px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile2_sm@x2.png`)} />
            <source media="(min-width: 0px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile2_sm@x1.png`)} />
            <img src={require(`../../images/beatport_studio/content_tiles/content_tile2_m@x2.png`)} className='BPSContentTiles-content-headerImage' loading="lazy" />
          </picture>
          <video
            autoPlay
            loop
            muted
            preload="none"
            poster={require(`../../images/beatport_studio/content_tiles/content_tile2_m@x2.png`)}
            className='BPSContentTiles-content-headerVideo'
          >
            <source src={require(`../../images/beatport_studio/content_tiles/content_tile2.webm`)} type="video/webm" />
          </video>
          <div className='BPSContentTiles-content-body'>
            <div className='BPSContentTiles-content-body-title'>
              Experiment and build with Frozen State Mode
            </div>
            <div className='BPSContentTiles-content-body-desc'>
              <div className='BPSContentTiles-content-body-desc-text'>
                <div>Designed for <span className='BPSContentTiles-content-body-desc-text-highlight'>unlimited flexibility</span> and discovery, Frozen State Mode allows for previously selected plugins to continue to work in their last saved state.<br /><br />No matter if you change your plugin selection, pause or cancel your membership, you can keep producing with the confidence that <span className='BPSContentTiles-content-body-desc-text-highlight'>your projects are safe.</span></div>
              </div>
              <div onClick={() => this.props.handleScroll(this.props.pricingRef)} className='BPSContentTiles-content-body-desc-btn bps-btn'>
                Try for Free
              </div>
            </div>
          </div>
        </div>
        <div className='BPSContentTiles-content'>
          <picture>
            <source media="(min-width: 768px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile3_m@x2.png`)} />
            <source media="(min-width: 768px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile3_m@x1.png`)} />
            <source media="(min-width: 0px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile3_sm@x2.png`)} />
            <source media="(min-width: 0px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/content_tiles/content_tile3_sm@x1.png`)} />
            <img src={require(`../../images/beatport_studio/content_tiles/content_tile3_m@x2.png`)} className='BPSContentTiles-content-headerImage' loading="lazy" />
          </picture>
          <video
            autoPlay
            loop
            muted
            preload="none"
            poster={require(`../../images/beatport_studio/content_tiles/content_tile3_m@x2.png`)}
            className='BPSContentTiles-content-headerVideo'
          >
            <source src={require(`../../images/beatport_studio/content_tiles/content_tile3.webm`)} type="video/webm" />
          </video>
          <div className='BPSContentTiles-content-body'>
            <div className='BPSContentTiles-content-body-title'>
              Streamline your sample discovery
            </div>
            <div className='BPSContentTiles-content-body-desc'>
              <div className='BPSContentTiles-content-body-desc-text'>
                <div>Find the perfect sample for your track with real-time auditioning from our royalty-free and ever-expanding library.<br /><br />Discover sounds from within the DAW using the Loopcloud Sounds plugin, all synced to the Key & BPM of your track.</div>
              </div>
              <div onClick={() => this.props.handleScroll(this.props.pricingRef)} className='BPSContentTiles-content-body-desc-btn bps-btn'>
                Try for Free
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BPSContentTiles
