import React from 'react'
import csrfToken from '../src/csrf_token'

class ExtraVc extends React.Component {
    state = {
        count: 0,
        reward_tokens: parseInt(this.props.reward_tokens || 0),
        processing: false,
        nothing_selected: false
    };

    componentDidMount() {
        Mess.subscribe('refresh_extra_vc', this.handle_refesh)
    }

    handle_change = () => {
        var radios = this.refs.form.elements['count']
        this.setState({ nothing_selected: false })
        if (radios.value) {
            this.setState({ count: radios.value })
        }

        for (var i = 0; i < radios.length; i++) {
            if (radios[i].checked) {
                this.setState({ count: radios[i].value })
                return
            }
        }
    };

    handle_refesh = () => {

        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: '/users/wallet_status',
            dataType: 'json',
            headers: {
    					"X-CSRF-Token": csrfToken()
    				},
            success: (data) => this.setState({ reward_tokens: data.reward_tokens, count: 0, processing: false }),
            error: () => this.setState({ processing: false })
        })
    };

    handle_convert = (event) => {
        event.preventDefault()
        if (this.state.processing) {
            return
        }

        const count = this.state.count

        if (count === 0) {
            this.setState({ nothing_selected: true })
            return
        }

        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: '/rewards/get_extra_vc/' + count,
            dataType: 'json',
            method: 'POST',
            headers: {
    					"X-CSRF-Token": csrfToken()
    				},
            success: function(data) {
                this.setState({ processing: false, reward_tokens: data.reward_tokens, count: 0 })
                Mess.publish('show_flash', data)

                Mess.publish('refresh_wallet')
                Mess.publish('refresh_samplepacks')
                Mess.publish('refresh_coupons')
            }.bind(this),
            error: function(xhr, status, err) {
                // console.error(this.props.url, status, err.toString());
                this.setState({ processing: false })

            }.bind(this)
        });
    };

    render() {
        let clazz = 'extravc' + ( this.state.processing ? ' processing' : '' )

        if (this.state.reward_tokens == 0) {
            return <div className="extravc _info">No Reward Tokens available at this moment</div>
        }

        return (
            <div className={ clazz }>
                <form ref="form" onChange={ this.handle_change } onSubmit={ this.handle_convert }>

                    <div className="extravc-form">
                        <p className="extravc-tokens">
                            { this.render_tokens() }
                        </p>
                        <p className="extravc-cash">&pound;{ this.state.count > 0 ? (this.state.count * 1.25).toFixed(2) : 0 }</p>
                        { this.state.nothing_selected ? <p>No reward tokens selected. Please select at least one to convert.</p> : null }
                    </div>

                    <input type="submit" value="Convert" className="extravc-action"/>
                </form>
            </div>
        )
    }

    render_tokens = () => {
        var tokens = [],
            max = (this.state.reward_tokens > 10 ? 10 : this.state.reward_tokens)

        for (var i = max; i > 0; i--) {
            tokens.push(
                <input type="radio" name="count" key={ 'extravcin-' + i } id={ 'extravc-' + i } value={ i } />
            )

            tokens.push(
                <label key={ 'extravcla-' + i } htmlFor={ 'extravc-' + i }>{ i }</label>
            )
        }

        return tokens
    };
}
export default ExtraVc
