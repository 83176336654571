import React from 'react'
import UserCourse from './UserCourse'
import UserProduct from './UserProduct'
import ListPager from './ListPager'
import csrfToken from '../src/csrf_token'

class UserProductsBox extends React.Component {
  constructor(props) {
      super(props)
      this.state = { items: {}, visibleIds: [], page: 1, processing: false }
  }

  componentDidMount() {
      this._changeItems(1)
  }

  componentDidUpdate() {
      const y = this.refs.box.getBoundingClientRect().top + window.pageYOffset - 20
      console.log('updated: ', y)

      if (y > 0) {
          setTimeout(function() { window.scrollTo(0, y) }, 400)
      }
  }

//     var objDiv = document.getElementById(list);
// window.scrollTo(objDiv.scrollTop, 0)

  _maxPage() {
      return Math.ceil(this.props.productIds.length / 5)
  }

  _changeItems(page) {
      page = page || this.state.page
      // console.log('_changeItems, this.state.page>', page)
      const from = (page - 1) * 5
      const ids = this.props.productIds.slice(from, from + 5)

      // console.log('_items, ids: ', ids)

      if (this.state.items[ids[0]]) {
          this.setState({ visibleIds: ids })

      } else {
          this._fetchItems(ids)
      }
  }

  _fetchItems(ids) {
      this.setState({ processing: true })
      $.ajax({
          url: window.location.href,
          dataType: 'json',
          data: { ids: ids },
          headers: {
            "X-CSRF-Token": csrfToken()
          },
          success: (data) => {
              const items = this.state.items
              this.setState({ items: { ...items, ...data }, processing: false })
              this._changeItems()
          },
          error: (xhr, status, err) => {
              this.setState({ processing: false })
              console.error(this.props.url, status, err.toString());
          }
      })
  }

  _changePage = (newPage) => {
      this.setState({ page: newPage })
      this._changeItems(newPage)
  }

  _renderItem(item, expanded) {
      if (item.course) {
          return <UserCourse key={ item.order_item_id } order_item={ item } expanded={ expanded } />
      }

      return <UserProduct key={ item.order_item_id } order_item={ item } expanded={ expanded } fallbackImageSrc={ this.props.fallbackImageSrc }/>
  }

  render() {
      const { productIds } = this.props
      const { page, processing, visibleIds } = this.state

      return (
          <div ref="box" className="userproductbox">
              {
                  visibleIds.map((id, idx) => {
                      let item = this.state.items[id]
                      if (item) return this._renderItem(item, true)
                  })
              }
              <ListPager page={ page } max={ this._maxPage() } onChange={ this._changePage } processing={ processing }/>
          </div>
      )

  }
}


export default UserProductsBox
