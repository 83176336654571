import React from 'react'
import ProcessLine from './ProcessLine'
import PopupInfoBox from './PopupInfoBox'
import csrfToken from '../src/csrf_token'

class PopupInfo extends React.Component {
    state = { content: null, visible: false, processing: false };

    handle_show = (event) => {

        event.preventDefault()
        event.stopPropagation()

        if (this.state.processing) {
            return
        }

        if (this.state.content) {
            this.setState({ visible: true })
            return
        }

        this.setState({ processing: true })

        $.ajax({
            url: this.props.link,
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ content: data, visible: true, processing: false } )

            }.bind(this),
            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                this.setState({ processing: false })
            }.bind(this)
        });
    };

    handle_close = () => {
        this.setState({ visible: false })
    };

    render() {
        var clazz = 'popupinfo' + (this.state.processing ?  ' _process' : '')
        return (
            <span className={ clazz }>
                <a href={ this.props.link } className={ clazz } title={ this.props.name } onClick={ this.handle_show } >
                    { this.props.name }
                </a>
                <ProcessLine processing={ this.state.processing } />
                { this.state.visible ? <PopupInfoBox content={ this.state.content } onClose={ this.handle_close } /> : null }
            </span>
        )
    }
}

export default PopupInfo
