import React from 'react'

class UserRateButton extends React.Component {
  render() {
    const rentToOwn = !!this.props.rentToOwn
    const buttonClass = rentToOwn ? 'userrate-rentToOwn' : 'userrate-button'
    const canEarnVC = this.props.canEarnVC
    const ratingTitle = canEarnVC ? 'Rate this product and earn Virtual Cash' : 'Rate this product'
    const ratingClassName = rentToOwn ? '' : 'button-rateit'
    const ratingText = canEarnVC ? 'Rate It and Earn VC' : 'Rate It'

    return (
      <div className={buttonClass}>
        <a href="#rateit" title={ratingTitle} className={ratingClassName} onClick={this.props.onClick}>{ratingText}</a>
        {/* we don't want display this text for rent to own product (even though we will give user vc on rating rant to own product.) */}
        { (canEarnVC && !rentToOwn) && <p>£0.20 Virtual Cash for each rating.</p> }
      </div>
    )
  }
}

export default UserRateButton
