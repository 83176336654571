import React from 'react'

const DefaultMessage = ({ message, handleMessageClick }) => (
  <div className="DefaultMessage" onClick={() => handleMessageClick(message)}>
    <div className="DefaultMessage-body rcw-client rcw-client-user">
      <div className="rcw-message-text rcw-message-text-default">
        <div className="DefaultMessage-body-icon sparkle-icon"></div>
        {message}
      </div>
    </div>
  </div>
)

export default DefaultMessage
