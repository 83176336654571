import React from 'react'
import BuyBoxAdd from './BuyBoxAdd'
import csrfToken from '../src/csrf_token'

class UserCouponsItemAction extends React.Component {
    state = { processing: false };

    handle_get_coupon = () => {
        if (this.state.processing || !this.props.coupon.action_url) {
            return
        }

        this.setState({processing: true })

        this.xhr = $.ajax({
            url: this.props.coupon.action_url,
            dataType: 'json',
            method: 'POST',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ processing: false })
                Mess.publish('show_flash', data)

                Mess.publish('refresh_wallet')
                Mess.publish('refresh_samplepacks')
                Mess.publish('refresh_coupons')
                Mess.publish('refresh_extra_vc')
            }.bind(this),
            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                this.setState({ processing: false })

            }.bind(this)
        });
    };

    render() {
        var coupon = this.props.coupon,
            price = coupon.reward_tokens

        return (
            <div className="usercoupons-item-action">
                { coupon.code ?
                    <BuyBoxAdd item_id={ coupon.product.product_id } in_cart={ coupon.product.in_cart } /> :
                    ( coupon.available ? <button onClick={ this.handle_get_coupon }><em>{ price }</em>I want this coupon</button> : <button className="_notokens" disabled="disabled"><em>{ price }</em>Not available</button>)
                }
            </div>
        )
    }
}









export default UserCouponsItemAction
