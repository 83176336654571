import React from 'react'
import PropTypes from 'prop-types'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class CartGiftItemMoreInfoModal extends React.Component {
  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  renderProductDescription(gift) {
    if (gift.description.length > 100) {
      return (
        <div className="CartGiftItemMoreInfoModal-content-info-productDescription">
          <div>{_.trunc(gift.description, 100)}</div> <a href={gift.product.link_to}>Read more</a>
        </div>
      )
    } else {
      return (
        <div className="CartGiftItemMoreInfoModal-content-info-productDescription">
          <div>{gift.description}</div>
        </div>
      )
    }
  }

  render() {
    if (!this.props.isModalOpen) {
      return null
    }

    const gift = this.props.gift
    const product = gift.product

    return (
      <div className="CartGiftItemMoreInfoModal">
        <div className="CartGiftItemMoreInfoModal-wrapper">
          <div className="CartGiftItemMoreInfoModal-cross" onClick={e => { this.props.onCloseClick(e) }}></div>

          <div className="CartGiftItemMoreInfoModal-content">
            <div className="CartGiftItemMoreInfoModal-content-info">
              <div className="CartGiftItemMoreInfoModal-content-info-title">
                Claim your free gift
              </div>
              <a href={product.link_to} className="CartGiftItemMoreInfoModal-content-info-productName">
                {product.name}
              </a>
              <div>
                <a href={product.category.link_to} className="CartProductItem-productData--category">
                  {product.category.name}
                </a>
                <span className="CartProductItem-productData--by"> by </span>
                <a href={product.manufacturer.link_to} className="CartProductItem-productData--manufacturer">
                  {product.manufacturer.name}
                </a>
              </div>

              {this.renderProductDescription(gift)}

              <div className="CartGiftItemMoreInfoModal-content-info-priceText">
                Price
              </div>

              <div className="CartGiftItemMoreInfoModal-content-info-price">
                <span className="CartGiftItemMoreInfoModal-content-info-priceValue">{gift.value}</span>
                <span className="CartGiftItemMoreInfoModal-content-info-priceFree"> FREE</span>
              </div>

              <div className="CartGiftItemMoreInfoModal-content-info-disclaimer">
                Please note only one free gift can be redeemed per purchase.
              </div>
            </div>

            <a href={product.link_to} className="CartGiftItemMoreInfoModal-content-imgContainer">
              <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

CartGiftItemMoreInfoModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  gift: PropTypes.object.isRequired
}

export default CartGiftItemMoreInfoModal
