import React from 'react'
import Ribbons from './Ribbons';

class SearchResultArticle extends React.Component {
  render() {
      var item = this.props.item
      return item.kind == 'article' ? (
          <div className="searchresult _article">
              <Ribbons ribbons={{ review: 'Review' }} />
              <a href={ item.articleLink } title={ item.article } data-layer={ dtl('search/suggestions') } className="searchresult-articlelink">{ item.article }</a>
          </div>
      ) : null
  }
}
export default SearchResultArticle
