import React from 'react'
import CourseModule from './CourseModule'

class CourseModules extends React.Component {
    render() {
        return (
            <div className="courseplayer-modules">
                <h2>Course Modules</h2>

                <ul>
                    { _.map(this.props.modules, function(item, idx) {
                        return <CourseModule key={ item.course_module_id } module={ item } playing={ idx == this.props.activeIdx } idx={ idx } onClick={ this.props.onModuleChange }/>
                    }.bind(this)) }
                </ul>

            </div>
        )
    }
}

export default CourseModules
