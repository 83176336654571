import React from 'react'
import GalleryContent from './GalleryContent'
import GalleryHeader from './GalleryHeader'
import GalleryFooter from './GalleryFooter'

class Gallery extends React.Component {
    state = {
        visible: true,
        fullscreen: false,
        idx: 0
    };

    componentDidMount() {
        Mess.subscribe('toggle_gallery', this.toggleGallery)
    }

    toggleGallery = () => {
        this.setState({ visible: !this.state.visible })
    };

    handleChange = (idx) => {
        this.setState({ idx: idx })
    };

    handleClose = () => {
        this.setState({ visible: false })
    };

    handle_fullscreen = (event) => {
        var fullscreen = this.state.fullscreen
        event.preventDefault()

        if (fullscreen) {
            this.setState({ fullscreen: false, idx: 0 })

        } else {
            this.setState({ fullscreen: true })
            dataLayer.push(dtl('product page/gallery:fullscreen', { raw: true, label: this.props.title }))
        }
    };

    render() {
        if (!this.state.visible) {
            return <span/>
        }

        var clazz = 'gallery' + ( this.state.fullscreen ? ' gallery-full' : '' )
        var footerSize = this.state.fullscreen ? 5 : 4

        if (this.props.items.length == 0) {
            return (
                <div className={ clazz }>
                     <GalleryContent src={null} desc="no image" kind="fallback" fallbackImageSrc={ this.props.fallbackImageSrc }/>)
                </div>
            )
        }

        return (
            <div className={ clazz }>
                <GalleryContent src={ this.props.items[this.state.idx].src } desc={ this.props.items[this.state.idx].desc } kind={ this.props.items[this.state.idx].kind } item={ this.props.items[this.state.idx] } fallbackImageSrc={ this.props.fallbackImageSrc }/>

                { this.state.fullscreen ? <GalleryHeader title={ this.props.items[this.state.idx].desc }/> : null }

                { this.props.items.length > 1 ? <GalleryFooter items={ this.props.items } active={ this.state.idx } onChange={ this.handleChange } footerSize={ footerSize }/> : null }


                <a href="#fs" className="gallery-fullscreen" title={ this.state.fullscreen ? 'Close Media Gallery' : 'Open Media Gallery' } onClick={ this.handle_fullscreen }>{ this.state.fullscreen ? 'Close Media Gallery' : 'Open Media Gallery' }</a>
            </div>
        )
    }
}

export default Gallery
