import React from 'react'
import UserCouponsItemHeader from './UserCouponsItemHeader'
import UserCouponsItemAction from './UserCouponsItemAction'

class UserCouponsItem extends React.Component {
    render() {
        var coupon = this.props.coupon,
            product = coupon.product

        return (
            <li className="usercoupons-item">
                <UserCouponsItemHeader coupon={ coupon } />
                <a href={ product.link_to } title={ product.name } className="usercoupons-item-image">
                    <img src={ product.image_url } alt={ product.image_description }/>
                </a>
                <div className="usercoupons-item-heads">
                    <UserCouponsItemAction coupon={ coupon } on_get_coupon={ this.props.on_get_coupon }/>

                    <h3><a href={ product.link_to } title={ product.name }>{ product.name }</a></h3>
                    <h4><a href={ product.category.link_to } title={ product.category.name }>{ product.category.name }</a> by <a href={ product.manufacturer.link_to } title={ product.manufacturer.name }>{ product.manufacturer.name }</a></h4>

                    { coupon.code ? (
                        <ul>
                            <li>This coupon code has been generated solely for you and can only be used once. Please do not transfer the coupon codes to any third parties.</li>
                        </ul>
                        ) : null
                    }
                </div>
            </li>
        )
    }
}

export default UserCouponsItem
