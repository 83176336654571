import React from 'react'
import PropTypes from 'prop-types';

class UserUpdateDownloadOses extends React.Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        current_os: PropTypes.number.isRequired
    };

    handleChange = (current_os) => {
        this.props.onChange(current_os)
    };

    render() {
        var prefix = 'item-' + this.props.item.product_id + '-'
        var current_os = this.props.current_os
        var macId = this.props.item.os.mac
        var pcId = this.props.item.os.pc
        var isFree = this.props.item.free

        return ( macId && pcId && isFree ?
            <form className="userproduct-download-oses">
                <input type="radio" name="pc" onChange={ this.handleChange.bind(this, pcId) } id={ prefix + 'pc' } checked={ pcId === current_os }/>
                <label className="_pc" htmlFor={prefix + 'pc'}></label>

                <input type="radio" name="pc" onChange={ this.handleChange.bind(this, macId) } id={prefix + 'mac'} checked={ macId === current_os }/>
                <label className="_mac" htmlFor={prefix + 'mac'}></label>
            </form> :

            null
        )
    }
}
export default UserUpdateDownloadOses
