import React from 'react'
import PropTypes from 'prop-types';
import UserUpdateDownloadOses from './UserUpdateDownloadOses'
import BuyBoxAdd from './BuyBoxAdd'

class UserUpdateDownload extends React.Component {
    static propTypes = {
        item: PropTypes.object.isRequired
    };

    state = {
        item: this.props.item,
        multi_platform: this.props.item.os.multi_platform,
        prefered: this.props.item.os.prefered,
    };

    handleOsChnage = (os) => {
        this.setState({ prefered: os })
    };

    handleApiError = (xhr) => {
        if (xhr.status === 403) {
            window.location.reload()
        }
    }

    render() {
        var current_os = this.state.multi_platform ? this.state.multi_platform : this.state.prefered

        return (

                <div>
                    <UserUpdateDownloadOses onChange={ this.handleOsChnage } item={ this.state.item } current_os={ current_os }/>

                    {
                      this.state.item.free ?
                        <a href={ '/users/download_upgrade/' + current_os } key='item' className="button-download userupdate-download js-protect" target="_blank">Download</a> :

                        <div className="button-upgrade">
                            <div className='button-upgrade-content'>
                              <div className="producttile-foot-price">
                                  <span>your price</span>
                                  <em>{this.state.item.sell_price}</em>
                              </div>
                              <div>
                                  <BuyBoxAdd item_id={ current_os } in_cart={ this.state.item.in_cart } update={ true } onApiError={this.handleApiError} />
                              </div>
                            </div>
                        </div>
                    }
                </div>
        )
    }
}

export default UserUpdateDownload
