import React from 'react'
import SearchFilter from './SearchFilter';

class SearchForm extends React.Component {
  state = { any_query: this.props.query && this.props.query.length > 0 };

  handle_form_change = () => {
      var $form = $(this.refs.form)
      var data = $form.serialize()

      this.check_query()
      if (this.state.any_query) {
          this.props.onChange(data)
      }
  };

  clear_form = (event) => {
      var $query = $(this.refs.query)

      if ($query[0]) {
          $query[0].value = ""
          this.props.on_close()
      }

      if (event) {
          event.preventDefault()
          event.stopPropagation()
      }

      this.check_query()
      this.props.on_close()
  };

  handle_close = (event) => {
      this.clear_form(event)
  };

  handle_escape = (event) => {
      if (event.keyCode == 27) {
          this.clear_form(event)
      }
  };

  handle_submit = (event) => {
      this.check_query()
      if (!this.state.any_query) {
          event.preventDefault()
          event.stopPropagation()
      }
  };

  check_query = () => {
      var query = this.refs.query

      if (query) {
          if (query.value.length > 0 && !this.state.any_query) {
              this.setState({ any_query: true })
          }

          if (query.value.length < 1 && this.state.any_query) {
              this.setState({ any_query: false })
          }

      }
  };

  handle_filter_change = (event) => {
      var form = this.refs.form
      if (form && this.state.any_query) {
          if (event) {
              event.stopPropagation()
          }
          form.submit()
      }
  };

  render() {
      var closable = this.state.any_query

      return (
          <form ref="form" action="/search" autoComplete="off" acceptCharset="UTF-8" method="get" onChange={ this.handle_form_change } onKeyUp={ this.handle_escape } onSubmit={ this.handle_submit }>
              <input type="hidden" name="qs" value="match"/>
              <p className="searchbox-inputcontainer searchbox-inputcontainer-dark">
                  <input className="searchbox-searchinput" type="text" ref="query" name="q" placeholder="search..." defaultValue={ this.props.query }/>
                  { closable ? <a href="#close" title="Close search box" onClick={ this.handle_close } data-layer={dtl('search/:close')}>close</a> : <a href="#close" title="Close search box" onClick={ this.handle_close } data-layer={dtl('search/:close')} className="_closed">close</a> }
                  <input type="submit" value="go" className="searchbox-go"/>
              </p>

              <SearchFilter filter={ this.props.filter } onChange={ this.handle_filter_change }/>
          </form>
      )
  }
}

export default SearchForm
