import React from 'react'

class DistributorMessageDetails extends React.Component {
	render() {
		var message = this.props.message

		return (
			<div className="distributormessage-details">
				<div className="distributormessage-details-header">
					<span className="_date">{ message.created_at }</span>
					<span className="_subject">{ message.title }</span>
					<span className="_close" onClick={ this.props.onClose }>close</span>
				</div>

				<div className="distributormessage-details-content">
					<pre className="_body">{ message.body }</pre>
				</div>
			</div>
		)
	}
}

export default DistributorMessageDetails
