import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { times } from 'lodash'
import PibSelectRadio from "../common/PibSelectRadio";
import csrfToken from "../../src/csrf_token";

const pauseSubModalStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '463px',
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
    borderRadius: 'unset',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 0'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '5'
  }
}

class PauseSubscriptionModal extends React.Component {
  state = {
    phase: 0,
    reason: "",
    reasonComment: "",
    pauseCycles: 1,
    disableInputs: false
  }

  pauseCyclesChanged = (e) => {
    this.setState({ pauseCycles: parseInt(e.target.value) })
  }

  pauseReasonChanged = (value) => {
    this.setState({ reason: value })
  }

  pauseReasonCommentChanged = (event) => {
    this.setState({ reasonComment: event.target.value })
  }

  previousPhase = () => {
    this.setState({ phase: this.state.phase - 1 })
  }

  nextPhase = () => {
    this.setState({ phase: this.state.phase + 1 })
  }

  canSelectPauseLength = () => {
    return this.props.period !== "annual"
  }

  async confirmPause(){
    const { subscriptionId } = this.props
    const { pauseCycles, reason, reasonComment, phase } = this.state

    this.setState({ disableInputs: true })

    const data = { cycles: pauseCycles, reason: reason, reason_comment: reasonComment }
    let response
    let responseData

    if (reason === "") {
      Mess.publish('show_error', "Please select one of the options below")
      this.setState({ disableInputs: false })
      return
    }

    try {
      response = await fetch(`/subscriptions/pause/${subscriptionId}`, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      })
      responseData = await response.json()
    } catch (e) {
      Mess.publish('show_error', "There was an unexpected error while pausing your subscription. Please try again")
      console.error(e)
    }

    if (responseData && responseData.success) {
      this.setState({ phase: phase + 1 })
    } else if (responseData) {
      Mess.publish('show_error', responseData && responseData.message || "Couldn't pause your subscription")
      console.error(responseData)
    }

    this.setState({ disableInputs: false })
  }

  onRequestClose = () => {
    const { closePauseSubModal, afterActionCallback } = this.props
    const { phase } = this.state

    closePauseSubModal()
    phase === 2 && afterActionCallback && afterActionCallback()
    this.setState({ phase: 0, pauseCycles: 1 })
  }

  render() {
    const { phase, pauseCycles, disableInputs, reason, reasonComment } = this.state
    const { productName, pauseDates, maxPauseCycles, isRto } = this.props

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.onRequestClose}
        style={pauseSubModalStyles}
        ariaHideApp={false}>

        <div className='UserRentToOwnProduct-cancelSubModal'>
          <div className='UserRentToOwnProduct-cancelSubModal-header'>
            <div className='UserRentToOwnProduct-cancelSubModal-header-title'>
              { phase === 2 ? 'Your plan is scheduled to pause' : 'Pause Plan'}
            </div>
            <div className='UserRentToOwnProduct-cancelSubModal-header-product'>
              <div className='UserRentToOwnProduct-cancelSubModal-header-product-text'>
                Product:
              </div>
              <div className='UserRentToOwnProduct-cancelSubModal-header-product-name'>
                {productName}
              </div>
            </div>
          </div>

          { phase === 0 && <PausePhaseOne closePauseSubModal={this.onRequestClose}
                                          pauseDates={pauseDates}
                                          maxPauseCycles={maxPauseCycles}
                                          resumeDate={pauseDates.resume[pauseCycles - 1]}
                                          pauseCyclesChanged={this.pauseCyclesChanged}
                                          canSelectPauseLength={this.canSelectPauseLength()}
                                          nextPhase={this.nextPhase}
                                          isRto={isRto} /> }

          { phase === 1 && <PausePhaseTwo pauseReasonChanged={this.pauseReasonChanged}
                                          pauseReasonCommentChanged={this.pauseReasonCommentChanged}
                                          confirmPause={this.confirmPause.bind(this)}
                                          disabled={disableInputs}
                                          reason={reason}
                                          reasonComment={reasonComment}
                                          previousPhase={this.previousPhase} /> }

          { phase === 2 && <PausePhaseThree pauseDates={pauseDates}
                                            resumeDate={pauseDates.resume[pauseCycles - 1]}
                                            closePauseSubModal={this.onRequestClose} /> }
        </div>
      </Modal>
    )
  }
}

export default PauseSubscriptionModal

const PausePhaseOne = ({
  closePauseSubModal, maxPauseCycles, pauseDates,
  pauseCyclesChanged, resumeDate, nextPhase, canSelectPauseLength, isRto
}) => (
  <div className='UserRentToOwnProduct-cancelSubModal-body'>
    <div className='UserRentToOwnProduct-cancelSubModal-body-title'>
      What happens when you pause your plan
    </div>

    <ul className='UserRentToOwnProduct-cancelSubModal-body-list'>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        { isRto ? 'During the pause you won’t be able to use the software' : 'You will lose access to Beatport Studio at the end of your billing cycle' }
      </li>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        You will be able to resume your plan at any time
      </li>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        Your payments will be resumed after the set period
      </li>
    </ul>

    <div className="UserRentToOwnProduct-cancelSubModal-pauseSelectWrapper">
      { canSelectPauseLength &&
        <p className="UserRentToOwnProduct-cancelSubModal-pauseHead">
          How many billing periods should we pause for?
        </p> }

      { canSelectPauseLength &&
        <select onChange={pauseCyclesChanged} className="UserRentToOwnProduct-cancelSubModal-dropdownSelect" name="pause_cycles" id="pause_cycles">
          <option selected disabled hidden>Choose the number of months to pause for</option>
          {times(maxPauseCycles, (num) => {
            return <option key={num} value={num + 1}>{num + 1}</option>
          })}
        </select> }

      <div className="flex UserRentToOwnProduct-cancelSubModal-pauseInfo">
        <div className="flex pauseDate">
          <p className="pauseDate-text">Pause Start</p>
          <p className="pauseDate-from">{pauseDates.start}</p>
        </div>
        <img src={`${require(`../../images/icon/arrow-vector.svg`)}`} alt=""/>
        <div className="flex pauseDate">
          <p className="pauseDate-text">Billing will resume</p>
          <p className="pauseDate-to">{resumeDate}</p>
        </div>
      </div>
    </div>

    <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
      <div className='UserRentToOwnProduct-cancelSubModal-body-buttons-keepBtn' onClick={closePauseSubModal}>
        Keep Plan
      </div>
      <button onClick={nextPhase} className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn'>
        Continue
      </button>
    </div>
  </div>
)

const PausePhaseTwo = ({ reason, reasonComment, pauseReasonChanged, pauseReasonCommentChanged, previousPhase, confirmPause, disabled = false }) => (
    <div className="UserRentToOwnProduct-cancelSubModal-body">
      <div className='UserRentToOwnProduct-cancelSubModal-body-title'>
        Would you mind telling us why you want to pause?
      </div>

      <PibSelectRadio noInitialValue={true} disabled={disabled} onChange={pauseReasonChanged} selectedValue={reason} options={[
        { label: "I'm not making music right now", value: "I'm not making music right now" },
        { label: "I am having technical problems", value: "I am having technical problems" },
        { label: "I can't afford it right now", value: "I can't afford it right now" },
        { label: "I am using another service", value: "I am using another service" }
      ]} />

      <textarea onChange={pauseReasonCommentChanged} name="pause_comment" id="pause_comment"
                className="UserRentToOwnProduct-cancelSubModal-commentTextArea"
                cols="30" rows="10" placeholder="Your comment" defaultValue={reasonComment}></textarea>

      <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
        <div className='UserRentToOwnProduct-cancelSubModal-body-buttons-keepBtn' onClick={previousPhase}>
          Back
        </div>
        <button disabled={disabled} onClick={confirmPause} className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn'>
          Confirm Pause
        </button>
      </div>
    </div>
)

const PausePhaseThree = ({ pauseDates, resumeDate, closePauseSubModal }) => (
  <div className="UserRentToOwnProduct-cancelSubModal-body">
    <p className="UserRentToOwnProduct-cancelSubModal-infoParagraph">Pause start: <b>{pauseDates.start}</b></p>
    <p className="UserRentToOwnProduct-cancelSubModal-infoParagraph">Billing will resume: <b>{resumeDate}</b></p>

    <ul className='UserRentToOwnProduct-cancelSubModal-body-list'>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        During the pause you won’t be able to use the software
      </li>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        You can re-activate your plan on your My Account page
      </li>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        Your payment will be resumed after the set period
      </li>
    </ul>

    <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
      <button onClick={closePauseSubModal} className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn wide'>
        Dismiss
      </button>
    </div>
  </div>
)
