import React from 'react'
import SearchResultProduct from './SearchResultProduct'
import SearchResultArticle from './SearchResultArticle';

class SearchResults extends React.Component {
  render() {
      return (
          <ul className="searchresults">
              { _.map(this.props.results, function(item, idx) {
                  return (
                      <li key={idx}>
                          <SearchResultProduct key={ idx + 100 } item={item} />
                          <SearchResultArticle key={ idx + 200 } item={item} />
                     </li>
                 )
              }) }
          </ul>
      )
  }
}
export default SearchResults
