import React from 'react'
import GalleryFooterIcon from './GalleryFooterIcon'

class GalleryFooter extends React.Component {
  state = { icon_width: 70 };

  componentDidMount() {
      this.update_icon_width()
  }

  componentDidUpdate() {
      this.update_icon_width()
  }

  update_icon_width = () => {
      var icon = document.getElementsByClassName('gallery-footer-icon')[0],
          rect = icon && icon.getBoundingClientRect(),
          width = rect && rect.width

      if (this.state.icon_width != width) {
          this.setState({ icon_width: width })
      }

  };

  handle_prev = () => {
      var active = this.props.active

      if (active > 0) {
          this.props.onChange(active - 1)
      }
  };

  handle_next = () => {
      var active = this.props.active
      var size = this.props.items.length

      if (active < size - 1) {
          this.props.onChange(active + 1)
      }
  };

  render() {
      var active = this.props.active,
          icon_width = this.state.icon_width

          // chunkSize = this.props.footerSize || 4,
          // chunkIdx = (active - active % chunkSize) / chunkSize

      var chunk_size = this.props.footerSize || 4,
          all_size = this.props.items.length,
          chunk_size = chunk_size > all_size ? all_size : chunk_size,
          chunk_idx = (active - active % chunk_size) / chunk_size,
          max_offset = all_size * icon_width - chunk_size * icon_width,
          offset = chunk_idx * icon_width * chunk_size

      offset = offset > max_offset ? max_offset : offset

      var has_next = (active < all_size - 1) ? '' : ' --off'
      var has_prev = active > 0 ? '' : ' --off'

      return (
          <div className="gallery-footer">
              <span className={ 'gallery-footer-prev' + has_prev} onClick={ this.handle_prev } data-layer={dtl('product page/gallery:prev') }>prev</span>
              <div className="gallery-footer-window" style={ { width: chunk_size * icon_width + 'px'} }>

                  <ul className="gallery-footer-content" style={ { marginLeft: -offset + 'px', width: all_size * icon_width } }>
                      {
                          _.map(this.props.items, function(el, idx) {
                              return <GalleryFooterIcon idx={ idx } kind={ el.kind } active={ active } key={ idx } onClick={ this.props.onChange }/>
                          }.bind(this))
                      }
                  </ul>
              </div>
              <span className={'gallery-footer-next' + has_next} onClick={ this.handle_next } data-layer={ dtl('product page/gallery:next') }>next</span>
          </div>
      )
  }
}

export default GalleryFooter
