import React from 'react'

class BuyBoxBundleSize extends React.Component {
    render() {
        return (
            <div className="buybox-bundlesize">
                Bundle Package
                <p><strong>{ this.props.size }</strong> programs inside</p>
            </div>
        )
    }
}
export default BuyBoxBundleSize
