import React from 'react'

class GalleryFooterIcon extends React.Component {
  handleClick = () => {
      this.props.onClick(this.props.idx)
  };

  render() {
      var selectedIconClass = this.props.idx == this.props.active ? ' --active' : ''
      return (
          <li className={ 'gallery-footer-icon --' + this.props.kind + selectedIconClass} onClick={ this.handleClick } data-layer={dtl('product page/gallery:icon')}>
              { this.props.kind }
          </li>
      )
  }
}

export default GalleryFooterIcon
