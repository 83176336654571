import React from 'react'
import ProcessLine from './ProcessLine'
import DistributorOrderDetails from './DistributorOrderDetails'
import csrfToken from '../src/csrf_token'

class DistributorOrder extends React.Component {
    constructor(props) {
        super(props)
        this.state = { expanded: false, details: null, processing: false }
    }

    handle_expand() {
        if (!this.state.details) {
            let order_id = this.props.order.order_id

            this.setState({ processing: true })

            $.ajax({
                url: "/distributor/order_details/" + order_id,
                dataType: 'json',
                method: 'post',
                headers: {
        					"X-CSRF-Token": csrfToken()
        				},
                success: (data) => this.setState({ processing: false, details: data, expanded: true }),
                error: (xhr) => this.setState({ processing: false })
            })

        } else {
            this.setState({ expanded: true })
        }
    }

    handle_collapse() {
        this.setState({ expanded: false })
    }

    render() {

      var order = this.props.order,
        handle_expand = this.handle_expand.bind(this),
        handle_collapse = this.handle_collapse.bind(this),
        details = this.state.details,
        expanded = this.state.expanded && (details != null)
        // header_clazz = "distributororder-header" + (order.void ? ' red strike' : '')
        // strike = order.void ? "red strike" : null

      const strike = (clazz) => clazz + (order.void ? ' red strike' : '')

      return (
        <li className="distributororder">
          <ProcessLine processing={ this.state.processing }/>

          <div className="distributororder-header" onClick={ handle_expand }>
            <p className={strike("_no")}>{ order.order_id }</p>
            <p className={strike("_date")}>{ order.sold_at }</p>
            <p className={strike("_email")}>{ order.email }</p>
            <p className={strike("_amount")}>{ order.total }</p>
            <p className={strike("_amount_in_currency")}>{ "(" + order.total_in_distributor_currency + ")" }</p>
          </div>

          { expanded ? <DistributorOrderDetails order={ details } onClose={ handle_collapse }/> : null }
        </li>
      )
    }
}

export default DistributorOrder
