import React from 'react'

class CompleteItemDownloadOses extends React.Component {
    handleChange = (pc) => {
        this.props.onChange(pc)
    };

    render() {
        var prefix = 'item-' + this.props.order_item.order_item_id + '-'
        var bothOsesAvailable = this.props.order_item.os.both
        var pcSelected = bothOsesAvailable && this.props.pc

        return ( bothOsesAvailable ?
            <form className="completeitem-download-oses">
                <input ref="pc" type="radio" name="pc" value="true" onChange={ this.handleChange.bind(this, true) } id={ prefix + 'pc' } checked={ pcSelected }/>
                <label className="_pc" htmlFor={prefix + 'pc'}>pc</label>

                <input type="radio" name="pc" value="false" onChange={ this.handleChange.bind(this, false) } id={prefix + 'mac'} checked={ !pcSelected }/>
                <label className="_mac" htmlFor={prefix + 'mac'}>mac</label>
            </form> : null
        )
    }
}



export default CompleteItemDownloadOses
