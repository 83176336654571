import React from 'react'
import PropTypes from 'prop-types'

class CartTokenVc extends React.Component {
  render() {
    const vcBalance = this.props.cart.virtualCashBalance

    return (
      <div className='cart-token-vc'>
        { vcBalance && (
          <div className="vc">
            <div className="vc-text label">Your virtual cash balance</div>
            <div className="vc-value value">{vcBalance}</div>
          </div>
        )}

        <div className="vc">
          <div className="vc-text label">Virtual cash you will earn</div>
          <div className="vc-value value">{this.props.cart.virtualCash}</div>
          <a className="what-is" href='https://help.pluginboutique.com/hc/en-us/articles/6232614952340-What-is-Virtual-Cash-and-how-can-I-use-it-' target="_blank" rel="noopener noreferrer">What is Virtual Cash?</a>
        </div>

        <div className="tokens">
          <div className="tokens-text label">Tokens you will earn</div>
          <div className="tokens-value value">{this.props.cart.tokensWillEarn}</div>
          <a className="what-is" href='https://help.pluginboutique.com/hc/en-us/articles/6232541775124-How-does-the-Rewards-Scheme-work-' target="_blank" rel="noopener noreferrer">What are Tokens?</a>
        </div>
      </div>
    )
  }
}

CartTokenVc.propTypes = {
  cart: PropTypes.object.isRequired
};

export default CartTokenVc
