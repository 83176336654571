import React from 'react'
import UserCouponsItem from './UserCouponsItem'
import csrfToken from '../src/csrf_token'

class UserCoupons extends React.Component {
    state = { processing: false, coupons: this.props.coupons };

    componentDidMount() {
        Mess.subscribe('refresh_coupons', this.handle_refesh)
    }

    handle_refesh = () => {

        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: '/rewards/coupons/',
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: (data) => this.setState({ coupons: data, processing: false }),
            error: () => this.setState({ processing: false })
        })
    };

    render() {
        let clazz = 'usercoupons' + ( this.state.processing ? ' processing cloudy-border' : '' );

        return (
            <ul className={ clazz }>
                { _.map(this.state.coupons, function(item) {
                    return <UserCouponsItem key={ item.coupon_id } coupon={ item } on_get_coupon={ this.handle_get_coupon }/>

                }.bind(this))}
            </ul>
        )
    }
}

export default UserCoupons
