import React from 'react'

class UserOrderItem extends React.Component {
  render() {
      var product = this.props.order_item.product || this.props.order_item.course

      return (
          <tr className="userorder-item">
              <th><a href={ product.link_to } title={ product.name }>{ product.name }</a></th>
              <td>{ this.props.order_item.sell_price }</td>
          </tr>
      )
  }
}

export default UserOrderItem
