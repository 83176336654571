import React from "react"
import PropTypes from "prop-types"
import BuyBoxPrice from "./BuyBoxPrice"
import BuyBoxTrial from "./BuyBoxTrial"
import BuyBoxDiscountRow from "./BuyBoxDiscountRow"
import BuyBoxVirtualBalanceWithRewards from "./BuyBoxVirtualBalanceWithRewards"
import BuyBoxGiftsList from "./BuyBoxGiftsList"
import CurrencyChanger from "./CurrencyChanger"
import WishlistAdd from "./WishlistAdd"
import BuyBoxAdd from "./BuyBoxAdd"
import csrfToken from "../src/csrf_token"

class BuyBox extends React.Component {
  static propTypes = {
    can_add_to_cart: PropTypes.bool.isRequired,
    rejection_description: PropTypes.string,
    rejection_link: PropTypes.string,
    sell_price: PropTypes.string.isRequired,
    with_vat: PropTypes.bool.isRequired,
    on_sale: PropTypes.bool.isRequired,
    item_id: PropTypes.number.isRequired,
    in_cart: PropTypes.bool.isRequired,
    in_wishlist: PropTypes.bool.isRequired,
    trial: PropTypes.object,
    regular_price: PropTypes.string.isRequired,
    discount: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    product_item: PropTypes.object.isRequired,
    rto: PropTypes.object,
    url: PropTypes.string,
    virtual_cash_balance: PropTypes.string,
    gifts: PropTypes.array
  }

  state = {
    sell_price: this.props.sell_price,
    regular_price: this.props.regular_price,
    discount: this.props.discount,
    currency_chooser_visible: false,
    currency: this.props.currency,
    product_item: this.props.product_item,
    rto: this.props.rto
  }

  handleCurrencyChooser = () => {
    this.setState({ currency_chooser_visible: !this.state.currency_chooser_visible})
  }

  handleCurrencyChanged = (currency) => {
    const pbref = this.props.pbref || "xcmp";
    $.ajax({
      url: '/product_items/' + this.props.item_id + '/price_data.js?currency=' + currency + '&pbref=' + pbref,
      dataType: 'json',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: function(data) {
          this.setState(data)
      }.bind(this),
      error: function(xhr, status, err) {
          console.error(this.props.url, status, err.toString());
      }.bind(this)
    })

    this.setState({ currency_chooser_visible: false, currency: currency })
  }

  renderRtoButton() {
    const { planId, billingCycles, reducedPrice, onSale } = this.state.rto

    if (!planId) return;

    return(
      <a
        data-layer={this.state.product_item.data_layer}
        className="buybox-actions-rentToOwnBtn"
        href={'/subscriptions/checkout/initial?plan_id=' + planId }
      >
        <div className="buybox-actions-rentToOwnBtn-topText">
          Rent To Own
          {
            onSale && <span className="buybox-actions-rentToOwnBtn-topText-sale"> SALE</span>
          }
        </div>
        <div className="buybox-actions-rentToOwnBtn-bottomText">
          (<span>{reducedPrice}</span>/month for <span>{billingCycles}</span> months)
        </div>
      </a>
    )
  }

  render() {
    const buybox_class = `buybox ${this.props.on_sale && 'buybox-sale'}`
    const product_item = this.state.product_item

    return (
      <div className={ buybox_class }>
        {
          this.props.on_sale && <span className='saleBackgroundText'>sale</span>
        }

        <BuyBoxPrice with_vat={ this.props.with_vat } value={ this.state.sell_price } />

        {
          product_item.discount && (
            <BuyBoxDiscountRow
              regularPrice={this.props.regular_price}
              discount={this.props.discount}
            />
          )
        }

        <BuyBoxVirtualBalanceWithRewards
          virtualCashBalance={ this.props.virtual_cash_balance }
          virtualCash={ this.props.product_item.virtual_cash }
          rewardTokens={ this.props.product_item.reward_tokens }
        />

        <CurrencyChanger
          currency={ this.state.currency }
          callback={ this.handleCurrencyChanged }
          is_product={ true }
        />

        <div className="buybox-actions">
          <BuyBoxAdd
            item_id={ this.props.item_id }
            in_cart={ this.props.in_cart }
            data_layer={ product_item.data_layer }
            can_add_to_cart={ this.props.can_add_to_cart }
            rejection_description={ this.props.rejection_description }
            rejection_link={ this.props.rejection_link }
            with_new_styles={ true }
          />

          { this.renderRtoButton() }

          <BuyBoxGiftsList gifts={ this.props.gifts } />

          <div className='buybox-links'>
            {
              (typeof(this.props.can_add_to_cart) != 'undefined' && this.props.can_add_to_cart) && (
                <WishlistAdd
                  item_id={ this.props.item_id }
                  in_wishlist={ this.props.in_wishlist }
                  with_new_styles={ true }
                />
              )
            }

            <div className='buybox-links-trial'>
              <BuyBoxTrial trial={ this.props.trial } is_product={ true } />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BuyBox
