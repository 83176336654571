import React from 'react'
import { instrumentsData, effectsData, utilitiesData, dawData, soundsData } from './whatsIncludedData'

class DynamicImage extends React.Component {
  render() {
    const { name, alt } = this.props
    return (
      <picture>
        <source media="(min-width: 768px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/favourite_plugins/${name}_m@x2.png`)} />
        <source media="(min-width: 768px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/favourite_plugins/${name}_m@x1.png`)} />
        <source media="(min-width: 0px) and (min-resolution: 2dppx)" srcSet={require(`../../images/beatport_studio/favourite_plugins/${name}_sm@x2.png`)} />
        <source media="(min-width: 0px) and (max-resolution: 1dppx)" srcSet={require(`../../images/beatport_studio/favourite_plugins/${name}_sm@x1.png`)} />
        <img
          src={require(`../../images/beatport_studio/favourite_plugins/${name}_m@x2.png`)}
          alt={alt}
          className='BPSFavouritePlugins-content-inner-media-image'
        />
      </picture>
    )
  }
}

class BPSFavouritePlugins extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentCategory: 'Instruments'
    }

    this.category = this.category.bind(this)
  }

  category(data) {
    const currentCategory = this.state.currentCategory
    const activeCategoryClass = 'BPSFavouritePlugins-content-inner-top-navbar-option-active'

    return (
      <div className='BPSFavouritePlugins-content-inner' ref={this.props.reference}>
        <div id='favourites'></div>
        <div className='BPSFavouritePlugins-content-inner-top'>
          <div className='BPSFavouritePlugins-content-inner-top-title'>
            Discover your next favourite plugin
          </div>

          <div className='BPSFavouritePlugins-content-inner-top-navbar'>
            <div className={`BPSFavouritePlugins-content-inner-top-navbar-option ${currentCategory === 'Instruments' ? activeCategoryClass : ''}`}
                  onClick={() => this.setState({ currentCategory: 'Instruments' })}>
              Instruments
            </div>
            <div className={`BPSFavouritePlugins-content-inner-top-navbar-option ${currentCategory === 'Effects' ? activeCategoryClass : ''}`}
                  onClick={() => this.setState({ currentCategory: 'Effects' })}>
              Effects
            </div>
            <div className={`BPSFavouritePlugins-content-inner-top-navbar-option ${currentCategory === 'Utilities' ? activeCategoryClass : ''}`}
                  onClick={() => this.setState({ currentCategory: 'Utilities' })}>
              Utilities
            </div>
            <div className={`BPSFavouritePlugins-content-inner-top-navbar-option ${currentCategory === 'DAW' ? activeCategoryClass : ''}`}
                  onClick={() => this.setState({ currentCategory: 'DAW' })}>
              DAW
            </div>
            <div className={`BPSFavouritePlugins-content-inner-top-navbar-option ${currentCategory === 'Sounds' ? activeCategoryClass : ''}`}
                  onClick={() => this.setState({ currentCategory: 'Sounds' })}>
              Sounds
            </div>
          </div>
        </div>

        <div className='BPSFavouritePlugins-content-inner-bottom'>
          <div className='BPSFavouritePlugins-content-inner-top'>
            <div className='BPSFavouritePlugins-content-inner-top-items'>
              { data.map((item, index) => {
                return this.categoryItem(item, index)
              }) }
            </div>
          </div>
          <div className='BPSFavouritePlugins-content-inner-media'>
            { this.currentCategoryVideo() }
            { this.currentCategoryImage() }
          </div>
        </div>
      </div>
    )
  }

  categoryItem(item, index) {
    return (
      <div key={index} className='BPSFavouritePlugins-content-inner-top-items-item'>
        <div className='BPSFavouritePlugins-content-inner-top-items-item-title'>
          <div className='BPSFavouritePlugins-content-inner-top-items-item-title-name'>{item.name}</div>
          {item.manufacturer && (
            <div className='BPSFavouritePlugins-content-inner-top-items-item-title-manufacturer'>by {item.manufacturer}</div>
          )}
        </div>
        <div className='BPSFavouritePlugins-content-inner-top-items-item-description'>
          {item.description}
        </div>
      </div>
    )
  }

  currentCategoryContent(currentCategory) {
    switch (currentCategory) {
      case 'Instruments':
        return this.category(instrumentsData)
      case 'Effects':
        return this.category(effectsData)
      case 'Utilities':
        return this.category(utilitiesData)
      case 'DAW':
        return this.category(dawData)
      case 'Sounds':
        return this.category(soundsData)
      default:
        null
    }
  }

  currentCategoryImage() {
    return <DynamicImage key={Math.random()} name={this.state.currentCategory.toLowerCase()} alt={this.state.currentCategory} />
  }

  currentCategoryVideo() {
    const name = this.state.currentCategory.toLowerCase()
    return <video
      autoPlay
      loop
      muted
      poster={require(`../../images/beatport_studio/favourite_plugins/${name}_m@x2.png`)}
      className='BPSFavouritePlugins-content-inner-media-video'
      key={Math.random()}
    >
      <source src={require(`../../images/beatport_studio/favourite_plugins/${name}.webm`)} type="video/webm" />
    </video>
  }

  render() {
    const currentCategory = this.state.currentCategory

    return (
      <div className='BPSFavouritePlugins'>
        <div className='BPSFavouritePlugins-content'>
          { this.currentCategoryContent(currentCategory) }
        </div>
      </div>
    )
  }
}

export default BPSFavouritePlugins