import React from 'react'
import Ribbons from './Ribbons';

class SearchResultProduct extends React.Component {
  render() {
      var item = this.props.item
      return item.kind == 'product' ? (
          <div className="searchresult _product">
              <Ribbons ribbons={ item.ribbons } />
              <div className="searchresult-oses">
                  { item.mac ? (
                      <span className="_mac">mac</span>
                  ) : null }
                  { item.pc ? (
                      <span className="_pc">pc</span>
                  ) : null }
              </div>
              <p>
                  <a href={ item.productLink } title={ item.product } data-layer={ dtl('search/suggestions') } className="searchresult-productlink">{ item.product }</a>
              </p>
              <p className="searchresult-manufacturerinfo">
                  by <a href={ item.manufacturerLink } title={ item.manufacturer } className="searchresult-manufacturerlink">{ item.manufacturer }</a>
              </p>
          </div>
      ) : null
  }
}
export default SearchResultProduct
