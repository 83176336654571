import React from 'react'
import PropTypes from 'prop-types'

class CountriesSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isListOpen: false,
      location: this.props.location
    }

    this.toggleList = this.toggleList.bind(this)
    this.selectItem = this.selectItem.bind(this)
  }

  toggleList() {
    this.setState({
      isListOpen: !this.state.isListOpen
    })
  }

  selectItem(item) {
    const { name } = item

    this.setState({
      location: name,
      isListOpen: false,
    })

    this.props.toggleLocation(name)
  }

  render() {
    const { isListOpen, location } = this.state
    const { countries } = this.props
    const locationAbbr = countries.find(country => country.name === location).abbr

    return (
      <div className="confirm-countries">
        <div
          className="confirm-countries-location"
          onClick={this.toggleList}
        >
          <div className="confirm-countries-location-container">
            <div className="confirm-countries-location-flag">
              <img src={require(`../../images/flags/${locationAbbr.toLowerCase()}.svg`)} alt={`${location} flag`}/>
            </div>
            <div className="confirm-countries-location-name">
              <p>{location}</p>
            </div>
          </div>
          <div
            className={`confirm-countries-location-button ${isListOpen ? 'arrow-select-up' : 'arrow-select-down'}`}
          >
          </div>
        </div>
        {isListOpen ? (
          <div role="list" className="confirm-countries-items">
            {countries.map((country) => (
              <div className="confirm-countries-item">
                <div className="confirm-countries-item-flag">
                  <img src={require(`../../images/flags/${country.abbr.toLowerCase()}.svg`)} alt={`${country.name} flag`}/>
                </div>
                <button
                  type="button"
                  className="confirm-countries-item-button"
                  key={country.id}
                  onClick={() => this.selectItem(country)}
                >
                  {country.name}
                </button>
              </div>
            ))}
          </div>
        ) : null}
        {isListOpen ? (
          <div className="confirm-countries-items-bottom"></div>
        ) : null}
      </div>
    )
  }
}

CountriesSelect.propTypes = {
  location: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  toggleLocation: PropTypes.func.isRequired
}
export default CountriesSelect
