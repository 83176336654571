import React from 'react'
import PropTypes from 'prop-types'
import csrfToken from '../src/csrf_token'
import { addToWishlist } from '../src/actions/addToWishlist'
import { setFallbackImage } from '../src/actions/setFallbackImage'

class HeaderCartProductItem extends React.Component {
  handleAddToWishlist(product) {
    addToWishlist(product)
  }

  renderWishlistSection(product) {
    if (product.in_wishlist) {
      return(
        <span className="ProductItem-wishlistText">In wishlist</span>
      )
    } else {
      return (
        <div className="ProductItem-wishlistText ProductItem-wishlistText--pointer" onClick={ () => this.handleAddToWishlist(product) }>
          Add to wishlist
        </div>
      )
    }
  }

  renderPriceSection(item, isGift) {
    if (isGift === false) {
      return (
        <span className="ProductItem-wishlistValue">{item.sell_price}</span>
      )
    } else {
      return (
        <span>
          <span className="ProductItem-wishlistValue ProductItem-wishlistValue--free">FREE</span>
          <span className="ProductItem-wishlistValue ProductItem-wishlistValue--crossed">{item.value_with_currency}</span>
        </span>
      )
    }
  }

  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  render() {
    const item = this.props.item
    const product = item.custom_bundle_product || item.product || item.course

    return (
      <div className="ProductItem">
        <a href={product.link_to} className="ProductItem-imgContainer">
          <img className="header-cart-item-img" src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
        </a>
        <div className="ProductItem-content">
          <a
            className="ProductItem-content--productNameLink"
            href={product.link_to}
            title={product.name}
          >
            {product.name}
          </a>
          <a className="ProductItem-content--manufacturerLink" href={product.manufacturer.link_to}>
            {product.manufacturer.name}
          </a>
          <a className="ProductItem-content--categoryLink" href={product.category.link_to}>
            {product.category.name}
          </a>
          <div className="ProductItem-wishlistRow">
            { (this.props.isGift === false) && this.renderWishlistSection(product) }

            { this.renderPriceSection(item, this.props.isGift) }
          </div>
        </div>
      </div>
    )
  }
}

HeaderCartProductItem.propTypes = {
  item: PropTypes.object.isRequired,
  fallbackImageSrc: PropTypes.string.isRequired,
  isGift: PropTypes.bool.isRequired
}

export default HeaderCartProductItem
