import React from 'react'

class LogIn extends React.Component {
    state = { visible: this.props.visible || false };

    componentDidMount() {
        Mess.subscribe('log_in', this.showLoginForm)
    }

    showLoginForm = (callback) => {
        this.callback = callback
        this.setState({ visible: true })
    };

    render() {
        return (this.state.visible ?
            <form acceptCharset="UTF-8" action={ this.props.url } className="loginbox" method="post">
                <div htmlStyle="display:none"><input name="utf8" type="hidden" value="✓"/><input name="authenticity_token" type="hidden" value={ this.props.token }/></div>

                <p>
                    <label form="user_email">Email</label>
                    <input autoFocus="autofocus" id="user_email" name="user[email]" placeholder="email" type="email"/>
                </p>

                <p>
                    <label form="user_password">Password</label>
                    <input id="user_password" name="user[password]" placeholder="password" type="password"/>
                </p>

                <p>
                    Dont have account? <a href="/users/sign_up" class="register" title="Click here to create account">Click here.</a> <a href="/forgot_password" title="Click here if you forgot your password">Forgot password?</a>
                </p>

                <p className="action">
                    <input alt="Log in" name="commit" type="submit" value="Log in"/>
                </p>
            </form> : null
        )
    }
}


export default LogIn
