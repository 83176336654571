import React from 'react'
import PropTypes from 'prop-types'
import { addToWishlist } from '../../src/actions/addToWishlist'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class CartProductItem extends React.Component {
  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  wishlistSection(product) {
    if (product.in_wishlist) {
      return(
        <div className="CartProductItem-wishlistBtn CartProductItem-inWishlist">In wishlist</div>
      )
    } else {
      return (
        <div className="CartProductItem-wishlistBtn" onClick={ () => addToWishlist(product) }>
          Add to wishlist
        </div>
      )
    }
  }

  render() {
    const item = this.props.item
    const product = item.custom_bundle_product || item.product || item.course

    return (
      <div className="CartProductItem-listItem">
        <div className="CartProductItem-columnProduct">
          <a href={product.link_to} className="CartProductItem-imgContainer">
            <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
          </a>
        </div>

        <div className="CartProductItem-productData">
          <a
            className="CartProductItem-productData--name"
            href={product.link_to}
            title={product.name}
          >
            {product.name}
          </a>
          <a href={product.category.link_to} className="CartProductItem-productData--category">
            {product.category.name}
          </a>
          <span className="CartProductItem-productData--by"> by </span>
          <a href={product.manufacturer.link_to} className="CartProductItem-productData--manufacturer">
            {product.manufacturer.name}
          </a>
        </div>

        <div className="CartProductItem-columnPrice">
          <span className="CartProductItem-priceMobileText">Price:</span>{item.sell_price}
        </div>

        <div className="CartProductItem-columnClear">
          <a className="CartProductItem-removeBtn"
             href={`/orders/${item.order_item_id}/remove_items`}
             data-method="delete">
             Remove
          </a>
          <div className="CartProductItem-wishlist">
            {this.wishlistSection(product)}
          </div>
        </div>
      </div>
    )
  }
}

CartProductItem.propTypes = {
  item: PropTypes.object.isRequired,
  fallbackImageSrc: PropTypes.string.isRequired
}

export default CartProductItem
