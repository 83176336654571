import React from 'react'

class PopupInfoBox extends React.Component {
    handle_esc = (event) => {
        if (event.keyCode == 27) {
            this.handle_close(event)
        }
    };

    handle_close = (event) => {
        event.preventDefault()
        event.stopPropagation()
        this.props.onClose()
    };

    render() {
        return (
            <div className="popupinfo-box" onKeyUp={ this.handle_esc } >
                <a href="#close" className="icon-remove" title="Hide information about Virtual Cash" onClick={ this.handle_close }>close</a>

                <div className="popupinfo-content" dangerouslySetInnerHTML={{__html: this.props.content }}/>
            </div>
        )
    }
}

export default PopupInfoBox
