import React from 'react'
import UserProductShort from './UserProductShort'
import UserProductImage from './UserProductImage'
import UserProductMeta from './UserProductMeta'
import UserProductSerials from './UserProductSerials'
import UserRate from './UserRate'
import UserProductGuide from './UserProductGuide'
import UserProductDownload from './UserProductDownload'
import UserRateDetails from './UserRateDetails'

class UserProduct extends React.Component {
    state = {
        expanded: this.props.expanded || false
    };

    handleExpander = () => {
        this.setState({ expanded: true })
    };

    render() {
        const { expanded } = this.state
        const { order_item } = this.props

        if (!expanded) {
            return <UserProductShort order_item={ order_item } onExpand={ this.handleExpander }/>
        }

        return (
            <div key={order_item.order_item_id} className="userproduct">
                <a name={ 'item-' + order_item.product.product_id } title={ order_item.product.name }></a>
                { expanded ? <UserProductImage product={ order_item.product } fallbackImageSrc={ this.props.fallbackImageSrc } /> : null }
                <div className="userproduct-data">
                    <UserProductMeta product={ order_item.product } />
                    <UserProductSerials order_item={ order_item } />
                </div>
                <div className="userproduct-footer">
                    <UserRate order_item={ order_item } />
                    <UserProductGuide guides={ order_item.product.is_daw ? [] : order_item.product.guides } />
                    <UserProductDownload order_item={ order_item } expanded={ true }/>
                </div>
                <UserRateDetails order_item_id={ order_item.order_item_id } />
            </div>
        )
    }
}

export default UserProduct
