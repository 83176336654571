import React from 'react'
import csrfToken from '../src/csrf_token'

class WishlistAdd extends React.Component {
    state = {
        progress: false,
        in_wishlist: this.props.in_wishlist || false
    };

    handle_add_to_wishlist(event) {
        event.preventDefault()

        this.setState({ progress: true })
        $.ajax({
            url: '/wishlists/add_to_wishlist',
            type: 'POST',
            dataType: 'json',
            data: {
              id: this.props.item_id
            },
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                Mess.publish('show_flash', data)
                Mess.publish('refresh_wishlist', data)
                this.setState({ progress: false, in_wishlist: !!data.notice })
                fbq('track', 'AddToWishlist', {
                    content_ids: [this.props.item_id]
                });
            }.bind(this),
            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });
    };

    render() {
        let clazz = `${this.state.in_wishlist ? ' --in' : ''} ${this.state.progress ? ' --progress' : ''} ${this.props.with_new_styles ? 'buybox-links-wishlist' : 'button-wishlist'}`

        return (
            this.state.in_wishlist ? <a href="/wishlist" className={ clazz } title="Check your wishlist">In Wishlist</a> :
            <a rel="nofollow" href="#add_to_wishlist" className={ clazz } title={ `Add product to wishlist` } onClick={ this.handle_add_to_wishlist.bind(this) }>Add to Wishlist</a>
        )
    }
}

export default WishlistAdd
