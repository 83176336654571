import React from 'react'
import RecommendedProductsListModal from './RecommendedProductsListModal'

class CartRecommendedItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isRecommendedProductsListOpen: false,
      isMoreInfoModalOpen: false
    }

    this.openRecommendedProductsList = this.openRecommendedProductsList.bind(this)
    this.closeRecommendedProductsList = this.closeRecommendedProductsList.bind(this)
  }

  openRecommendedProductsList(event) {
    event.preventDefault()

    this.setState({
      isRecommendedProductsListOpen: true
    })
  }

  closeRecommendedProductsList(event) {
    event.preventDefault()

    this.setState({
      isRecommendedProductsListOpen: false
    })
  }

  render() {
    return (
      <div className="CartProductItem-listItem CartProductItem-emptyItem">
        <div className="CartProductItem-productData CartProductItem-productRecommendedData">
          <div className="CartProductItem-productData--name CartRecommendedItem-title">
            Recommended products based on your basket
          </div>
          <div className="CartProductItem-productData--category">
            Add more to your order
          </div>
        </div>
        <div className="CartProductItem-columnClear CartProductItem-columnClearEmpty">
          <div onClick={e => this.openRecommendedProductsList(e)} className="CartProductItem-addGiftBtn">Browse products</div>
        </div>

        <RecommendedProductsListModal
          isModalOpen={this.state.isRecommendedProductsListOpen}
          onCloseClick={this.closeRecommendedProductsList}
          fallbackImageSrc={this.props.fallbackImageSrc}
          alreadyInCartProducts={this.props.alreadyInCartProducts}
          recommendedProducts={this.props.recommendedProducts}
          fallbackProducts={this.props.fallbackProducts}
          recommendationsService={this.props.recommendationsService}
          frMeta={this.props.frMeta}
        />
      </div>
    )
  }
}

export default CartRecommendedItem
