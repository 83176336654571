import React from 'react'

class NavigationMenuItem extends React.Component {
  handleClick(event) {
      if (!this.props.opened) {
          event.preventDefault()
          event.stopPropagation()
          this.props.onClick(this.props.item.id)
      }
  }

  render() {
      var clazz = 'navigation-menu-item' + (this.props.opened ? ' --selected' : '')
      return (
          <li className={ clazz } onClick={ this.handleClick.bind(this) }><a href={ this.props.item.path } title={ this.props.item.title } data-layer={ this.props.item.data_layer }>{ this.props.item.title }<em/></a></li>
      )
  }
}
export default NavigationMenuItem
