import csrfToken from '../../src/csrf_token'

export const getSummaryAndRecommendations = async (query, awsLambdaGcpChatUrl) => {
  try {
    const params = new URLSearchParams({ query: query }).toString()

    const response = await fetch(`${awsLambdaGcpChatUrl}?${params}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json'
      }
    })

    if (!response.ok) {
      throw new Error(response)
    }

    const parsedResponse = await response.json()
    const summary = parsedResponse['answer_result']['generated_answer']
    const isSummaryValid = summary && summary.trim() !== "NOT APPLICABLE"
    const validatedSummary = isSummaryValid ? summary : null

    return { summary: validatedSummary, productIds: parsedResponse['search_result']['product_ids'] }
  } catch (error) {
    console.error(`GCP fetch summary and product_ids request error: ${error}`)

    return { summary: null, productIds: null}
  }
}

export const fetchProductsData = async (productIds) => {
  try {
    const params = new URLSearchParams({ product_ids: productIds }).toString()

    const response = await fetch(`/api/gcp/fetch_products?${params}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    })

    if (!response.ok) {
      throw new Error(response)
    }

    const responseJson = await response.json()

    return responseJson.products
  } catch (error) {
    console.error(`Gcp fetch products data request error: ${error}`)

    return { products: null }
  }
}

export const getChatVisibilityValue = () => {
  const itemStr = localStorage.getItem('isChatVisibleItem')

  if (!itemStr) return null

  const item = JSON.parse(itemStr)
  const now = new Date()

  if (now.getTime() > item.expiry) {
    localStorage.removeItem('isChatVisibleItem')

    return null
  }

  return item.value
}

export const updateChatVisibilityItem = (value, ttl) => {
  const now = new Date()

  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem('isChatVisibleItem', JSON.stringify(item))
}
