import React from 'react'
import PropTypes from 'prop-types'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class SidebarCartSubItem extends React.Component {
  onErrorImageMissing = (event) => {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  render() {
    const item = this.props.item
    const product = item.custom_bundle_product || item.product || item.course

    return(
      <div className="SidebarCartItem SidebarCartSubItem">
        <div className="SidebarCartItem-firstRow">
          <div className="SidebarCartItem-firstRow-imgContainer">
            <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={this.onErrorImageMissing} />
          </div>
          <div className="SidebarCartItem-firstRow-content">
            <div className="SidebarCartItem-firstRow-content-main">
              <div className="SidebarCartItem-firstRow-content-main--name" title={product.name}>
                {product.name}
              </div>
            </div>
          </div>
        </div>
        <div className="SidebarCartItem-secondRow">
          <div className="SidebarCartItem-secondRow-price">
            <span>
              {item.sell_price_without_discount}
            </span>
          </div>
        </div>
      </div>
    )
  }
}

SidebarCartSubItem.propTypes = {
  fallbackImageSrc: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  vatSwitcherValue: PropTypes.bool.isRequired
}

export default SidebarCartSubItem
