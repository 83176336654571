import React from 'react'
import PropTypes from 'prop-types'

const BuyBoxDiscountRow = ({ regularPrice, discount }) => (
  <div className='buybox-discount'>
    <div className='buybox-discount-listPrice'>
      <div className='buybox-discount-listPrice-text'>
        List Price
      </div>
      <div className='buybox-discount-listPrice-value'>
        { regularPrice }
      </div>
    </div>
    <div className='buybox-discount-youSave'>
      <div className='buybox-discount-youSave-text'>
        You Save
      </div>

      <div className='buybox-discount-youSave-value'>
        { discount }
      </div>
    </div>
  </div>
)

BuyBoxDiscountRow.propTypes = {
  regularPrice: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
};

export default BuyBoxDiscountRow
