import React from 'react'
import csrfToken from '../src/csrf_token'

class PasswordMeter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      score: null,
      timeout: null,
      timestamp: Date.now()
    }

    this.handlePasswordStrengthCheck(this.props.password)
  }

  componentDidUpdate(prevProps) {
    if(this.props.password !== prevProps.password) {
      if(this.state.timeout) clearTimeout(this.state.timeout)
      this.state.timeout = setTimeout(() => {
        this.handlePasswordStrengthCheck(this.props.password)
      }, 500)
    }
  }

  handlePasswordStrengthCheck(password) {
    $.ajax({
      url: '/password_strength_checks',
      dataType: 'json',
      method: 'post',
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        password: password,
        timestamp: Date.now()
      },
      success: function(response) {
        if(response.timestamp > this.state.timestamp) {
          this.setState({
            score: response.score,
            timestamp: response.timestamp
          })
        }
      }.bind(this),
      error: function(data) {
        console.log(data)
      }.bind(this)
    })
  }

  displayScore(score) {
    return score || score === 0
  }

  render() {
    const { score } = this.state

    const strength = {
      0: "Worst",
      1: "Bad",
      2: "Weak",
      3: "Good",
      4: "Strong"
    }

    return (
      <div className="password-meter">
        { this.displayScore(score) &&
          <div className="password-meter-container">
            <div className="password-meter-score-container">
              <div className="password-meter-score">
                <div className={`password-meter-score-score-${score}`}></div>
                <p className="password-meter-score-text">{strength[score]}</p>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

export default PasswordMeter
