import React from 'react'

class NavigationMenuLink extends React.Component {
  render() {
      return (
          <li className="navigation-menu-link"><a href={ this.props.item.path } title={ this.props.item.title } data-layer={ this.props.item.data_layer }>{ this.props.item.title }</a></li>
      )
  }
}

export default NavigationMenuLink
