import React from 'react'
import PropTypes from 'prop-types';
import UserProductDownloadOses from './UserProductDownloadOses'
import UserProductDownloadVersions from './UserProductDownloadVersions'
import csrfToken from '../src/csrf_token'

class UserProductDownload extends React.Component {
    static propTypes = {
        order_item: PropTypes.object.isRequired
    };

    state = {
        order_item: this.props.order_item,
        pc: this.props.order_item.os.current_pc,
        progress: false
    };

    handleOsChnage = (pc) => {
        this.setState({ pc: pc })
    };

    handleVersionChange = (version) => {
        var data = {
            id: this.state.order_item.order_item_id,
            version: version
        }

        this.setState({ progress: true })

        $.ajax({
            url: '/users/download_box',
            dataType: 'json',
            method: 'post',
            data: data,
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {

                if (data.error) {
                    Mess.publish('show_error', data.error)

                } else {
                    this.setState({ order_item: data.order_item, pc: data.order_item.os.current_pc })

                    Mess.publish('version_changed', data.order_item)
                }
                this.setState({ progress: false })

            }.bind(this),

            error: function(xhr, status, err) {
                Mess.publish('show_error', err.toString())
                this.setState({ progress: false })

            }.bind(this)
        });


    };

    render() {
        const loopcloud_downloads = this.state.order_item.loopcloud_downloads
        const regular_downloads = this.state.order_item.downloads
        const pc_downloads = loopcloud_downloads !== undefined ? [loopcloud_downloads.pc] : regular_downloads.pc
        const mac_downloads = loopcloud_downloads !== undefined ? [loopcloud_downloads.mac] : regular_downloads.mac

        const downloads = this.state.pc ? pc_downloads : mac_downloads

        const clazz = 'userproduct-download' + (this.state.progress ? ' --progress' : '')

        const loopcloudOauthUrl = this.state.order_item.loopcloud_oauth_url

        let downloadButton

        if (loopcloudOauthUrl === undefined) {
          downloadButton = _.map(downloads, function(item, idx) {
                              return (item.processing ?
                                  <span className="button-download" key={ item + idx}>Processing Download...</span> :
                                  // item.url could return external domain so in this case add rel="noopener noreferrer"
                                  <a href={ item.url } title={ '' } key={ 'item' + idx} className="button-download" target="_blank">{ item.name }</a>
                              )
                          }.bind(this))
        } else {
          downloadButton = <a href={ loopcloudOauthUrl } title={ '' } className="button-download" target="_blank">Connect to Loopcloud</a>
        }

        const showVersions = ((loopcloudOauthUrl === undefined) && this.props.expanded && (loopcloud_downloads === undefined))

        return (
            <div className={clazz}>
                <UserProductDownloadOses onChange={ this.handleOsChnage } order_item={ this.state.order_item } pc={ this.state.pc }/>
                <div className="userproduct-download-actions">
                    { downloadButton }

                    { showVersions ?
                      <UserProductDownloadVersions order_item={ this.state.order_item } onChange={ this.handleVersionChange } /> : null }
                </div>
            </div>
        )
    }
}

export default UserProductDownload
