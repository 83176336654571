import React from 'react'
import AudioPlayer from './AudioPlayer'

class GalleryContentAudio extends React.Component {
  render() {
      return (
          <div className="gallery-content --audio">
              <div className="gallery-content-inner">
                  <AudioPlayer key={ this.props.item.resource_id } src={ this.props.src } title={ this.props.desc } />
              </div>
          </div>
      )
  }
}

export default GalleryContentAudio
