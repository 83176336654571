import React from 'react'
import { setTheme, toggleTheme, THEMES } from '../src/utils/set_theme.js'

class DarkThemeSwitch extends React.Component {
  constructor(props) {
      super(props)

      this.state = {
        theme: props.theme
      }

      this.handleSwitchTheme = this.handleSwitchTheme.bind(this)
  }

  componentWillMount() {
    if(!this.state.theme && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && !(this.state.theme === 'dark')) {
      this.setState({ theme: 'dark' })
      setTheme(THEMES.dark)
    }
  }

  handleSwitchTheme() {
    toggleTheme()
  }

  render() {
    return (
      <div className="dark-theme-switch">
        <label className="dark-theme-switch-switch">
          <input
            type="checkbox"
            defaultChecked={this.state.theme === 'dark'}
            onChange={this.handleSwitchTheme}
          >
          </input>
          <span className="dark-theme-switch-slider"></span>
        </label>
      </div>
    )
  }
}

export default DarkThemeSwitch
