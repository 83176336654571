import React from 'react'
import NavigationTop from './NavigationTop'
import NavigationMenu from './NavigationMenu'
import NavigationWithoutMenu from './NavigationWithoutMenu';
import PropTypes from 'prop-types';
class Navigation extends React.Component {
    static propTypes = {
        effectsCategoryId: PropTypes.number.isRequired
    };

    state = {
        openedItemId: null
    };

    handleOpenedChange = (openedItemId) => {
        this.setState({ openedItemId: (openedItemId == this.state.openedItemId ? null : openedItemId) })

        event.stopPropagation()
    };

    handleClose = () => {
        this.setState({ openedItemId: null })
    };

    subMenuData = () => {
        var data = _.find(this.props.navigationData, function(el) { return el.id == this.state.openedItemId }.bind(this))

        return data
    };

    withSubmenu = () => {
        return this.state.openedItemId != 'manufacturers' && this.state.openedItemId != 'courses'
    };

    handle_scroll_top = () => {
        window.scrollTo(0, null)
    };

    render() {
        var clazz = 'navigation' + (this.state.openedItemId ? ' _opened' : '')

        return (
            <div className={ clazz }>
                { this.state.openedItemId ? <div className="navigation-cover" onClick={this.handleClose}> </div> : null }

                <input type="checkbox" id="menu"/>
                <label htmlFor="menu" onClick={ this.handle_scroll_top }></label>
                <NavigationTop items={ this.props.navigationData } opened_item_id={ this.state.openedItemId } onClick={ this.handleOpenedChange } />

                { this.withSubmenu() ?
                    <NavigationMenu item={ this.subMenuData() } onClose={ this.handleClose } effectsCategoryId={this.props.effectsCategoryId} /> :
                    <NavigationWithoutMenu item={ this.subMenuData() } onClose={ this.handleClose } /> }

            </div>
        )
    }
}

export default Navigation
