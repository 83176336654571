import React from 'react'

class Ribbons extends React.Component {
    render() {
        return (
            <ul className="ribbons">
                { _.map(this.props.ribbons, function(value, code) {
                    return <li key={code} className={ 'ribbons-' + code }>{ value }</li>
                }) }
            </ul>
        )
    }
}
export default Ribbons
