import React from 'react'
import Ribbons from './Ribbons'
import Oses from './Oses'

class BundleVerticalGalleryItem extends React.Component {
    render() {
        var product = this.props.product,
            manufacturer = product.manufacturer,
            category = product.category

        return (
            <a href={ product.link_to } className="bundlegallery-item" target="_blank" title={ '' }>
                <img src={ product.image_url } alt={ product.name }/>
                <div className="bundlegallery-meta">
                    <Ribbons ribbons={ product.ribbons }/>
                    <Oses oses={ product.oses } />

                    <h4>{ product.name }</h4>
                    { category.name } by { manufacturer.name }

                    <span className="_info">View in new tab</span>
                </div>
            </a>
        )
    }
}



export default BundleVerticalGalleryItem
