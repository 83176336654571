import React from 'react'

class PromoBoxPagingItem extends React.Component {
    handle_click = (event) => {
        this.props.on_change_to(this.props.idx)
    };

    render() {
        return <li onClick={ this.handle_click } className={ this.props.className } data-layer={dtl('homepage/promobox paging')}>{ this.props.idx + 1}</li>
    }
}

export default PromoBoxPagingItem
