import React from 'react'

class BPSLabels extends React.Component {
  render() {
    return(
      <div className='BPSLabels'>
        <div className='BPSLabels-content'>
          <div className='BPSLabels-content-title'>
            Choose from 30+ industry-leading brands
          </div>
          <div className='BPSLabels-content-image-wrapper'>
            <div>
              <img src={require(`../../images/beatport_studio/labels_logos.png`)} alt='labels logos' className='BPSLabels-content-image' />
              <img src={require(`../../images/beatport_studio/labels_logos.png`)} alt='labels logos' className='BPSLabels-content-image' />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BPSLabels