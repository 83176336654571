import React from 'react'
import PropTypes from 'prop-types';

class NavigationTopItem extends React.Component {
  constructor(props) {
    super(props);
    this.handle_link = this.handle_link.bind(this);
    this.handle_non_link = this.handle_non_link.bind(this);
  }

  handle_link(event) {
      if (!this.props.has_children) {
          return
      }

      if (!this.props.opened) {
          event.preventDefault()
          event.stopPropagation()
          this.props.onClick(this.props.item.id)
      }
  }

  handle_non_link() {
      this.props.onClick(this.props.item.id)
  }

  render() {
      var clazz = 'navigation-top-item ' + (this.props.opened ? '--selected' : '') + ' _' + this.props.item.id,
          item = this.props.item

      return (item.path ?
          <li className={ clazz }>
                <a href={ item.path } onClick={ this.handle_link } title={ item.title } data-method={ item.method } data-layer={ item.data_layer } target={item.target || '_self'}>{ item.title }</a>
          </li> :
          <li className={ clazz } onClick={ this.handle_non_link } data-layer={ item.data_layer } >
              { item.title }
          </li>
      )
  }
}
export default NavigationTopItem
