import React from 'react'
import PropTypes from 'prop-types';
import { setFallbackImage } from '../src/actions/setFallbackImage'

class UserProductImage extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired
    };

    onErrorImageMissing = (event) => {
        setFallbackImage(event, this.props.fallbackImageSrc)
    };

    render() {
        return (
            <a href={ this.props.link_to } title={ this.props.product.name } className="userproduct-image">
                <img src={ this.props.product.image_url || '/' } alt={ this.props.product.image_description } onError={(event) => this.onErrorImageMissing(event)} />
            </a>
        )
    }
}

export default UserProductImage
