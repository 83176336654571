import React from 'react'

class UserProductMeta extends React.Component {
  anchorHref(resource) {
    return resource.custom_link_to ||  resource.link_to
  }

  anchorTarget(resource) {
    return resource.custom_link_to ? '_blank' : '_self'
  }

  render() {
    const { product } = this.props
    const { category, manufacturer } = product

    return (
      <div className="userproduct-meta">
        <h3 className="userproduct-title">
          <a
            href={ this.anchorHref(product) }
            title={ product.name }
            target={ this.anchorTarget(product) }
          >
            { product.name }
          </a>
        </h3>
        <h4>
          <a
            href={ category.link_to }
            title={ category.name }
          >
            { category.name }
          </a> by <a href={ this.anchorHref(manufacturer) } title={ manufacturer.name } target={ this.anchorTarget(manufacturer) }>{ manufacturer.name }</a>
        </h4>
      </div>
    )
  }
}

export default UserProductMeta
