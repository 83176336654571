import React from 'react'
import PropTypes from "prop-types"
import CancelSubscriptionModal from './rent_to_own/CancelSubscriptionModal'
import PauseSubscriptionModal from "./rent_to_own/PauseSubscriptionModal";
import ResumeSubscriptionModal from "./rent_to_own/ResumeSubscriptionModal";

class UserSubscriptionProduct extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      actionsOpen: false,
      downloadsOpen: false,
      cancelSubModalIsOpen: false,
      pauseSubModalIsOpen: false,
      resumeSubModalIsOpen: false
    }

    this.actionsWrapperRef = React.createRef()
    this.downloadsWrapperRef = React.createRef()
    this.actionsButtonRef = React.createRef()
    this.downloadsButtonRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.isClickOutside = this.isClickOutside.bind(this)
    this.closeCancelSubModal = this.closeCancelSubModal.bind(this)
    this.closePauseSubModal = this.closePauseSubModal.bind(this)
    this.closeResumeSubModal = this.closeResumeSubModal.bind(this)
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true)
  }

  nextBillingText = (item) => {
    switch (item.status) {
      case 'paused':
      case 'Pause Scheduled':
        return 'Billing will resume at'
      default:
        return 'Active to'
    }
  }

  nextBillingValue = (item) => {
    switch (item.status) {
      case 'canceled':
      case 'expired':
        return item.expires_at || 'n/a'
      default:
        return item.next_billing_date
    }
  }

  showDropdown = (item) => {
    return this.canPause(item) || this.canResume(item) || this.canChangeOrCancel(item)
  }

  canChangeOrCancel = (item) => {
    return item.status === 'paused' || item.status === 'active' || item.status === 'On Trial'
  }

  canPause = (item) => {
    return item.pause_dates && item.pause_dates.resume && item.pause_dates.resume.length > 0 && !item.on_free_trial
  }

  canResume = (item) => {
    return item.status === 'paused' || item.scheduled_pause
  }

  handleClickOutside = event => {
    if (!this.state.actionsOpen && !this.state.downloadsOpen) return

    if (this.isClickOutside(event.target)) {
      this.setState({ actionsOpen: false, downloadsOpen: false })
    }
  }

  isClickOutside = target => {
    if (this.actionsWrapperRef && this.actionsWrapperRef.current && this.actionsWrapperRef.current.contains(target)) return false
    if (this.downloadsWrapperRef && this.downloadsWrapperRef.current && this.downloadsWrapperRef.current.contains(target)) return false
    if (this.actionsButtonRef && this.actionsButtonRef.current && this.actionsButtonRef.current.contains(target)) return false
    if (this.downloadsButtonRef && this.downloadsButtonRef.current && this.downloadsButtonRef.current.contains(target)) return false

    return true
  }

  openCloseActions() {
    if (this.state.actionsOpen) {
      this.setState({ actionsOpen: false })
    } else {
      this.setState({ actionsOpen: true })
      this.setState({ downloadsOpen: false })
    }
  }

  openCloseDownloads() {
    if (this.state.downloadsOpen) {
      this.setState({ downloadsOpen: false })
    } else {
      this.setState({ downloadsOpen: true })
      this.setState({ actionsOpen: false })
    }
  }

  openCancelSubModal() {
    this.setState({
      cancelSubModalIsOpen: true,
      actionsOpen: false
    })
  }

  openPauseSubModal() {
    this.setState({
      pauseSubModalIsOpen: true,
      actionsOpen: false
    })
  }

  openResumeSubModal() {
    this.setState({ resumeSubModalIsOpen: true, actionsOpen: false })
  }

  closeCancelSubModal() {
    this.setState({
      cancelSubModalIsOpen: false
    })
  }

  closePauseSubModal() {
    this.setState({
      pauseSubModalIsOpen: false
    })
  }

  closeResumeSubModal() {
    this.setState({ resumeSubModalIsOpen: false })
  }

  render() {
    const item = this.props.item
    const product = item.product
    const dotClass = `UserSubscriptionProduct-item-content-body-status-value-dot-${item.status}`
    const actionsClass = `UserSubscriptionProduct-item-actionsList-${this.state.actionsOpen ? 'visible' : ''}`
    const downloadsClass = `UserSubscriptionProduct-item-downloadsList-${this.state.downloadsOpen ? 'visible' : ''}`
    const downloadsActiveClass = this.state.downloadsOpen ? 'UserSubscriptionProduct-item-content-header-actions-download-active' : ''
    const downloadsTextActiveClass = this.state.downloadsOpen ? 'UserSubscriptionProduct-item-content-header-actions-download-text-active' : ''
    const downloadsIconActiveClass = this.state.downloadsOpen ? 'UserSubscriptionProduct-item-content-header-actions-download-icon-active' : ''
    const macUrl = product.latest_bp_access_os_downloads.mac[0].url
    const windowsUrl = product.latest_bp_access_os_downloads.pc[0].url

    return(
      <div className='UserSubscriptionProduct'>
        <div className='UserSubscriptionProduct-item'>
          <a className='UserSubscriptionProduct-item-img' href={product && product.link_to} target='_blank' rel="noopener noreferrer">
            <img className='UserSubscriptionProduct-item-img-tag' src={product && product.image_url} alt={product && product.image_description} />
          </a>
          <div className='UserSubscriptionProduct-item-content'>
            <div className='UserSubscriptionProduct-item-content-header'>
              <a className='UserSubscriptionProduct-item-content-header-name' href={product && product.link_to} target='_blank' rel="noopener noreferrer">
                {product && product.name || 'n/a'}
              </a>

              {
                item.status !== 'Processing' && (
                  <div className='UserSubscriptionProduct-item-content-header-actions'>
                    <div ref={this.downloadsButtonRef} onClick={() => this.openCloseDownloads()} className={`UserSubscriptionProduct-item-content-header-actions-download ${downloadsActiveClass}`}>
                      <div className={`UserSubscriptionProduct-item-content-header-actions-download-icon ${downloadsIconActiveClass}`}></div>
                      <div className={`UserSubscriptionProduct-item-content-header-actions-download-text ${downloadsTextActiveClass}`}>
                        Download
                      </div>
                    </div>

                    { this.showDropdown(item) &&
                      <div ref={this.actionsButtonRef} onClick={() => this.openCloseActions()} className='UserSubscriptionProduct-item-content-header-actions-moreActions'>
                        <div className='UserSubscriptionProduct-item-content-header-actions-moreActions-icon'></div>
                      </div>
                    }
                  </div>
                )
              }
            </div>
            <div className='UserSubscriptionProduct-item-content-body'>
              <div className='UserSubscriptionProduct-item-content-body-status'>
                <div className='UserSubscriptionProduct-item-content-body-status-text'>
                  Status
                </div>
                <div className='UserSubscriptionProduct-item-content-body-status-value'>
                  <div className={`UserSubscriptionProduct-item-content-body-status-value-dot ${dotClass}`}></div>
                  <div className='UserSubscriptionProduct-item-content-body-status-value-text'>{item.status}</div>
                  { item.scheduled_pause && <ScheduledPauseInfo pauseStartDate={item.pause_dates.start} /> }
                </div>
              </div>
              <div className='UserSubscriptionProduct-item-content-body-billingData'>
                {
                  item.status !== 'Processing' && (
                    <div className='UserSubscriptionProduct-item-content-body-billingData-payments'>
                      <div className='UserSubscriptionProduct-item-content-body-billingData-payments-text'>
                        Current plan
                      </div>
                      <div className='UserSubscriptionProduct-item-content-body-billingData-payments-value'>
                        {item.plan_period}
                      </div>
                    </div>
                  )
                }
                <div className='UserSubscriptionProduct-item-content-body-billingData-nextBilling'>
                  <div className='UserSubscriptionProduct-item-content-body-billingData-nextBilling-text'>
                    {this.nextBillingText(item)}
                  </div>
                  <div className='UserSubscriptionProduct-item-content-body-billingData-nextBilling-value'>
                    <div className='UserSubscriptionProduct-item-content-body-billingData-nextBilling-value-text'>
                      {this.nextBillingValue(item)}
                    </div>
                  </div>
                </div>
                <div className='UserSubscriptionProduct-item-content-body-billingData-billedAmount'>
                  <div className='UserSubscriptionProduct-item-content-body-billingData-billedAmount-text'>
                    Billed {item.plan_period}
                  </div>
                  <div className='UserSubscriptionProduct-item-content-body-billingData-billedAmount-value'>
                    {item.price}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div ref={this.actionsWrapperRef} className={`UserSubscriptionProduct-item-actionsList ${actionsClass}`}>
            { this.canChangeOrCancel(item) && <a className='UserSubscriptionProduct-item-actionsList-action' href={item.change_payment_method_url}>Change Payment Method</a> }
            { this.canChangeOrCancel(item) && <div className='UserSubscriptionProduct-item-actionsList-action' onClick={() => this.openCancelSubModal()}>Cancel Subscription</div> }
            {/* <div className='UserSubscriptionProduct-item-actionsList-action'>Change Plan</div> */}
            { this.canPause(item) && <div className='UserSubscriptionProduct-item-actionsList-action' onClick={() => this.openPauseSubModal()}>Pause Plan</div> }
            { this.canResume(item) && <div className='UserSubscriptionProduct-item-actionsList-action' onClick={() => this.openResumeSubModal()}>Resume Plan</div> }
          </div>

          <div ref={this.downloadsWrapperRef} className={`UserSubscriptionProduct-item-downloadsList ${downloadsClass}`}>
            <a className='UserSubscriptionProduct-item-downloadsList-windows'
              href={windowsUrl} target='_blank' rel="noreferrer">
              <div className='UserSubscriptionProduct-item-downloadsList-windows-icon'></div>
              <div className='UserSubscriptionProduct-item-downloadsList-windows-text'>Windows</div>
            </a>
            <a className='UserSubscriptionProduct-item-downloadsList-mac'
              href={macUrl} target='_blank' rel="noreferrer">
              <div className='UserSubscriptionProduct-item-downloadsList-mac-icon'></div>
              <div className='UserSubscriptionProduct-item-downloadsList-mac-text' >Mac</div>
            </a>
          </div>
        </div>

        <CancelSubscriptionModal
          isOpen={this.state.cancelSubModalIsOpen}
          closeCancelSubModal={this.closeCancelSubModal}
          afterActionCallback={this.props.reloadItems}
          productName={product && product.name || 'n/a'}
          nextBillingDate={item.expires_at || 'n/a'}
          isPaused={item.status === 'paused'}
          subscription={item}
        />

        { this.canPause(item) && <PauseSubscriptionModal isOpen={this.state.pauseSubModalIsOpen}
                                                         closePauseSubModal={this.closePauseSubModal}
                                                         afterActionCallback={this.props.reloadItems}
                                                         productName={product.name}
                                                         subscriptionId={item.id}
                                                         nextBillingDate={item.next_billing_date || 'n/a'}
                                                         pauseDates={item.pause_dates || {}}
                                                         period={item.plan_period}
                                                         maxPauseCycles={item.max_pause_cycles} /> }

        { this.canResume(item) && <ResumeSubscriptionModal closeResumeSubModal={this.closeResumeSubModal}
                                                           afterActionCallback={this.props.reloadItems}
                                                           isOpen={this.state.resumeSubModalIsOpen}
                                                           productName={product.name}
                                                           subscriptionId={item.id} /> }
      </div>
    )
  }
}

UserSubscriptionProduct.propTypes = {
  item: PropTypes.object,
  reloadItems: PropTypes.func
}

export default UserSubscriptionProduct

class ScheduledPauseInfo extends React.Component {
  state = {
    showInfo: false
  }

  showInfo = () => {
    this.setState({ showInfo: true })
  }

  hideInfo = () => {
    this.setState({ showInfo: false })
  }

  render() {
    const { pauseStartDate } = this.props
    const { showInfo } = this.state

    return <div className="scheduledPauseInfo">
      <div className="questionCircle" onMouseEnter={this.showInfo} onMouseLeave={this.hideInfo}>?</div>
      { showInfo &&
        <div className="hoverInfo">You can still use your product through {pauseStartDate}, after which your plan will be paused</div>
      }
    </div>
  }
}

ScheduledPauseInfo.propTypes = {
  pauseStartDate: PropTypes.string.isRequired
}
