import React from 'react'
import AudioButton from './AudioButton'
import SamplepackPrice from './SamplepackPrice'
import { setFallbackImage } from '../src/actions/setFallbackImage'

class Samplepack extends React.Component {
    state = { audio_visible: false };

    handle_click = (event) => {
        event.stopPropagation()
        event.preventDefault()
        this.props.onClick(this.props.item.product_id)
    };

    handle_show_audio = () => {
        if (!this.state.audio_visible) {
            this.setState({ audio_visible: true})
        }
    };

    onErrorImageMissing = (event) => {
      setFallbackImage(event, this.props.fallbackImageSrc)
    };

    render() {
        return (
            <div className="samplepack" onMouseEnter={ this.handle_show_audio } >
                <a href="#details" title={ '' } onClick={ this.handle_click }><img src={ this.props.item.image_url || '/' } alt={ this.props.item.image_description } onError={(event) => this.onErrorImageMissing(event)} /></a>
                <a href="#details" title={ '' } onClick={ this.handle_click } className="_details">Details</a>

                { this.state.audio_visible ?
                    <AudioButton src={ this.props.item.audio_url }/> :
                    null }

                <SamplepackPrice item={ this.props.item } />
            </div>
        )
    }
}

export default Samplepack
