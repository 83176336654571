import React from 'react'
import Samplepacks from './Samplepacks'
import csrfToken from '../src/csrf_token'

class SamplepacksChooser extends React.Component {
    state = { visible: false, samplepacks: this.props.samplepacks, processing: false };

    componentDidMount() {
        Mess.subscribe('refresh_samplepacks', this.handle_refresh)
    }

    handle_refresh = () => {
        let limit = this.state.samplepacks.length
        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: `/rewards/samplepacks/?limit=${limit}`,
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: (data) => this.setState({ samplepacks: data, processing: false }),
            error: () => this.setState({ processing: false })
        });
    };

    handle_click = () => {
        if (this.state.processing) {
            return
        }

        if (this.state.visible) {
            this.setState({ visible: false })
            return
        }

        this.setState({ processing: true })

        if (this.xhr) {
            this.xhr.abort()
        }

        this.xhr = $.ajax({
            url: '/rewards/samplepacks',
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ samplepacks: data, processing: false, visible: true })

            }.bind(this),

            error: function(xhr, status, err) {
                this.setState({ processing: false })

            }.bind(this)
        });
    };

    render() {
        let activatorClass = 'samplepacks-chooser-activator' + (this.state.visible ? ' _opened' : ''),
            chooserClass = 'samplepacks-chooser' + ( this.state.processing ? ' processing' : '' )

        return (
            <div className={ chooserClass }>

                <Samplepacks samplepacks={ this.state.samplepacks } />

                { this.state.visible ?
                    null :
                    <a href="#samplepacks" onClick={ this.handle_click } className={ activatorClass } title="Show full list of samplepacks">See the list of samplepacks</a>
                }

            </div>
        )
    }
}
export default SamplepacksChooser
