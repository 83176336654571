import React from 'react'
import Samplepack from './Samplepack'
import SamplepackDetails from './SamplepackDetails'

class Samplepacks extends React.Component {
    state = {
        details_id: null
    };

    show_details = (new_details_id) => {
        var current_details_id = this.state.details_id

        this.setState({ details_id: (new_details_id == current_details_id ? null : new_details_id) })
    };

    render() {
        return (
            <div className="samplepacks">
                { this.generate_items() }
            </div>
        )
    }

    generate_items = () => {
        var items = [],
            packs = this.props.samplepacks,
            show_details = false,
            details_id = this.state.details_id,
            idx

        for (var i = 0; i < packs.length; i++) {
            var active = details_id && details_id == packs[i].product_id

            items.push(<Samplepack key={ 'p' + packs[i].product_id } item={ packs[i] } active={ active } onClick={ this.show_details } fallbackImageSrc={this.props.fallbackImageSrc} />)

            if (active) {
                show_details = true
                idx = i % 5

            }

            if ((i % 5 == 4 || i == packs.length - 1) && show_details) {
                items.push(<SamplepackDetails product_id={ this.state.details_id } idx={ idx } key={ 'pd' + details_id } />)
                show_details = false
            }
        }

        return items
    };
}

export default Samplepacks
