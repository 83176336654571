import React from 'react'
import PropTypes from 'prop-types';

class RatingStars extends React.Component {
    static propTypes = {
        value: PropTypes.number.isRequired
    };

    render() {
        return (
            <span className="ratingstars"><span className={ 'ratingstars-' + this.props.value } >{ this.props.value }</span></span>
        )
    }
}

export default RatingStars
