import React from 'react'
import UserUpdateImage from './UserUpdateImage'
import UserUpdateMeta from './UserUpdateMeta'
import UserUpdateInfo from './UserUpdateInfo'
import UserUpdateDownload from './UserUpdateDownload'

class UserUpdates extends React.Component {
    render() {
        let items = this.props.updates
        const fallbackImageSrc = this.props.fallbackImageSrc

        return (
          <div>
              { _.map(items, function(item) {
                    return (
                        <div className="userupdate" key={ 'update-item-' + item.product_id }>
                            <UserUpdateImage product={ item.product } fallbackImageSrc={ fallbackImageSrc } />
                            <div className="userupdate-data">
                                <UserUpdateMeta product={ item.product } />
                                <UserUpdateInfo item={ item } />
                            </div>

                            <div className="userupdate-footer">
                                <UserUpdateDownload item={ item } />
                            </div>
                        </div>
                    )
                })
              }
          </div>
        )
    }
}

export default UserUpdates
