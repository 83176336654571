import React from 'react'
import PropTypes from 'prop-types'

class CurrentBalancesWidget extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.toggleWidget = this.toggleWidget.bind(this)
  }

  toggleWidget() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { isOpen } = this.state
    const { virtualCash, tokens } = this.props.user

    return (
      <div
        className="current-balances-widget"
        onClick={this.toggleWidget}
      >
        <div className={`current-balances-widget-toggle ${isOpen ? 'toggle-arrow-up' : 'toggle-arrow-down'}`}></div>
        { isOpen ? (
          <div className="current-balances-widget-opened">
            <div className="current-balances-widget-opened-container">
              <div className="current-balances-widget-vc">
                <div className="current-balances-widget-vc-label">Your virtual cash balance</div>
                <div className="current-balances-widget-vc-value">{virtualCash}</div>
              </div>

              <div className="current-balances-widget-tokens">
                <div className="current-balances-widget-tokens-label">Tokens earned</div>
                <div className="current-balances-widget-tokens-value">{tokens}</div>
              </div>
            </div>
          </div>
          ) : (
          <div className="current-balances-widget-closed">
            <div className="current-balances-widget-title">See current balances</div>
          </div>
          )
        }
      </div>
    )
  }
}

CurrentBalancesWidget.propTypes = {
  user: PropTypes.object.isRequired
};

export default CurrentBalancesWidget
