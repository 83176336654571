import React from 'react'
import { setFallbackImage } from '../src/actions/setFallbackImage'

class BundleGalleryItem extends React.Component {
    onErrorImageMissing(event) {
        setFallbackImage(event, this.props.fallbackImageSrc)
    }

    render() {
        var item = this.props.item,
            image_url = item.image_url,
            link_to = item.link_to,
            name = item.name

        if (link_to) {
            return (
                <a href={ link_to } className="bundlegallery-item" target="_blank" title={ `Show ${name} product`}>
                    <img src={ image_url || '/' } onError={(event) => this.onErrorImageMissing(event)} alt={ name }/>
                </a>
            )

        } else {
            return <div className="bundlegallery-item"><img src={ image_url } onError={(event) => this.onErrorImageMissing(event)} alt={ name }/></div>
        }
    }
}
export default BundleGalleryItem
