import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'

const videoModalStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    border: 'none',
    background: 'rgba(36, 36, 36, 0)',
    overflow: 'initial',
    outline: 'none'
  },
  overlay: {
    background: 'rgba(36, 36, 36, 0.8)',
    backdropFilter: 'blur(7px)'
  }
}

class BPSVideoModal extends React.Component {
  render() {
    const videoModalHolder = document.getElementById('video-modal')

    const contents = <Modal
                      isOpen={this.props.isOpen}
                      onRequestClose={() => this.props.closeVideoModal()}
                      style={videoModalStyles}
                      ariaHideApp={false}
                      className='BPSVideoModal'
                     >
      <div className='BPSVideoModal'>
        <div className='BPSVideoModal-btn' onClick={() => this.props.closeVideoModal()}></div>

        <div className='BPSVideoModal-wrapper'>
          <iframe className='BPSVideoModal-wrapper-iframe'
            src="https://www.youtube.com/embed/zN-z0GN9WN0?si=Peg1kMKdj66pOCK1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </Modal>

    return ReactDOM.createPortal(this.props.isOpen ? contents : null, videoModalHolder)
  }
}

export default BPSVideoModal