import React from 'react'
import PropTypes from 'prop-types'
import { setFallbackImage } from '../src/actions/setFallbackImage'

class UserUpdateImage extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired
    };

    onErrorImageMissing = (event) => {
        setFallbackImage(event, this.props.fallbackImageSrc)
    };

    render() {
        return (
            <a href={ this.props.link_to } className="userupdate-image">
                <img src={ this.props.product.image_url || '/' } onError={(event) => this.onErrorImageMissing(event)} />
            </a>
        )
    }
}

export default UserUpdateImage
