import React, { useState } from "react"
import Select from 'react-select'

const filter = (option, searchText) => {
  return option.data.search.toLowerCase().includes(searchText.toLowerCase())
    || option.data.value.toLowerCase().includes(searchText.toLowerCase())
}

const darkStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? '0 0 0 1px #474747' : '',
    borderColor: state.isFocused ? '#474747' : '#525252',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'black' :'white',
    backgroundColor: state.isSelected ? '#474747' : '#363636',
  }),
}

const CountrySelect = (props) => {
  const [country, setCountry] = useState(props.country || 'GB')

  const options = props.countriesList.map((val) => {
    // eslint-disable-next-line no-undef
    const labelImg = <span
        dangerouslySetInnerHTML={{
          __html: `<img class="country_flag" src="${require(`../../../images/flags/${val[0].toLowerCase()}.svg`)}" alt="${val[1]} flag"><span class="country_label">${val[1]}</span>`
        }}
      />
    return { value: val[0], label: labelImg, search: val[1] }
  });

  return <>
    <Select
      name='billing_info[country]'
      value={ options.find(element => element.value === country) }
      options={options}
      onChange={(event) => setCountry(() => event.target?.value || event.value)}
      className='bps-checkout-field-country'
      classNamePrefix='bps-checkout-field-country'
      filterOption={filter}
      styles={darkStyles}
      data-recurly='country'
      required={true}
    />
    <input type='hidden' name='billing_info[country]' value={country} data-recurly='country' />
  </>
}

export default CountrySelect