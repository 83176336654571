import React from 'react'
import DistributorProductsActivator from './DistributorProductsActivator'
import DistributorProductsContent from './DistributorProductsContent'
import Tick from './Tick'
import csrfToken from '../src/csrf_token'

class DistributorProducts extends React.Component {
    state = { visible: false, products: null, processing: false }

    load_data() {
        this.setState({ processing: true })
        $.ajax({
            url: '/distributors/distributor_products/' + this.props.manufacturer_id,
            dataType: 'json',
            headers: {
    					"X-CSRF-Token": csrfToken()
    				},
            success: (data) => this.setState({ products: data, processing: false }),
            error: (xhr, status, err) => {
                console.error(err)
                this.setState({ visible:  false, processing: false })
            }
        });
    }

    toggle_product = (product_id, show) => {
        this.setState({ processing: true })
        let hide_show = show ? 'enable_product/' : 'disable_product/'
        $.ajax({
            url: '/distributors/' + hide_show + product_id,
            dataType: 'json',
            headers: {
    					"X-CSRF-Token": csrfToken()
    				},
            success: (data) => {
                let products = this.state.products.map((item) => item.item_id != product_id ? item : { ...item, disabled: !show })
                this.setState({ products: products, processing: false })
            },
            error: (xhr, status, err) => {
                console.error(err)
                this.setState({ visible:  false, processing: false })
            }
        });
    }

    show = () => {
        if (!this.state.products) {
            this.load_data()
        }
        this.setState({ visible: true })
    }

    hide = () => this.setState({ visible: false })

    render() {
        let { visible, products, processing } = this.state
        let clazz = "distributorproducts" + (processing ? ' _processing' : '')

        return (
            <div className={ clazz }>
                <DistributorProductsActivator
                    onClick={ visible ? this.hide : this.show }/>

                { visible && products ? <DistributorProductsContent
                        products={ products }
                        onToggleProduct={ this.toggle_product.bind(this) } /> : null
                }

                { visible ? <Tick className="tick distributorproducts-close" off={ true } onClick={ this.hide }/> : null }
            </div>
        )
    }
}

export default DistributorProducts
