import React from 'react'

class BackToBeatportWidget extends React.Component {
  render() {
    return (
      <div className="back-to-beatport-widget">
        <div className="back-to-beatport-widget-text">
          <h3>
            <a href="http://beatport.com">
              <span className="back-to-beatport-widget-text-text">&lt; Go back to</span>
              <span className="back-to-beatport-widget-text-logo"></span>
            </a>
          </h3>
        </div>
        <div className="back-to-beatport-widget-logo"></div>
      </div>
    )
  }
}

export default BackToBeatportWidget
