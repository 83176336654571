import React from 'react'
import BundleGalleryItem from './BundleGalleryItem'

class BundleGallery extends React.Component {
    constructor(props) {
        super(props)
        this.state = { current: 0 }
    }

    handle_prev() {
        var current = this.state.current

        if (current > 0) {
            this.setState({ current: current - 1})
        }
    }

    handle_next() {
        var current = this.state.current,
            size = this.props.items.length

        if (current < size - 1) {
            this.setState({ current: current + 1 })
        }
    }

    render() {
        var items = this.props.items,
            size = items.length,
            current = this.state.current,
            item_width = 550,
            offset = -(current * item_width),
            width = size * item_width,
            has_prev = current > 0,
            has_next = size > 1 && current < size - 1,
            prev_clazz = 'bundlegallery-prev' + (has_prev ? '' : ' _off'),
            next_clazz = 'bundlegallery-next' + (has_next ? '' : ' _off')

        return (
            <div className="bundlegallery">
                <div className="bundlegallery-window">
                    <div className="bundlegallery-content" style={{ marginLeft: offset + 'px', width: width + 'px' }} >
                        {
                            items.map((item, idx) =>
                                <BundleGalleryItem name={ this.props.name } item={ item } key={ item.item_id } fallbackImageSrc={this.props.fallbackImageSrc} />
                            )
                        }
                    </div>
                </div>
                <span className={ prev_clazz } onClick={ this.handle_prev.bind(this) }></span>
                <span className={ next_clazz } onClick={ this.handle_next.bind(this) }></span>
            </div>
        )
    }
}


export default BundleGallery
