import React from 'react'
import Markdown from 'markdown-to-jsx'

const AnswerMessage = ({ products, answer, cannedStart, cannedEnd }) => {
  const formatProductInfo = (product) => {
    const percentageDiscount = product.percentage_discount
    const formatted_discount = (percentageDiscount && `(-${percentageDiscount}%)`) || ''
    const additionalInfo = product.available
      ? ` by ${product.manufacturer_name}: ${product.sell_price} ${formatted_discount}`
      : ' (No longer available)'

    return (
      <div className="AnswerMessage-container-products-item" key={product.id}>
        <a className="AnswerMessage-container-products-item-name dib" href={product.url}>
          { product.name }
        </a>
        <span>
          { additionalInfo }
        </span>
      </div>
    )
  }

  return(
    <div className="AnswerMessage rcw-client-prompt rcw-client">
      <div className="AnswerMessage-container rcw-message-text rcw-message-text-prompt">
        <div className="AnswerMessage-container-disclaimer">This response is AI generated</div>
        <div className="AnswerMessage-container-topText">{cannedStart}</div>
        { products.length > 0 && (
          <div className="AnswerMessage-container-products">
            { products.map(product => (
              formatProductInfo(product)
            ))}
          </div>
        )}
        <div className="AnswerMessage-container-generatedText"><Markdown>{answer}</Markdown></div>
        <p className="AnswerMessage-container-bottomText">
          {cannedEnd}
        </p>
      </div>
    </div>
  )
}

export default AnswerMessage
