import React from 'react'
import CartLoginEmail from './CartLoginEmail'
import CartLoginPassword from './CartLoginPassword'

class CartLogin extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      email: null
    }

    this.renderStep = this.renderStep.bind(this)
  }

  renderStep() {
    const { step, email } = this.state

    switch (step) {
      case 1:
        return <CartLoginEmail toStepTwo={(email) => this.toStepTwo(email)} email={email}/>
      case 2:
        return <CartLoginPassword toStepOne={() => this.toStepOne()} email={email}/>
    }
  }

  toStepTwo(email) {
    this.setState({
      step: 2,
      email: email
    })
  }

  toStepOne() {
    this.setState({
      step: 1
    })
  }

  render() {
    return (
      <div className="cart-login">

        <div className="cart-login-back-button-container">
          <a className="cart-login-back-button" href="/cart">&lt; Back</a>
        </div>

        <div className="cart-login-title-container">
          <div className="cart-login-title">
            Start Checkout
          </div>
        </div>

        {this.renderStep()}
      </div>
    )
  }
}

export default CartLogin
