import React from 'react'
import PropTypes from 'prop-types'
import { addToWishlist } from '../../src/actions/addToWishlist'
import { setFallbackImage } from '../../src/actions/setFallbackImage'

class SidebarCartItem extends React.Component {
  onErrorImageMissing(event) {
    setFallbackImage(event, this.props.fallbackImageSrc)
  }

  wishlistSection(product) {
    if (product.in_wishlist) {
      return(
        <span>In wishlist</span>
      )
    } else {
      return (
        <div onClick={ () => addToWishlist(product, this.props.subscriptionId) }>
          Add to wishlist
        </div>
      )
    }
  }

  renderPriceSection(item, isGift) {
    if (isGift === false) {
      return (
        <span>
          {item.sell_price_without_discount}
        </span>
      )
    } else {
      return(
        <span>
          <span className="SidebarCartItem-secondRow-price--free">
            FREE
          </span>
          <span className="SidebarCartItem-secondRow-price--crossed">
            {item.value_with_currency}
          </span>
        </span>
      )
    }
  }

  render() {
    const item = this.props.item
    const product = item.custom_bundle_product || item.product || item.course

    return(
      <div className="SidebarCartItem">
        <div className="SidebarCartItem-firstRow">
          <a href={product.link_to} className="SidebarCartItem-firstRow-imgContainer">
            <img src={product.image_url || this.props.fallbackImageSrc} alt={product.image_description} onError={(event) => this.onErrorImageMissing(event)} />
          </a>
          <div className="SidebarCartItem-firstRow-content">
            <div className="SidebarCartItem-firstRow-content-main">
              <a
                className="SidebarCartItem-firstRow-content-main--name"
                href={product.link_to}
                title={product.name}
              >
                {product.name}
              </a>
              <a href={product.manufacturer.link_to} className="SidebarCartItem-firstRow-content-main--manufacturer">
                {product.manufacturer.name}
              </a>
              <a href={product.category.link_to} className="SidebarCartItem-firstRow-content-main--category">
                {product.category.name}
              </a>
            </div>
          </div>
        </div>
        <div className="SidebarCartItem-secondRow">
          <div className="SidebarCartItem-secondRow--wishlist">
            {(this.props.isGift === false) && this.wishlistSection(product)}
          </div>
          <div className="SidebarCartItem-secondRow-price">
            {this.renderPriceSection(item, this.props.isGift)}
          </div>
        </div>
      </div>
    )
  }
}

SidebarCartItem.propTypes = {
  fallbackImageSrc: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  vatSwitcherValue: PropTypes.bool.isRequired,
  isGift: PropTypes.bool.isRequired,
  subscriptionId: PropTypes.number
}

export default SidebarCartItem
