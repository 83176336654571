import React from 'react'

class UserCoursePlay extends React.Component {
    handlePlay = (event) => {
        Mess.publish('show_course', this.props.order_item.course.course_id)
    };

    render() {
        return (
            <div className="userproduct-download">
                <div className="userproduct-download-actions">
                    <a href="#play" title={ '' } key={ 'item' + this.props.order_item.order_item_id } className="button-play" onClick={ this.handlePlay }>Watch Now</a>
                </div>
            </div>
        )

    }
}



export default UserCoursePlay
