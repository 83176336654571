import React from 'react'
import BundleVerticalGalleryItem from './BundleVerticalGalleryItem'

class BundleVerticalGallery extends React.Component {
    state = { current: 0 };

    handle_prev = () => {
        var current = this.state.current

        if (current > 0) {
            this.setState({ current: current - 1})
        }
    };

    handle_next = () => {
        var current = this.state.current,
            size = this.props.items.length

        if (current < size - 3) {
            this.setState({ current: current + 1 })
        }
    };

    render() {
        var items = this.props.items,
            size = items.length,
            item_height = 96,
            current = this.state.current,
            offset = -(current * item_height),
            has_prev = current > 0,
            has_next = size > 3 && current < size - 3,
            prev_clazz = 'bundlegallery-prev' + (has_prev ? '' : ' _off')
            next_clazz = 'bundlegallery-next' + (has_next ? '' : ' _off')

        return (
            <div className="bundlegallery">
                <div className="bundlegallery-window">
                    <div className="bundlegallery-content" style={{ marginTop: offset + 'px' }} >
                        { _.map(items, function(el) {
                            return <BundleVerticalGalleryItem product={ el.product } key={ el.custom_bundle_item_id } />
                        }) }
                    </div>
                </div>
                <span className={ prev_clazz } onClick={ this.handle_prev }></span>
                <span className={ next_clazz } onClick={ this.handle_next }></span>
            </div>
        )
    }
}

export default BundleVerticalGallery
