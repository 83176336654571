import React from 'react'
import AudioPlayerProgress from './AudioPlayerProgress'

class AudioPlayer extends React.Component {
    state = { playing: false, duration: null, currentTime: null, progress: 0 };

    componentDidMount() {
        var audio = this.refs.audio;
        audio.addEventListener('progress', this.updateProgress);
        audio.addEventListener('timeupdate', this.updateProgress);
        audio.addEventListener('ended', this.handleMediaEnd);
    }

    componentWillUnmount() {
        var audio = this.refs.audio

        if (audio) {
            audio.removeEventListener('progress', this.updateProgress);
            audio.removeEventListener('timeupdate', this.updateProgress);
            audio.removeEventListener('ended', this.handleMediaEnd);
            audio.pause()
        }
    }

    updateProgress = (event) => {
        var audio = this.refs.audio

        var progress = (audio.currentTime / audio.duration) * 100
        var duration = this.formatTime(Math.ceil(audio.duration))
        var current = this.formatTime(Math.ceil(audio.currentTime))

        this.setState({ duration: duration, currentTime: current, progress: progress })
    };

    handleMediaEnd = (event) => {
        this.setState({ playing: false })
    };

    formatTime = (seconds) => {
        if (isNaN(seconds)) {
            return ''
        }

        var minutes = Math.floor(seconds / 60)
        var secs = seconds % 60
        secs = secs < 10 ? '0' + secs : secs

        return minutes + ':' + secs
    };

    handlePlayPause = () => {
        var audio = this.refs.audio
        var playing = this.state.playing

        if (playing) {
            audio.pause()
            dataLayer.push(dtl('product page/gallery:pause', { raw: true, label: this.props.title }))

        } else {
            // audio.load()
            audio.play()
            dataLayer.push(dtl('product page/gallery:play', { raw: true, label: this.props.title }))
        }

        this.setState({ playing: !playing })
    };

    handle_change_progress = (ratio) => {
        var audio = this.refs.audio,
            duration = audio.duration,
            new_current_time = duration * ratio

        audio.currentTime = new_current_time
    };

    render() {
        var clazz = 'audioplayer' + ( this.state.playing ? ' --playing' : '')

        return (
            <div className={ clazz }>
                <audio ref="audio">
                    <source src={ this.props.src }/>
                </audio>

                <button onClick={ this.handlePlayPause }>{ this.state.playing ? 'pause' : 'play' }</button>

                <AudioPlayerProgress progress={ this.state.progress } onChange={ this.handle_change_progress }/>

                <div className="audioplayer-meta">
                    <h3>{ this.props.title || 'Unknown' }</h3>
                    { this.state.duration ? (
                        <dl className="audioplayer-time">
                            <dt>{ this.state.currentTime }</dt>
                            <dd>{ this.state.duration }</dd>
                        </dl>
                        ) : null }
                </div>
            </div>
        )
    }
}

export default AudioPlayer
