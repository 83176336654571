global.dtl = function(value, opts) {

    if (!value) {
        return null
    }

    opts = opts || {}
    parts = value.split(/\s*\/\s*/)

    var out = {
        event: opts.event || 'send',
        category: opts.category || parts[0],
        action: opts.action || parts[1],
        label: opts.label || parts[2],
        value: opts.value || parts[3]
    }

    return opts.raw ? out : JSON.stringify(out)
}
