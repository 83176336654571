import React from 'react'
import ProcessLine from './ProcessLine'
import SamplepacksUse from './SamplepacksUse'
import csrfToken from '../src/csrf_token'

class SamplepackDetails extends React.Component {
    state = { samplepack: null, product_id: null, processing: false };

    componentWillReceiveProps() {
        this.retrieveDetails(this.props.product_id)
    }

    componentWillMount() {
        this.retrieveDetails(this.props.product_id)
    }

    retrieveDetails = (product_id) => {
        this.setState({processing: true })

        if (this.xhr) {
            this.xhr.abort()
        }

        this.xhr = $.ajax({
            url: '/rewards/samplepack/' + product_id,
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ samplepack: data, processing: false })

            }.bind(this),
            error: function(xhr, status, err) {
                this.setState({ processing: false })

            }.bind(this)
        });
    };

    render() {
        var samplepack = this.state.samplepack

        return ( samplepack ?
            <div className={ "samplepack-details _idx-" + this.props.idx } >
                <ProcessLine progress={ this.state.processing } />
                <h2>{ samplepack.name }</h2>

                <dl>
                    <dt dangerouslySetInnerHTML={{__html: samplepack.description }}></dt>
                    { samplepack.features ? <dd dangerouslySetInnerHTML={{__html: samplepack.features }}></dd> : null }
                </dl>

                <SamplepacksUse samplepack={ samplepack } />
            </div> :
            <div className={ "samplepack-details _closed _idx-" + this.props.idx } >
            </div>

        )
    }
}

export default SamplepackDetails
