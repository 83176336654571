import React from 'react'

class DistributorOrderDetailsItem extends React.Component {
  render() {
      var item = this.props.item,
          product = item.product,
          manufacturer = product.manufacturer

      return (
          <tr>
              <td>{ manufacturer.name }</td>
              <td>{ product.name }</td>
              <td className="_detailsprice">{ item.sell_price }</td>
          </tr>
      )
  }
}

export default DistributorOrderDetailsItem
