import React from 'react'
import PopupInfo from './PopupInfo'
import csrfToken from '../src/csrf_token'

class VcWallet extends React.Component {
    state = {
        processing: false,
        checkout: this.props.checkout,
        virtual_cash: this.props.virtual_cash,
        reward_tokens: this.props.reward_tokens,
        reward_progress: this.props.reward_progress,
        reward_gap: this.props.reward_gap,
        virtual_cash_float: this.props.virtual_cash_float
    };

    componentDidMount() {
        Mess.subscribe('refresh_wallet', this.handle_refesh)
    }

    handle_refesh = (param) => {
        console.log(param);
        this.setState({ processing: true })

        this.xhr = $.ajax({
            url: '/users/wallet_status',
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: (data) => this.setState(
                {
                    processing: false,
                    virtual_cash: data.virtual_cash,
                    reward_tokens: data.reward_tokens,
                    reward_gap: data.reward_gap,
                }),
            error: () => this.setState({ processing: false })
        })
    };

    startFromDigit = (text) => {
      return text.substr(0).match(/\d/);
    }

    getLabel = (text) => {
      if (text === "All orders expired.") { return "Expires:" }

      return this.startFromDigit(text) ? "Expires in:" : "Expires";
    }

    expireData() {
      return <div className="vc-expire-info">
        <span className="vc-expire-info-earn">Earn </span><span className="">as you spend!</span>
      </div>
    }

    render() {
      let clazz = "walletinfo" + ( this.state.processing ? ' processing' : '' )
      let token_plural = this.state.reward_tokens < 1 ? "Token" : "Tokens"
      let progress_style = {
        width: `${this.state.reward_progress}%`
      }
      const tokenValue = `${this.state.reward_tokens} ${token_plural}`

      return (
          <div className={ clazz }>
              <div className="walletinfo-section vc-expire">
                  <div className="vc-expire-header">
                    <div className="vc-expire-title">Virtual Cash</div>
                    <span className="vc-expire-logo"></span>
                  </div>
                  <div className="vc-expire-body">
                    <div className="vc-expire-value">{this.state.virtual_cash}</div>
                    { this.expireData() }
                    <a href="https://help.pluginboutique.com/hc/en-us/articles/6232614952340-What-is-Virtual-Cash-and-how-can-I-use-it-" target="_blank" className="vc-expire-button">Learn More</a>
                  </div>
              </div>
              <div className="walletinfo-section rewards">
                <div className="rewards-header">
                  <div className="rewards-title">Rewards+</div>
                  <span className="rewards-logo"></span>
                </div>
                <div className="rewards-body">
                  <div className="rewards-value">{tokenValue}</div>
                  <span className="rewards-info">Exclusive offers + <span className="rewards-more">more!</span></span>
                  <a href="/rewards" target="_blank" className="rewards-button">See Offers</a>
                </div>
              </div>

              <div className="walletinfo-section _gap gap">
                  <span className="rewardprogress">
                      <em style={ progress_style }></em>
                  </span>
                  <div className="rewards-body">
                    <div className="gap-value">{ `Spend ${this.state.reward_gap} to earn another token` }</div>
                    <a href="/deals" target="_blank" className="gap-button">Start Earning!</a>
                  </div>
              </div>
          </div>
      )
    }
}

export default VcWallet
