import React from 'react'
import PropTypes from 'prop-types'
import csrfToken from '../../src/csrf_token'

class VirtualCashContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      vcBalance: this.props.vcBalance,
      isVcApplied: this.props.isVcApplied,
      error: this.props.error,
      vcValue: '',
      notice: null
    }

    this.applyVc = this.applyVc.bind(this)
  }

  handleChange(e) {
    this.setState({
      vcValue: event.target.value
    })
  }

  applyVc() {
    const validVcValue = this.validateVcValue(this.state.vcValue)
    if (validVcValue) {
      Mess.publish('disablePayment')
      $.ajax({
        url: '/checkout/transition',
        dataType: 'json',
        method: 'post',
        headers: {
          "X-CSRF-Token": csrfToken()
        },
        data: {
          transition: 'apply_vc',
          order: {
            vc_spent: validVcValue
          }
        },
        success: function(response) {
          Mess.publish('refresh_cart', response)
          this.setState({
            vcBalance: response.cart.vcBalance,
            isVcApplied: response.cart.isVcApplied,
            error: null,
            notice: response.notice
          })
        }.bind(this),
        error: function(data) {
          this.setState({
            error: data.responseJSON.error
          })
        }.bind(this)
      })
    } else {
      this.setState({
        error: 'Use only digits and period or comma as a decimal separator'
      })
    }
  }

  validateVcValue(vcValue) {
    const replaced = vcValue.replaceAll(',', '.')
    if (!isNaN(Number(replaced)) && replaced.length > 0) {
      return Number(replaced).toString()
    }
  }

  render() {
    const errorClass = this.state.error ? 'red-border ' : ''
    const { error, notice, isVcApplied, vcBalance, vcValue } = this.state

    return (
      <div className="VirtualCashContainer">
        <div className="VirtualCashContainer-header">
          Virtual Cash
        </div>

        <div className="VirtualCashContainer-balance">
          Your current Virtual Cash balance is <span>{vcBalance}</span>
        </div>

        <div className="VirtualCashContainer-body">
          <div className="VirtualCashContainer-body-lable">
            Want to use your Virtual Cash?
          </div>
          { error && (
            <div className="error-container">
              <div className="error-text">
                {error}
              </div>
            </div>
          )}

          { (!error && isVcApplied) && (
            <div className="message-success">
              Your Virtual Cash has been successfully applied!
            </div>
          )}
          <div className="VirtualCashContainer-body-inputRow">
            <input className={errorClass + "VirtualCashContainer-body-inputRow-input"}
                   value={vcValue}
                   type="text"
                   placeholder="Enter the amount of virtual cash you want to use"
                   onChange={e => this.handleChange(e)} />

            <button className="VirtualCashContainer-body-inputRow-btn"
                    onClick={this.applyVc}>
              Apply
            </button>
          </div>
          {
            notice && (
              <div className="notice-container">
                <div className="notice-text">
                  { notice }
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

VirtualCashContainer.propTypes = {
  vcBalance: PropTypes.string,
  error: PropTypes.object,
  isVcApplied: PropTypes.bool.isRequired
}

export default VirtualCashContainer
