import React from 'react'
import csrfToken from '../src/csrf_token'

class Instructions extends React.Component {
    state = { expanded: this.props.expanded || false, content: this.props.content, processing: false };

    componentDidMount() {
      if (this.props.expanded) {
        window.addEventListener('load', function() {
          var hash = window.location.hash
          var url = window.location.href
          url = url.substring(0, url.length - hash.length)
          window.location = url + "#instructions"
        });
      }
    }

    fetch_content = () => {
        this.setState({ processing: true })

        $.ajax({
            url: '/products/' + this.props.item_id + '/installation_instructions',
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ processing: false, content: data.content, expanded: true })

            }.bind(this),
            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
                this.setState({ processing: false })
            }.bind(this)
        });
    };

    handle_click = (event) => {
        event.preventDefault()

        var expanded = this.state.expanded

        if (expanded) {
            this.setState({ expanded: false })

        } else {
            if (this.state.content) {
                this.setState({ expanded: true })

            } else {
                this.fetch_content()
            }
        }

    };

    render() {
        var clazz = "button-viewnow" + (this.state.processing ? ' _processing' : '')
        return (
            <div id="instructions" className="instructions">
                <div className="instructions-header">
                    Installation Instructions
                    <a href="#installation_instructions" className={ clazz } title={ this.state.expanded ? 'Hide installation instructions' : 'View installation instructions' } onClick={ this.handle_click }>
                        { this.state.expanded ? 'Hide' : 'View Now' }
                    </a>
                </div>
                { this.state.expanded ? <div className="instructions-content" dangerouslySetInnerHTML={{__html: this.state.content }} /> : null }
            </div>
        )

    }
}

export default Instructions
