import React from 'react'
import BPSFaqItem from './BPSFaqItem'

class BPSFaq extends React.Component {
  render() {
    return(
      <div className='BPSFaq'>
        <div className='BPSFaq-id-wrapper'>
          <div id='faq'></div>
        </div>
        <div className='BPSFaq-header' ref={this.props.reference}>
          Got questions? We've got answers.
        </div>
        <div className='BPSFaq-list'>
          <BPSFaqItem title="How does Beatport Studio work?">
            <ul>
              <li className='BPSFaqItem-content-textItem'>
                Select up to 10 plugins each month to access from an ever-expanding collection.
              </li>
              <li className='BPSFaqItem-content-textItem'>
                Choose up to 100 sounds per month from Loopcloud, the most diverse sample library on the planet.
              </li>
              <li className='BPSFaqItem-content-textItem'>
                Your choice of a DAW during the selection process.
              </li>
            </ul>
          </BPSFaqItem>

          <BPSFaqItem title="Can I change my selections mid-month?">
            <ul>
              <li className='BPSFaqItem-content-textItem'>
                If you've used all your selections for the month, you will not be able to make any changes until the beginning of your next billing cycle. If you anticipate wanting to choose plugins on-the-fly throughout the month, save some selections and don't use them all at once.
              </li>
            </ul>
          </BPSFaqItem>

          <BPSFaqItem title="Can I select a few plugins at first, and then use my remaining selections later?">
            <ul>
              <li className='BPSFaqItem-content-textItem'>
                Yes. Select as many plugins as you'd like when you're getting started, and you can make your remaining selections throughout the month. No pressure to select all 10 at once.
              </li>
            </ul>
          </BPSFaqItem>

          <BPSFaqItem title="Can I cancel my Beatport Studio membership at any time?">
            <ul>
              <li className='BPSFaqItem-content-textItem'>
                Both monthly and Annual plans can be cancelled at any time <a className="BPSFaqItem-content-textItem-link" target="_blank" rel="noopener noreferrer" href="/users/subscriptions">here</a>. Once cancelled, you will be able to access your products until the end of your billing cycle.
              </li>
            </ul>
          </BPSFaqItem>

          <BPSFaqItem title="How often can I change my selections?">
            <ul>
              <li className='BPSFaqItem-content-textItem'>
                You can change your selections each month for constant experimentation and ultimate flexibility, or keep the same selections with auto-renew. The choice is yours.
              </li>
              <img src={`${require(`../../images/beatport_studio/bps_faq.png`)}`} className='BPSFaqItem-content-billingCycleImg'></img>
            </ul>
          </BPSFaqItem>

          <BPSFaqItem title="What happens to my projects if I'm no longer subscribing to a plugin I've used in them?">
            <ul>
              <li className='BPSFaqItem-content-textItem'>
                Beatport Studio uses a Frozen State Mode technology. This means that selected products will continue to output sound while preserving a plugin's parameters or preset, ensuring that projects remain accessible.
              </li>
            </ul>
          </BPSFaqItem>
        </div>
      </div>
    )
  }
}

export default BPSFaq