import React from 'react'

class UserCouponsItemHeader extends React.Component {
  couponCode() {
    const coupon = this.props.coupon

    if (coupon.code) {
      return <strong>{ coupon.code}</strong>
    }

    if (coupon.available) {
      return <span>You can generate it, just click <strong>I WANT THIS COUPON</strong></span>
    }

    return <span><strong>Unavailable</strong> - you don't have enough Reward Tokens</span>
  }

  expiration() {
    const coupon = this.props.coupon,
      expires = coupon.expires_on

    return expires ? `| Expires on ${expires}` : null
  }

  render() {
    const coupon = this.props.coupon

    return (
      <div className="usercoupons-item-header">
        <p>Coupon Code: { coupon.discount } | { this.couponCode() } { this.expiration() }</p>
      </div>
    )
  }
}

export default UserCouponsItemHeader
