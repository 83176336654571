import React from 'react'
import PropTypes from 'prop-types'
import UserRentToOwnProduct from './UserRentToOwnProduct'
import UserSubscriptionProduct from './UserSubscriptionProduct'
import csrfToken from '../src/csrf_token'

class UserSubscriptionsBox extends React.Component {
  constructor(props) {
    super(props)

    this.state = { items: {}, processing: false }
  }

  componentDidMount() {
    this.fetchItems()
  }

  _reloadItems() {
    this.setState({ items: {} }, () => {
      this.fetchItems()
    })
  }

  fetchItems() {
    this.setState({ processing: true })

    const ids = this.props.subscriptionIds

    $.ajax({
      url: window.location.href,
      dataType: 'json',
      data: { ids: ids },
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      success: (data) => {
        const items = this.state.items

        this.setState({ items: { ...items, ...data }, processing: false })
      },
      error: (xhr, status, err) => {
        this.setState({ processing: false })

        console.error(this.props.url, status, err.toString())
      }
    })
  }

  regularSubs = () => {
    if (this.state.items !== {}) {
      return Object.values(this.state.items).filter(item => !item.is_rto)
    } else {
      return []
    }
  }

  rtoSubs = () => {
    if (this.state.items !== {}) {
      return Object.values(this.state.items).filter(item => item.is_rto)
    } else {
      return []
    }
  }

  render() {
    const { processing } = this.state
    const rtoSubs = this.rtoSubs()
    const regularSubs = this.regularSubs()

    return (
      <div className='UserSubscriptionsBox'>
        {
          this.props.regularSubsEnabled && (
            <div className='UserSubscriptionsBox-productsList'>
              <div className='UserSubscriptionsBox-productsList-regularSubs'>
                <div className='UserSubscriptionsBox-title'>Subscriptions</div>
                  { (regularSubs.length === 0)
                    ? <div className='UserSubscriptionsBox-productsList-subsNotFound'>No regular subscriptions found.</div>
                    : (
                      this.regularSubs().map(sub => {
                        return <UserSubscriptionProduct item={sub} key={sub.id} reloadItems={this._reloadItems.bind(this)} />
                      })
                    )
                  }
              </div>
            </div>
          )
        }
        <div className='UserSubscriptionsBox-productsList'>
          <div className='UserSubscriptionsBox-productsList-rtoSubs'>
            <div className='UserSubscriptionsBox-title'>Rent To Own</div>
              { (rtoSubs.length === 0)
                ? <div className='UserSubscriptionsBox-productsList-subsNotFound'>No rent to own subscriptions found.</div>
                : (
                  this.rtoSubs().map(sub => {
                    return <UserRentToOwnProduct item={sub} key={sub.id} reloadItems={this._reloadItems.bind(this)} />
                  })
                )
              }
          </div>
        </div>
      </div>
    )
  }
}

export default UserSubscriptionsBox
