import React from "react";

class NeedHelp extends React.Component {
  render() {
    return (
      <div className='need-help'>
        <div className="content need-help-content">
          <h4 className="header">Need help?</h4>
          <a href="https://help.pluginboutique.com/hc/en-us/sections/6069236066452-Purchasing-Help" target="_blank" rel="noopener noreferrer">Purchasing</a>
          <a href="https://help.pluginboutique.com/hc/en-us/articles/6233291121300-Can-I-get-a-refund-" target="_blank" rel="noopener noreferrer">Refunds</a>
          <a href="https://help.pluginboutique.com/hc/en-us/sections/6069229530644-Free-Gifts" target="_blank" rel="noopener noreferrer">Free Gifts</a>
        </div>
      </div>
    )
  }
}

export default NeedHelp;
