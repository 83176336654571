import React from 'react'
import PropTypes from 'prop-types';
import RatingStars from './RatingStars'
import UserRate from './UserRate'

class UserProductRate extends React.Component {
    static propTypes = {
        order_item: PropTypes.object.isRequired
    };

    render() {
        var rating = this.props.order_item.rating
        return (
            <div className="userproduct-rate">
                { rating.rated ?
                    <RatingStars value={ rating.overall }/> :
                    <UserRate order_item={ this.props.order_item } />
                }
            </div>
        )
    }
}

export default UserProductRate
