import React from 'react'
import PropTypes from 'prop-types';

class UserUpdateMeta extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired
    };

    render() {
        var product = this.props.product
        var category = this.props.product.category
        var manufacturer = this.props.product.manufacturer

        return (
            <div className="userproduct-meta">
                <h3><a href={ product.link_to }>{ product.name }</a></h3>
                <h4><a href={ category.link_to }>{ category.name }</a> by <a href={ manufacturer.link_to }>{ manufacturer.name }</a></h4>
            </div>
        )
    }
}

export default UserUpdateMeta
