import React from 'react'
import DistributorOrderDetailsItem from './DistributorOrderDetailsItem'

class DistributorOrderDetails extends React.Component {
  render() {
      var order = this.props.order,
          items = order.order_items

      return (
          <div className="distributororder-details">
              <div className="distributororder-details-header">
                  <h2 className="dib">Order no. { order.order_id } - Detailed View</h2>
                  { order.void  ? <span className="ttu red ml3">order voided</span> : null }
                  <span className="_close" onClick={ this.props.onClose }>close</span>
              </div>

              <div className="distributororder-details-content">
                  <div className="_detailsdate">
                      <h4>Date &amp; Time Of Purchase</h4>{ order.sold_at }
                  </div>
                  <div className="_detailsemail">
                      <h4>Buyers email</h4>{ order.email }
                  </div>

                  <table>
                      <thead><tr>
                          <th className="_detailsmanufacturer">Manufacturer</th>
                          <th className="_detailsproduct">Product</th>
                          <th className="_detailsprice">Price</th>
                      </tr></thead>
                      <tbody>
                          {
                              items.map((item, idx) =>
                                  <DistributorOrderDetailsItem item={ item } key={ item.order_item_id }/>
                              )
                          }
                      </tbody>
                      <tfoot>
                          <tr>
                              <td></td>
                              <td className="_total">Total Paid</td>
                              <td className="_detailsprice">{ order.total }</td>
                          </tr>
                      </tfoot>
                  </table>

                  <div className="_commission">
                      <div className="_commissionrate">
                          <h4>Commission</h4>{ order.distributor_commission_level + "%" }
                      </div>
                      <div className="_commissionvalue">
                          <h4>Commission Earned</h4>{ order.distributor_commission }
                      </div>
                  </div>
              </div>
          </div>
      )
  }
}

export default DistributorOrderDetails
