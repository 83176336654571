import React from 'react'
import csrfToken from '../src/csrf_token'

class CustomBundleProfits extends React.Component {
    state = {
        custom_bundle: this.props.custom_bundle
    };

    componentDidMount() {
        Mess.subscribe('currency_changed', this.handle_currency_changed)
    }

    handle_currency_changed = (currency) => {
        $.ajax({
            url: '/custom_bundles/' + this.props.custom_bundle.custom_bundle_id + '/price_data.js?currency=' + currency,
            dataType: 'json',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ custom_bundle: data })

            }.bind(this),
            error: function(xhr, status, err) {
                console.error(this.props.url, status, err.toString());
            }.bind(this)
        });
    };

    render() {
        var bundle = this.state.custom_bundle

        return (
            <ul className="bundleprofits">
                <li className="_value">
                    <strong>{ bundle.products_value }</strong>
                    The price of the { bundle.bundle_size } products if bought individually
                </li>
                <li className="_price">
                    <strong>{ bundle.sell_price }</strong>
                    The price of the { bundle.bundle_size } products when bought in a bundle
                </li>
                <li className="_save">
                    <strong> Save { bundle.bundle_discount }</strong>
                    Buy now and download the whole package right away
                </li>
            </ul>

        )
    }
}
export default CustomBundleProfits
