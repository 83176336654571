import React from 'react'
import PromoBoxPaging from './PromoBoxPaging'
import externalLink from '../src/external_link'

class PromoBoxBanners extends React.Component {
    state = { paging_visible: false };

    // componentDidMount: function() {
    //     this.paging_timer = setTimeout(function() {
    //         this.setState({ paging_visible: false })
    //     }.bind(this), 4000)
    // },

    handle_show_paging = () => {
        if (this.props.rotating) {
            clearTimeout(this.paging_timer)
            this.setState({ paging_visible: true })
        }
    };

    handle_hide_paging = () => {
        if (this.props.rotating) {
            // this.paging_timer = setTimeout(function() {
            this.setState({ paging_visible: false })
            // }.bind(this), 4000)
        }
    };

    render() {
        var show_paging = this.props.size > 1,
            clazz = this.props.className + (this.props.rotating ? '' : ' _static'),
            dl_prefix = this.props.data_layer_prefix,
            data_layer = dtl(this.props.data_layer)

        clazz += this.props.move ? ' _move' : ''

      return (
        <div className={ clazz } onMouseEnter={ this.handle_show_paging } onMouseLeave={ this.handle_hide_paging } >
            <ul className="_banners">
                { _.map(this.props.banners, function(item, index) {
                    const addRelAttribute = item.target == 'new' && externalLink(item.link)
                    return (
                        <li key={ index }>
                            <a href={ item.link } target={ item.target == 'new' ? '_blank' : null} rel={ addRelAttribute ? 'noopener noreferrer' : null} data-layer={ data_layer }><img className={ 'space' + item.spaces } src={ item.image }/></a>
                        </li>
                    )
                }) }
            </ul>
            { this.props.size > 1 ? <PromoBoxPaging size={ this.props.size } current={ this.props.current } visible={ this.state.paging_visible } on_change_to={ this.props.on_change_to } /> : null }
        </div>
      )
    }
}

export default PromoBoxBanners
