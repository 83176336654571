import React from 'react'
import UserOrderMeta from './UserOrderMeta'

class UserOrderShort extends React.Component {
  render() {
      return (
          <div className="userorder userorder-short">
              <UserOrderMeta order={this.props.order} />

              <div className="userorder-short-value">
                  { this.props.order.cash }
              </div>

              <a href="#expand" title={ '' } onClick={ this.props.onExpand } className="userorder-expander button-down">Show Details</a>
          </div>
      )
  }
}

export default UserOrderShort
