import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import csrfToken from "../../src/csrf_token";

const cancelSubModalStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '463px',
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
    borderRadius: 'unset',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 0'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '5'
  }
}

export default class ResumeSubscriptionModal extends React.Component {
  state = {
    disableInputs: false
  }

  async confirmResume(){
    const { subscriptionId, closeResumeSubModal, afterActionCallback } = this.props

    this.setState({ disableInputs: true })

    let response
    let responseData

    try {
      response = await fetch(`/subscriptions/resume/${subscriptionId}`, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
        }})
      responseData = await response.json()
    } catch (e) {
      Mess.publish('show_error', "There was an unexpected error while resuming your subscription. Please try again")
      console.error(e)
    }

    if (responseData.success) {
      Mess.publish('show_notice', 'Your subscription will be resumed shortly')
      closeResumeSubModal()
      afterActionCallback && afterActionCallback()
    } else {
      Mess.publish('show_error', responseData && responseData.message || "Couldn't resume your subscription")
      console.error(responseData)
    }

    this.setState({ disableInputs: false })
  }

  render() {
    const { disableInputs } = this.state

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={() => this.props.closeResumeSubModal()}
        style={cancelSubModalStyles}
        ariaHideApp={false}
      >
        <div className='UserRentToOwnProduct-cancelSubModal'>
          <div className='UserRentToOwnProduct-cancelSubModal-header'>
            <div className='UserRentToOwnProduct-cancelSubModal-header-title'>
              Resume Plan
            </div>
            <div className='UserRentToOwnProduct-cancelSubModal-header-product'>
              <div className='UserRentToOwnProduct-cancelSubModal-header-product-text'>
                Product:
              </div>
              <div className='UserRentToOwnProduct-cancelSubModal-header-product-name'>
                {this.props.productName}
              </div>
            </div>
          </div>
          <div className='UserRentToOwnProduct-cancelSubModal-body'>
            <div className='UserRentToOwnProduct-cancelSubModal-body-title'>
              What happens when you resume your plan
            </div>

            <ul className='UserRentToOwnProduct-cancelSubModal-body-list'>
              <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
                Your payments will resume immediately.
              </li>
              <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
                Access to your software will be resumed as soon as the payment is processed.
              </li>
            </ul>

            <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
              <div className='UserRentToOwnProduct-cancelSubModal-body-buttons-keepBtn' onClick={() => this.props.closeResumeSubModal()}>
                Keep Pause
              </div>
              <button disabled={disableInputs} onClick={this.confirmResume.bind(this)} className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn'>
                Resume
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

ResumeSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeResumeSubModal: PropTypes.func.isRequired,
  afterActionCallback: PropTypes.func,
  productName: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired
}
