import React from 'react';


class SearchFilter extends React.Component {
  handle_clean_pc = () => {
      $(this.refs.pc).prop('checked', false)
      $(this.refs.mac).prop('checked', false)

  };

  handle_clean_mac = () => {
      $(this.refs.mac).prop('checked', false)
      $(this.refs.pc).prop('checked', false)

  };

  render() {
      return (
          <ul className="searchfilter">
              <input type="hidden" name="oses[]" value=""/>
              <li className="_mac">
                  <input type="checkbox" name="oses[]" value="mac" id="searchfilter-oses-mac" defaultChecked={ this.props.filter.mac } onChange={ this.props.onChange } ref="mac"/>
                  <label htmlFor="searchfilter-oses-mac" onClick={ this.handle_clean_pc } data-layer={dtl('search/:mac')}>Mac</label>
              </li>

              <li className="_pc">
                  <input type="checkbox" name="oses[]" value="pc" id="searchfilter-oses-pc" defaultChecked={ this.props.filter.pc } onChange={ this.props.onChange } ref="pc"/>
                  <label htmlFor="searchfilter-oses-pc" onClick={ this.handle_clean_mac } data-layer={dtl('search/:pc')}>PC</label>
              </li>

              <li className="_free">
                  <input type="checkbox" name="free" value="true" id="searchfilter-free" defaultChecked={ this.props.filter.free } onChange={ this.props.onChange } data-layer={dtl('search/:free')}/>
                  <label htmlFor="searchfilter-free">Free</label>
              </li>
          </ul>
      )
  }
}

export default SearchFilter
