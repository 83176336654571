import React from 'react'
import PropTypes from 'prop-types';
import csrfToken from '../../src/csrf_token'
import Select from 'react-select'
import _ from '../../src/lodash'

class ShippingInfo extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      first_name: this.props.address.first_name,
      last_name: this.props.address.last_name,
      phone_number: this.props.address.phone_number,
      phone_prefix: this.props.address.phone_prefix || this.props.address.country,
      address_1: this.props.address.address_1,
      address_2: this.props.address.address_2,
      postal_code: this.props.address.postal_code,
      city: this.props.address.city,
      province: this.props.address.province,
      country: this.props.address.country,
      full_address: null,
      autocomplete: this.shouldRenderAutocomplete.bind(this),
      company_name: this.props.address.company_name,
      vat_number: this.props.address.vat_number,
      store: this.props.address.store,
      errors: this.props.errors,
      dark_theme: this.props.dark_theme
    }

    this.handleChange = this.handleChange.bind(this);
    this.manuallyEnterAddress = this.manuallyEnterAddress.bind(this);
    this.resetShippingInfo = this.resetShippingInfo.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.openManualEnterAdress = this.openManualEnterAdress.bind(this)
  }

  validateInputs() {
    const fieldsWithPresence = ['postal_code', 'address_1', 'city', 'country', 'province']
    const fieldsWithMaxLength = [...fieldsWithPresence, 'address_2']

    let fieldsWithErrors = {}

    fieldsWithPresence.map(field => {
      if ((this.state[field] === null) || (this.state[field] === '')) {
        fieldsWithErrors[field] = 'This information is required'
      }
    })

    fieldsWithMaxLength.forEach(field => {
      if ((this.state[field] ?? '').length > 255) {
        fieldsWithErrors[field] = 'This information is too long (maximum is 255 characters)'
      }
    })

    this.setState({
      errors: [fieldsWithErrors]
    })

    return fieldsWithErrors
  }

  shouldRenderAutocomplete() {
    if(this.state.errors && this.state.errors[1]) {
      return !['address_1', 'address_2', 'postal_code', 'city', 'province', 'country'].some((val) => this.state.errors[1][val])
    }
    return true
  }

  errorFor(field) {
    if(this.state.errors && this.state.errors[0]) {
      return this.state.errors[0][field];
    }
  }

  componentDidMount () {
    let thus = this;
    pca.on("load", function(type, id, control) {
      pca.on("options", function(type, key, options) {
        options.suppressAutocomplete = false;
      });

      control.listen("populate", function(address) {
        if(this.autocomplete.field.id.includes('shipping')) {
          thus.setState({
            full_address: address.Label.replace(/\n/g, ', '),
            address_1: address.Line1,
            address_2: address.Line2,
            postal_code: address.PostalCode,
            city: address.City,
            province: address.ProvinceName,
            country: address.CountryIso2
          });
        };
      });
    });
  }

  handleChange(event, attr_name) {
    const object = {}

    object[attr_name] = event.target ? event.target.value : event.value

    if(attr_name === 'store'){
      object['store'] = event.target.checked
    }

    this.setState(object);
  }

  openManualEnterAdress() {
    this.setState({ autocomplete: false })
  }

  manuallyEnterAddress(event) {
    event.preventDefault();
    this.setState({ autocomplete: !this.state.autocomplete });
  };

  resetShippingInfo(event) {
    event.preventDefault();
    this.setState({ autocomplete: true, full_address: null });

    const autocompleteElement = `${this.props.addressOwner}_${this.props.kind}_info_attributes_address_lookup`

    setTimeout(function(){
      document.getElementById(autocompleteElement).focus();
      document.getElementById(autocompleteElement).select();
    }, 250);
  }

  prefixNumber() {
    return this.props.countriesTelephonePrefixes.find(element => element[1] === this.state.phone_prefix)[2]
  }

  textInput(label_text, attr_name, attr_value) {
    const inputClassName = this.errorFor(attr_name) ? 'red-border' : ''

    return (
      <p className="address-input-wrapper">
        <label className="label-address" htmlFor={`${this.props.kind}_${attr_name}`} dangerouslySetInnerHTML={{ __html: _.unescape(label_text) }}>
        </label>

        { this.errorFor(attr_name) && (<div class="error-container"><div className="error-text">{ this.errorFor(attr_name) }</div></div>) }

        <input
               className={inputClassName + " input-address"}
               type="text"
               value={attr_value}
               autoComplete="off"
               name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][${attr_name}]`}
               id={`${this.props.addressOwner}_${this.props.kind}_info_attributes_${attr_name}`}
               onChange={(event) => this.handleChange(event, attr_name)} />
      </p>
    )
  }

  isExpandedFormErrorsPresent(errors) {
    const expandedFields = ['postal_code', 'address_1', 'city', 'country', 'province']

    return errors[0] ? !_.isEmpty(_.intersection(expandedFields, Object.keys(errors[0]))) : false
  }

  renderAddressAutocomplete() {
    const addressLookupErrorsPresent = this.isExpandedFormErrorsPresent(this.state.errors)
    const inputErrorClassName = addressLookupErrorsPresent ? 'red-border' : ''

    return (
      <div className={ this.state.autocomplete ? '' : 'dn' }>
        <p>
          <span className="address-autocomplete-box">
            <label className="label-address label-autocomplete-box" htmlFor={`${this.props.kind}_address_lookup`}>
              Shipping Address Lookup:&nbsp;
              <span class="red-asterisk">*</span>
            </label>
          </span>

          { addressLookupErrorsPresent && (<div class="error-container"><div className="error-text">{ 'This information is required' }</div></div>) }

          <input
                  className={ `${inputErrorClassName} input-address` }
                  type="text"
                  autoComplete="off"
                  placeholder="Start typing your address"
                  disabled={this.state.full_address}
                  value={this.state.full_address}
                  id={`${this.props.addressOwner}_${this.props.kind}_info_attributes_address_lookup`}/>

        </p>
        { (this.state.full_address || !this.props.address['new_record?']) && (<div className="full-address">
          <div>{ this.state.address_1 }</div>
          <div>{ this.state.address_2 }</div>
          <div>{ this.state.postal_code }&nbsp;{ this.state.city }</div>
          <div>{ this.state.province }</div>
          <div>{ this.countryName() }</div>
          <hr/>
          <a href="#" onClick={ this.resetShippingInfo }>Edit</a>
        </div>)
        }
        <a className="enter-manually" href="#" onClick={ this.manuallyEnterAddress }>Or Manually enter Shipping address</a>
      </div>
    )
  }

  renderManualAddressCompletion() {
    return (
      <div className={ this.state.autocomplete ? 'dn' : '' }>
        <span className={ this.state.autocomplete ? 'address-autocomplete-box' : 'address-autocomplete-box label-autocomplete-box' }>
          <a href="#" onClick={ this.manuallyEnterAddress }>Back to Auto Address lookup</a>
        </span>

        { this.textInput('Postal Code/Zip Code: <span class="red-asterisk">*</span>', 'postal_code', this.state.postal_code) }

        { this.textInput('Address 1: <span class="red-asterisk">*</span>', 'address_1', this.state.address_1) }

        { this.textInput('Address 2: ', 'address_2', this.state.address_2) }

        { this.textInput('City: <span class="red-asterisk">*</span>', 'city', this.state.city) }

        { this.renderProvinceSelect() }

        { this.renderCountryInput() }
      </div>
    )
  }

  renderCountryInput(){
    const options = this.props.countriesList.map((val, index) => {
      let labelImg = (<span dangerouslySetInnerHTML={{ __html: `<img class="country_flag" src="${require(`../../images/flags/${val[0].toLowerCase()}.svg`)}" alt="${val[1]} flag"><span class="country_label">${val[1]}</span>` }}/>)
      return { value: val[0], label: labelImg, search: val[1] }
    });

    const filter = (option, searchText) => {
      return option.data.search.toLowerCase().includes(searchText.toLowerCase())
        || option.data.value.toLowerCase().includes(searchText.toLowerCase())
    }

    const normalBorderColor = this.state.dark_theme ? '#525252' : '#8c8c8c'
    const errorBorderColor = this.state.dark_theme ? '#ff4e08' : '#d45e69'
    const borderStyles = this.errorFor('country') ? `${errorBorderColor} !important` : normalBorderColor

    const styles = {
      control: (provided, state) => ({
        ...provided,
        minHeight: '29px',
        height: '29px',
        borderColor: borderStyles,
        fontSize: '14px'
      }),
      menu: (provided, state) => ({
        ...provided,
        width: '100%'
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '0 3px',
        display: 'none'
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none'
      }),
    }

    const dark_styles = {
      ...styles,
      control: (provided, state) => ({
        ...provided,
        minHeight: '29px',
        height: '29px',
        fontSize: '14px',
        borderColor: borderStyles,
        backgroundColor: '#242424'
      }),
      menu: (provided, state) => ({
        ...provided,
        width: '100%',
        backgroundColor: '#3b3b3b'
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? 'black' :'#ffffff',
        backgroundColor: state.isSelected ? '#01ff95' : '#3b3b3b',
        '&:hover': {
          backgroundColor: '#01ff95',
          color: 'black'
        }
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: '#a8a8a8'
      }),
      input: (provided, state) => ({
        ...provided,
        color: '#a8a8a8'
      })
    }

    return (
      <p>
        <label className="label-address" htmlFor={`${this.props.kind}_country`}>
          Country: <span className="red-asterisk">*</span>
        </label>
        <div className="country-box">
          { this.errorFor('country') && (<div class="error-container"><div className="error-text">{ this.errorFor('country') }</div></div>) }
          <Select
            filterOption={filter}
            className={"country_select"}
            name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][country]`}
            value={ options.find(element => element.value === this.state.country) }
            onChange={(event) => this.handleChange(event, 'country')}
            options={options}
            styles={this.state.dark_theme ? dark_styles : styles}
          />
        </div>
      </p>
    )
  }

  renderProvinceSelect(){
    if(!['US', 'CA'].includes(this.state.country)){
      return this.textInput('County / State: <span class="red-asterisk">*</span>', 'province', this.state.province)
    } else {
      const list = this.state.country === 'US' ? this.props.usStatesList : this.props.canadaStatesList
      const options = list.map((val, index) => {
        return { value: val[1].name, label: val[1].name, search: val[1].name }
      });

      const filter = (option, searchText) => {
        return option.data.search.toLowerCase().includes(searchText.toLowerCase())
          || option.data.value.toLowerCase().includes(searchText.toLowerCase())
      }

      const normalBorderColor = this.state.dark_theme ? '#525252' : '#8c8c8c'
      const errorBorderColor = this.state.dark_theme ? '#ff4e08' : '#d45e69'
      const borderStyles = this.errorFor('province') ? `${errorBorderColor} !important` : normalBorderColor

      const styles = {
        control: (provided, state) => ({
          ...provided,
          minHeight: '29px',
          height: '29px',
          borderColor: borderStyles,
          fontSize: '14px'
        }),
        menu: (provided, state) => ({
          ...provided,
          width: '100%'
        }),
        dropdownIndicator: (provided, state) => ({
          ...provided,
          padding: '0 3px',
          display: 'none'
        }),
        indicatorSeparator: (provided, state) => ({
          ...provided,
          display: 'none'
        }),

      }

      const dark_styles = {
        ...styles,
        control: (provided, state) => ({
          ...provided,
          minHeight: '29px',
          height: '29px',
          fontSize: '14px',
          borderColor: borderStyles,
          backgroundColor: '#242424'
        }),
        menu: (provided, state) => ({
          ...provided,
          width: '100%',
          backgroundColor: '#3b3b3b'
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'black' :'#ffffff',
          backgroundColor: state.isSelected ? '#01ff95' : '#3b3b3b',
          '&:hover': {
            backgroundColor: '#01ff95',
            color: 'black'
          }
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: '#a8a8a8'
        }),
        input: (provided, state) => ({
          ...provided,
          color: '#a8a8a8'
        })
      }

      return (
        <p>
          <label className="label-address" htmlFor={`${this.props.kind}_province`}>
            County / State: <span class="red-asterisk">*</span>
          </label>
          <div className="province-box">
            { this.errorFor('province') && (<div class="error-container"><div className="error-text">{ this.errorFor('province') }</div></div>) }
            <Select
              filterOption={filter}
              className='province_select'
              name={`${this.props.addressOwner}[${this.props.kind}_info_attributes][province]`}
              value={ options.find(element => element.value === this.state.province) }
              onChange={(event) => this.handleChange(event, 'province')}
              options={options}
              styles={this.state.dark_theme ? dark_styles : styles}
            />
          </div>
        </p>
      )
    }
  }

  renderFullForm() {
    return (
      <div>
        { this.renderAddressAutocomplete() }
        { this.renderManualAddressCompletion() }
      </div>
    )
  }

  countryName() {
    return this.state.country && this.props.countriesList.find(el => el[0] === this.state.country)[1]
  }

  renderReadOnlyView() {
    return (
      <div>
        <label className="label-address">Ship to:</label>
        <div className="full-address">
          <div>{ this.state.first_name } { this.state.last_name }</div>
          <div>{ this.state.phone_number ? this.prefixNumber() : '' } { this.state.phone_number }</div>
          <div>{ this.state.address_1 }</div>
          <div>{ this.state.address_2 }</div>
          <div>{ this.state.postal_code }&nbsp;{ this.state.city }</div>
          <div>{ this.state.province }</div>
          <div>{ this.countryName() }</div>
          <hr/>
          <a href="/checkout/transition?transition=edit_billing_info">Edit</a>
        </div>
      </div>
    )
  }

  render() {
    return this.props.readOnly ? this.renderReadOnlyView() : this.renderFullForm()
  }
}

ShippingInfo.propTypes = {
  address: PropTypes.object.isRequired,
  readOnly: PropTypes.boolean
}

export default ShippingInfo
