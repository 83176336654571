import React from 'react'
import PropTypes from 'prop-types';
import UserRateDetailsSection from './UserRateDetailsSection'
import csrfToken from '../src/csrf_token'

class UserRateDetails extends React.Component {
    static propTypes = {
        order_item_id: PropTypes.number.isRequired
    };

    state = { visible: false };

    componentDidMount() {
        Mess.subscribe('show_rating_form', this.handle_show_form)
    }

    handle_show_form = (orderItemId) => {
        if (!orderItemId || this.props.order_item_id == orderItemId) {
            this.setState({ visible: true })
        }
    };

    handleCancel = (event) => {
        event.stopPropagation()
        event.preventDefault()

        this.setState({ visible: false })
    };

    radio_value = (elements) => {
        for (var i = 0; i < elements.length; i++) {
            if (elements[i].checked) {
                return elements[i].value
            }
        }
    };

    handle_submit = (event) => {
        event.stopPropagation()
        event.preventDefault()

        var form = this.refs.ratingform
        var data = {
            order_item_id: this.props.order_item_id,
            sound_quality: this.radio_value( form.elements['sound_quality'] ),
            value_for_money:this.radio_value( form.elements['value_for_money'] ),
            ease_of_use: this.radio_value( form.elements['ease_of_use'] ),
            features: this.radio_value( form.elements['features'] ),
            presets: this.radio_value( form.elements['presets'] )
        }

        $.ajax({
            url: '/ratings',
            dataType: 'json',
            method: 'post',
            data: { rating: data },
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                if (data.error) {
                    Mess.publish('show_error', data.error)
                    if (data.redirect){
                      window.location = data.redirect
                    }
                } else {
                    Mess.publish('show_notice', data.notice)
                    Mess.publish('refresh_rating', data.order_item)
                    this.setState({ visible: false })
                }

            }.bind(this),

            error: function(xhr, status, err) {
                // console.error(this.props.url, status, err.toString());
            }.bind(this)
        });
    };

    render() {

        return ( this.state.visible ?
            <div className="userrate-details">
                <form ref="ratingform" onSubmit={ this.handle_submit }>
                    <UserRateDetailsSection title="Sound Quality" name="sound_quality" order_item_id={ this.props.order_item_id } />
                    <UserRateDetailsSection title="Value For Money" name="value_for_money" order_item_id={ this.props.order_item_id } />
                    <UserRateDetailsSection title="Ease Of Use" name="ease_of_use" order_item_id={ this.props.order_item_id } />
                    <UserRateDetailsSection title="Features" name="features" order_item_id={ this.props.order_item_id } />
                    <UserRateDetailsSection title="Presets" name="presets" order_item_id={ this.props.order_item_id } />

                    <div className="action">
                        <a href="#cancel" title="Cancel rating this product" onClick={ this.handleCancel } className="button-plain">Cancel</a>
                        <input type="submit" value="Submit" className="button-plain" />
                    </div>

                </form>
            </div> :
            null
        )
    }
}

export default UserRateDetails
