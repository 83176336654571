import React from 'react'
import PropTypes from 'prop-types'

class BPSFaqItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false
    }
  }

  icon(isOpen) {
    if (isOpen) {
      return (
        <span className='BPSFaqItem-title-iconMinus'></span>
      )
    } else {
      return(
        <span className='BPSFaqItem-title-iconPlus'></span>
      )
    }
  }

  render() {
    const isOpen = this.state.isOpen

    return (
      <div className='BPSFaqItem'>
        <div className='BPSFaqItem-title' onClick={() => this.setState({ isOpen: !isOpen })}>
          <span className='BPSFaqItem-title-text'>{this.props.title}</span>
          {this.icon(isOpen)}
        </div>

        { isOpen && (
          <div className='BPSFaqItem-content'>
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}

BPSFaqItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
}

export default BPSFaqItem
