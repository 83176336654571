import React from 'react'
import Modal from 'react-modal'
import csrfToken from "../../src/csrf_token";
import PibSelectRadio from "../common/PibSelectRadio";

const cancelSubModalStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '463px',
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
    borderRadius: 'unset',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 0'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '5'
  }
}

class CancelSubscriptionModal extends React.Component {
  state = {
    phase: 0,
    reason: "",
    reasonComment: "",
    error: '',
    disableInputs: false
  }

  cancelReasonChanged = (value) => {
    this.setState({ reason: value })
  }

  cancelReasonCommentChanged = (event) => {
    this.setState({ reasonComment: event.target.value })
  }

  previousPhase = () => {
    this.setState({ phase: this.state.phase - 1 })
  }

  nextPhase = () => {
    this.setState({ phase: this.state.phase + 1 })
  }

  async confirmCancel() {
    this.setState({ error: '' })

    if (this.state.reason.trim().length < 1) {
      this.setState({ error: 'Please select one of the reasons' })
      return
    }

    const { id: subscriptionId } = this.props.subscription
    const { reason, reasonComment, phase } = this.state

    this.setState({ disableInputs: true })

    let response
    let responseData
    const data = { reason: reason, reason_comment: reasonComment }

    try {
      response = await fetch(`/subscriptions/cancel/${subscriptionId}`, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          "X-CSRF-Token": csrfToken(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
      })
      responseData = await response.json()
    } catch (e) {
      Mess.publish('show_error', "There was an unexpected error while canceling your subscription. Please try again")
      console.error(e)
    }

    if (responseData && responseData.success) {
      this.setState({ phase: phase + 1 })
    } else if (responseData) {
      Mess.publish('show_error', responseData && responseData.message || "Couldn't cancel your subscription")
      console.error(responseData)
    }

    this.setState({ disableInputs: false })
  }

  onRequestClose = () => {
    const { closeCancelSubModal, afterActionCallback } = this.props
    const { phase } = this.state

    closeCancelSubModal()
    phase === 2 && afterActionCallback && afterActionCallback()
    this.setState({ phase: 0 })
  }

  render() {
    const { disableInputs, phase, reason, reasonComment } = this.state
    const { nextBillingDate, productName, isOpen, isPaused } = this.props

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.onRequestClose}
        style={cancelSubModalStyles}
        ariaHideApp={false}
      >
        <div className='UserRentToOwnProduct-cancelSubModal'>
          <div className='UserRentToOwnProduct-cancelSubModal-header'>
            <div className='UserRentToOwnProduct-cancelSubModal-header-title'>
              { phase === 2 ? 'Your plan is cancelled' : 'Cancel Plan'}
            </div>
            <div className='UserRentToOwnProduct-cancelSubModal-header-product'>
              <div className='UserRentToOwnProduct-cancelSubModal-header-product-text'>
                Product:
              </div>
              <div className='UserRentToOwnProduct-cancelSubModal-header-product-name'>
                {productName}
              </div>
            </div>
          </div>

          { phase === 0 && !isPaused &&  <CancelPhaseOne closeCancelSubModal={this.onRequestClose}
                                           nextBillingDate={nextBillingDate}
                                           nextPhase={this.nextPhase}
                                           subscription={this.props.subscription}/> }

          { phase === 0 && isPaused && <CancelPhaseOnePaused closeCancelSubModal={this.onRequestClose} nextPhase={this.nextPhase} /> }

          { phase === 1 && <CancelPhaseTwo cancelReasonChanged={this.cancelReasonChanged}
                                           cancelReasonCommentChanged={this. cancelReasonCommentChanged}
                                           confirmCancel={this.confirmCancel.bind(this)}
                                           disabled={disableInputs}
                                           reason={reason}
                                           reasonComment={reasonComment}
                                           previousPhase={this.previousPhase}
                                           subscription={this.props.subscription}
                                           error={this.state.error} /> }

          { phase === 2 && <CancelPhaseThree closeCancelSubModal={this.onRequestClose}
                                             isPaused={isPaused}
                                             subscription={this.props.subscription} /> }
        </div>
      </Modal>
    )
  }
}

const CancelPhaseOne = ({ closeCancelSubModal, nextPhase, nextBillingDate, subscription }) => (
  <div className='UserRentToOwnProduct-cancelSubModal-body'>
    <div className='UserRentToOwnProduct-cancelSubModal-body-title'>
      What happens when you cancel your plan
    </div>

    <ul className='UserRentToOwnProduct-cancelSubModal-body-list'>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        Your payments will stop immediately.
      </li>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        Your software will work until the end of your billing cycle, after which you will lose access.
      </li>
      { !subscription.is_rto && <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        If you are cancelling on trial, you will not receive any free gifts related to your subscription.
      </li> }
    </ul>
    <div className='UserRentToOwnProduct-cancelSubModal-body-nextBilling'>
      {`Your billing cycle ends on ${nextBillingDate}`}
    </div>
    <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
      <div className='UserRentToOwnProduct-cancelSubModal-body-buttons-keepBtn' onClick={closeCancelSubModal}>
        Keep Plan
      </div>
      <button className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn' onClick={nextPhase}>
        Continue
      </button>
    </div>
  </div>
)

const CancelPhaseOnePaused = ({ closeCancelSubModal, nextPhase }) => (
  <div className='UserRentToOwnProduct-cancelSubModal-body'>
    <div className='UserRentToOwnProduct-cancelSubModal-body-title'>
      What happens when you cancel your plan
    </div>

    <ul className='UserRentToOwnProduct-cancelSubModal-body-list'>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        Your plan will be expired immediately.
      </li>
      <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
        You will lose access to your software from Beatport Studio plan.
      </li>
    </ul>
    <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
      <div className='UserRentToOwnProduct-cancelSubModal-body-buttons-keepBtn' onClick={closeCancelSubModal}>
        Keep Plan
      </div>
      <button className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn' onClick={nextPhase}>
        Continue
      </button>
    </div>
  </div>
)

const CancelPhaseTwo = ({ reason, reasonComment, cancelReasonChanged, cancelReasonCommentChanged, previousPhase, confirmCancel, disabled = false, error, subscription }) => (
  <div className="UserRentToOwnProduct-cancelSubModal-body">
    <div className='UserRentToOwnProduct-cancelSubModal-body-title'>
      Would you mind telling us why you want to cancel?
    </div>

    {
      error.length > 0 && (
        <div className='UserRentToOwnProduct-cancelSubModal-body-error'>
          Please select one of the reasons
        </div>
      )
    }

    <PibSelectRadio
      disabled={disabled}
      noInitialValue={!subscription.is_rto}
      onChange={cancelReasonChanged}
      selectedValue={reason}
      options={[
        { label: "I'm not making music right now", value: "I'm not making music right now" },
        { label: "I am having technical problems", value: "I am having technical problems" },
        { label: "I can't afford it right now", value: "I can't afford it right now" },
        { label: "I am using another service", value: "I am using another service" },
        { label: "I bought or plan on buying this software elsewhere", value: "I bought or plan on buying this software elsewhere" }
      ]}
    />

    <textarea onChange={cancelReasonCommentChanged} name="cancel_comment" id="cancel_comment"
              className="UserRentToOwnProduct-cancelSubModal-commentTextArea"
              cols="30" rows="10" placeholder="Your comment" defaultValue={reasonComment}></textarea>

    <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
      <div className='UserRentToOwnProduct-cancelSubModal-body-buttons-keepBtn' onClick={previousPhase}>
        Back
      </div>
      <button disabled={disabled} onClick={confirmCancel} className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn'>
        Confirm Cancel
      </button>
    </div>
  </div>
)

const CancelPhaseThree = ({ closeCancelSubModal, subscription, isPaused }) => (
  <div className="UserRentToOwnProduct-cancelSubModal-body">
    <ul className='UserRentToOwnProduct-cancelSubModal-body-list'>
      {
        subscription.is_rto ? (
          <>
            <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
              You won’t be able to use the software
            </li>
            { !isPaused && <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
              You can activate plan in Your Account page
            </li> }
            <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
              Your payment has stopped
            </li>
          </>
        ) : (
          <>
            <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
              { isPaused ? 'You will lose access to Beatport Studio' : 'You will lose access to Beatport Studio at the end of your billing cycle' }
            </li>
            <li className='UserRentToOwnProduct-cancelSubModal-body-list-item'>
              Your payments have stopped
            </li>
          </>
        )
      }
    </ul>

    <div className='UserRentToOwnProduct-cancelSubModal-body-buttons'>
      <button onClick={closeCancelSubModal} className='UserRentToOwnProduct-cancelSubModal-body-buttons-cancelBtn wide'>
        Dismiss
      </button>
    </div>
  </div>
)

export default CancelSubscriptionModal
