import React from 'react'

const WelcomeMessage = ({ message }) => (
  <div className="WelcomeMessage">
    <div className="rcw-client rcw-client-prompt">
      <div className="rcw-message-text rcw-message-text-prompt" dangerouslySetInnerHTML={{ __html: message }}></div>
    </div>
  </div>
)

export default WelcomeMessage
