import React from 'react'

class ProcessLine extends React.Component {
    render() {
        var clazz = 'pager' + (this.props.processing || this.props.progress ? ' _processing' : '')
        return (
            <div className={ clazz }>
                { this.props.processing || this.props.progress ? 'fetching...' : 'stand by'}
            </div>
        )
    }
}



export default ProcessLine
