import React from 'react'

class AudioButton extends React.Component {
  state = {
    playing: false,
    progress: 0,
    showSpinner: false
  };

  componentDidMount() {
    var audio = this.refs.audio

    if (audio) {
      audio.addEventListener('ended', this.handle_media_end)

      audio.addEventListener('playing', this.remove_spinner)

      Mess.subscribe('stop_audio_buttons', this.handle_force_stop)
    }
  }

  componentWillUnmount() {
    var audio = this.refs.audio

    if (audio) {
      audio.removeEventListener('ended', this.handle_media_end);
    }
  }

  remove_spinner = () => {
    this.setState({
      showSpinner: false
    })
  }

  update_progress = (event) => {
    var audio = this.refs.audio;

    var progress = (audio.currentTime / audio.duration) * 100

    this.setState({ progress: progress })
  };

  handle_media_end = (event) => {
    this.setState({ playing: false })
  };

  handle_play_pause = (event) => {
    event.stopPropagation()

    var audio = this.refs.audio,
      playing = this.state.playing

    if (!audio) {
      return
    }
    if (playing) {
      audio.pause()

    } else {
      // audio.load()
      Mess.publish('stop_audio_buttons')
      audio.play()

      this.setState({
        showSpinner: true
      })
    }

    this.setState({ playing: !playing })
  };

  handle_force_stop = () => {
    var audio = this.refs.audio,
      playing = this.state.playing

    if (playing && audio) {
      audio.pause()
    }

    this.setState({ playing: false })
  };


  play_pause_or_spinner = () => {
    if (this.state.showSpinner === true) {
      return <div className='audiobutton-loader' />
    } else {
      return <button onClick={ this.handle_play_pause } >{ this.state.playing ? 'pause' : 'play' }</button>
    }
  }

  render() {
    var clazz = 'audiobutton' + ( this.state.playing ? ' _playing' : '')

    return ( this.props.src ?
      <div className={ clazz }>
        <audio ref="audio">
          <source src={ this.props.src }/>
        </audio>

        {this.play_pause_or_spinner()}
      </div> :
      null
    )
  }
}

export default AudioButton
