import React from 'react'
import NavigationLinks from './NavigationLinks'

class NavigationWithoutMenu extends React.Component {
  render() {

      if (!(this.props.item && this.props.item.children)) {
          return null
      }

      return (
          <div className={ 'navigation-board --nomenu navigationmenu-' + this.props.item.id } onClick={ this.props.onClose }>
              <div className="navigation-content">
                  <NavigationLinks item={ this.props.item } cols="6" />
              </div>
          </div>
      )
  }
}
export default NavigationWithoutMenu
