import React from 'react'

class MenuActivator extends React.Component {
    handleClick = () => {
        Mess.publish('toggle_menu')
    };

    render() {
        return (
            <a className="topbar-hamburger" href="#" title="Hide menu" onClick={ this.handleClick }>menu</a>
        )
    }
}
export default MenuActivator
