import React from 'react'

const InvalidQuery = ({ message }) => {
  return(
    <div className="InvalidQuery">
      <div className="rcw-client rcw-client-prompt">
        <div className="rcw-message-text rcw-message-text-prompt">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
      </div>
    </div>
  )
}

export default InvalidQuery
