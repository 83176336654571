import React from 'react'
import csrfToken from '../src/csrf_token'

class CategorySamples extends React.Component {

  state = {
    processing: true,
    data: null
  }

  componentDidMount = () => {
    this.xhr = $.ajax({
      url: `/categories/${this.props.id}/samples?free=${this.props.free}`,
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      // dataType: 'json',
      success: (data) => {
        this.setState({
          processing: false,
          data: data,
        })
        window.setTimeout(ReactRailsUJS.mountComponents(), 500)
      },
      error: () => this.setState({ processing: false })
    })
  }

  render() {
    const { processing, data } = this.state

    if (processing) {
      return (
        <div className="flex justify-center h2"><span className="progress pr4"></span></div>
      )
    }

    return (
      <div className="samplebox-samples" dangerouslySetInnerHTML={{__html: data }}></div>
    )
  }
}

export default CategorySamples
