import React from 'react'
import PropTypes from 'prop-types'

const BuyBoxGiftsList = ({ gifts }) => {
  if (gifts.length <= 0) return null

  return (
    <div className="buybox-footer">
      <div className="buybox-footer-text">
        <div className="buybox-footer-text-value">
          Buy and get one
        </div>
        <div className="buybox-footer-text-icon">
        </div>
      </div>

      <div className="buybox-footer-giftsList">
        {
          _.map(gifts, (gift) => (
            <a
              key={gift.product.name}
              href={gift.product.link_to}
              target="_blank"
              rel="noreferrer"
              className="buybox-footer-giftsList-item">
              <span className="buybox-footer-giftsList-item-dot"></span>{gift.product.name}
            </a>
          ))
        }
      </div>
    </div>
  )
}

BuyBoxGiftsList.propTypes = {
  gifts: PropTypes.array
}

export default BuyBoxGiftsList
