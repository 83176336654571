import React from 'react'
import UserProductMeta from './UserProductMeta'
import UserCoursePlay from './UserCoursePlay'
import UserCourseExpander from './UserCourseExpander'

class UserCourseShort extends React.Component {
    render() {
        return (
            <div className="userproduct userproduct-short">
                <a name={ 'item-' + this.props.order_item.course.course_id } title={ this.props.order_item.course.name }></a>
                <UserProductMeta product={ this.props.order_item.course } />
                <UserCoursePlay order_item={ this.props.order_item} expanded={ false } />
                <UserCourseExpander name={ this.props.order_item.course.name } onClick={ this.props.onExpand }/>
            </div>
        )
    }
}

export default UserCourseShort
