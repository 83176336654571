import React from 'react'
import ProcessLine from './ProcessLine'
import csrfToken from '../src/csrf_token'

class SamplepacksUse extends React.Component {
    state = { visible: true, processing: false };

    handle_use = () => {

        this.setState({ processing: true })

        if (this.xhr) {
            this.xhr.abort()
        }

        this.xhr = $.ajax({
            url: this.props.samplepack.action_url,
            dataType: 'json',
            method: 'POST',
            headers: {
              "X-CSRF-Token": csrfToken()
            },
            success: function(data) {
                this.setState({ processing: false })
                Mess.publish('show_flash', data)

                Mess.publish('refresh_wallet')
                Mess.publish('refresh_samplepacks_download')
                Mess.publish('refresh_samplepacks')
                Mess.publish('refresh_coupons')
                Mess.publish('refresh_extra_vc')
            }.bind(this),
            error: function(xhr, status, err) {
                this.setState({ processing: false })

            }.bind(this)
        });

    };

    render() {
        var has_samplepack = this.props.samplepack.has_samplepack,
            has_tokens = this.props.samplepack.has_tokens,
            reward_tokens = this.props.samplepack.reward_tokens

        return (
            <div className="samplepack-details-use">
                <p>
                    By clicking the Download button you acknowledge that you have
                    the required software to use this product as specified above.
                    No exchanges or returns on Rewards+ gifts available.
                </p>
                {
                    has_samplepack ?
                    <span className="_has">You already have it</span> :
                    (
                        has_tokens ?
                        <button onClick={ this.handle_use }><em>{ reward_tokens }</em>Select for Download</button> :
                        <button className="_notokens"><em>{ reward_tokens }</em>Not Available</button>
                    )
                }
                <ProcessLine progress={ this.state.processing } />
            </div>
        )
    }
}

export default SamplepacksUse
