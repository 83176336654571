import React from 'react'

class GalleryHeader extends React.Component {
  render() {
      return (
          <h2 className="gallery-header">{ this.props.title }</h2>
      )
  }
}

export default GalleryHeader
