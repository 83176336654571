import React from 'react'
import PropTypes from 'prop-types';
import UserTrialDownloadOses from './UserTrialDownloadOses'

class UserTrialDownload extends React.Component {
  static propTypes = {
      trial: PropTypes.object.isRequired
  };

  state = {
      pc: this.props.trial.os.current_pc,
      progress: false
  };

  handleOsChnage = (pc) => {
      this.setState({ pc: pc })
  };

  render() {
      var downloads = this.state.pc ? this.props.trial.downloads.pc : this.props.trial.downloads.mac

      var clazz = 'usertrial-download' + (this.state.progress ? ' --progress' : '')

      return (
          <div className={clazz}>
              <a name={ 'trial-' + this.props.trial.trial_id } title={ '' }></a>
              <UserTrialDownloadOses onChange={ this.handleOsChnage } trial={ this.props.trial } pc={ this.state.pc }/>
              <div className="usertrial-download-actions">
                  {
                      _.map(downloads, function(item, idx) {
                          return (
                              // item.url could return external domain so in this case add rel="noopener noreferrer"
                              <a href={ item.url } key={ 'item' + idx} title={ '' } className="button-download" target="_blank">Download Trial</a>
                          )
                      }.bind(this))
                  }
              </div>
          </div>
      )
  }
}

export default UserTrialDownload
