import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

const licenseModalStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    // width: '463px',
    maxWidth: '100%',
    maxHeight: '100%',
    border: 'none',
    borderRadius: 'unset',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: '0 0'
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    zIndex: '5'
  }
}

class LicenseModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = { clipboardText: 'Copy to clipboard' }

    this.clipboardClick = this.clipboardClick.bind(this)
  }

  clipboardClick() {
    navigator.clipboard.writeText(this.props.serialNumber)

    this.setState({
      clipboardText: 'Copied!'
    })
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={() => this.props.closeLicenseModal()}
        style={licenseModalStyles}
        ariaHideApp={false}
      >
        <div className='UserRentToOwnProduct-licenseModal'>
          <div className='UserRentToOwnProduct-licenseModal-header'>
            <div className='UserRentToOwnProduct-licenseModal-header-info'>
              <div className='UserRentToOwnProduct-licenseModal-header-info-text'>
                Your product license for:
              </div>
              <div className='UserRentToOwnProduct-licenseModal-header-info-value'>
                {this.props.productName}
              </div>
            </div>
            <div className='UserRentToOwnProduct-licenseModal-header-copy'>
              <div className='UserRentToOwnProduct-licenseModal-header-copy-text'>
                {this.state.clipboardText}
              </div>
              <div className='UserRentToOwnProduct-licenseModal-header-copy-icon' onClick={() => this.clipboardClick()}>
              </div>
            </div>
          </div>
          <div className='UserRentToOwnProduct-licenseModal-body'>
            <div className='UserRentToOwnProduct-licenseModal-body-serialValue'>
              {this.props.serialNumber}
            </div>
            <div className='UserRentToOwnProduct-licenseModal-body-closeButton' onClick={() => this.props.closeLicenseModal()}>
              Dismiss
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

LicenseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeLicenseModal: PropTypes.func.isRequired,
  productName: PropTypes.string.isRequired,
  serialNumber: PropTypes.string
}

export default LicenseModal
