import React from 'react'
import UserCourseShort from './UserCourseShort'
import UserProductImage from './UserProductImage'
import UserProductMeta from './UserProductMeta'
import UserRate from './UserRate'
import UserProductGuide from './UserProductGuide'
import UserCoursePlay from './UserCoursePlay'
import UserRateDetails from './UserRateDetails'

class UserCourse extends React.Component {
    state = { expanded: false };

    handleExpander = () => {
        this.setState({ expanded: true })
    };

    render() {
        if (!this.state.expanded) {
            return <UserCourseShort order_item={ this.props.order_item } onExpand={ this.handleExpander }/>
        }

        return (
            <div className="userproduct">
                <a name={ 'item-' + this.props.order_item.course.course_id } title={ '' }></a>
                { this.state.expanded ? <UserProductImage product={ this.props.order_item.course } /> : null }
                <div className="userproduct-data">
                    <UserProductMeta product={ this.props.order_item.course } />
                </div>
                <div className="userproduct-footer">
                    <UserRate order_item={ this.props.order_item } />
                    <UserProductGuide order_item={ this.props.order_item } />
                    <UserCoursePlay order_item={ this.props.order_item } expanded={ true } />
                </div>
                <UserRateDetails order_item_id={ this.props.order_item.order_item_id } />
            </div>
        )
    }
}

export default UserCourse
