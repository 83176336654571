import React from "react"
import _ from "../../src/lodash"
import GiftItem from "./GiftItem"

class RecommendedProductsListModal extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      isModalOpen,
      recommendedProducts,
      fallbackProducts,
      recommendationsService,
      onCloseClick,
    } = this.props

    if (!isModalOpen) return null

    const recommendedItems =
      _.isEmpty(recommendedProducts) || !recommendationsService
        ? fallbackProducts
        : recommendedProducts

    return (
      <div className="CartGiftItemModal">
        <div className="CartGiftItemModal-content">
          <div className="CartGiftItemModal-content-header">
            <div className="CartGiftItemModal-content-header-title">
              Recommended Products For You
            </div>
            <div
              className="CartGiftItemModal-content-header-cross"
              onClick={(e) => {
                onCloseClick(e)
              }}
            ></div>
          </div>
          <div className="CartGiftItemModal-giftsList">
            {recommendedItems.map((item) => {
              const product = item.product ?? item
              const frId = item.fresh_relevance_id
              const productUrl =
                item.product_url !== undefined
                  ? item.product_url
                  : item.link_to

              return (
                <GiftItem
                  key={product.product_id}
                  product={product}
                  productUrl={productUrl}
                  frId={frId}
                  fallbackImageSrc={this.props.fallbackImageSrc}
                  alreadyInCartProducts={this.props.alreadyInCartProducts}
                  recommendationsService={recommendationsService}
                  frMeta={this.props.frMeta}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default RecommendedProductsListModal
