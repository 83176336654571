import React from 'react'

class Oses extends React.Component {
    render() {
        var oses = this.props.oses,
            mac = oses && oses.mac,
            pc = oses && oses.pc

        return (
            <div className="oses">
                { mac ? <span className="oses-mac">mac</span> : null }
                { pc ? <span className="oses-pc">pc</span> : null }
            </div>
        )
    }
}
export default Oses
