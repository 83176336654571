import React from 'react'
import PropTypes from 'prop-types';

class UserTrialDownloadOses extends React.Component {
  static propTypes = {
      trial: PropTypes.object.isRequired,
      pc: PropTypes.bool.isRequired
  };

  handleChange = (pc) => {
      this.props.onChange(pc)
  };

  render() {
      var prefix = 'item-' + this.props.trial.trial_id + '-'
      var bothOsesAvailable = this.props.trial.os.pc_available && this.props.trial.os.mac_available
      var pcSelected = bothOsesAvailable && this.props.pc

      return ( bothOsesAvailable ?
          <form className="usertrial-download-oses">
              <input ref="pc" type="radio" name="pc" value="true" onChange={ this.handleChange.bind(this, true) } id={ prefix + 'pc' } checked={ pcSelected }/>
              <label className="_pc" htmlFor={prefix + 'pc'}>pc</label>

              <input type="radio" name="pc" value="false" onChange={ this.handleChange.bind(this, false) } id={prefix + 'mac'} checked={ !pcSelected }/>
              <label className="_mac" htmlFor={prefix + 'mac'}>mac</label>
          </form> :
          null
      )
  }
}

export default UserTrialDownloadOses
