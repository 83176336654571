import React from 'react'
import PropTypes from 'prop-types'
import csrfToken from '../../src/csrf_token'

class NewsletterContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      termsCheckboxValue: false,
      newsCheckboxValue: this.props.newsletter_subscription,
      marketingCheckboxValue: this.props.other_newsletter_subscription
    }
  }

  termsCheckboxClicked(event) {
    const isCheckboxChecked = event.target.checked

    if (isCheckboxChecked === true) {
      this.setState({
        termsCheckboxValue: true
      })

      Mess.publish(`terms_checkbox_change`, 'yes')
    } else {
      this.setState({
        termsCheckboxValue: false
      })

      Mess.publish(`terms_checkbox_change`, 'no')
    }
  }

  newsCheckboxClicked(event) {
    const isCheckboxChecked = event.target.checked

    if (isCheckboxChecked === true) {
      this.setState({
        newsCheckboxValue: true
      })

      Mess.publish(`news_checkbox_change`, true)
    } else {
      this.setState({
        newsCheckboxValue: false
      })

      Mess.publish(`news_checkbox_change`, false)
    }
  }

  marketingCheckboxClicked(event) {
    const isCheckboxChecked = event.target.checked

    if (isCheckboxChecked === true) {
      this.setState({
        marketingCheckboxValue: true
      })

      Mess.publish(`marketing_checkbox_change`, true)
    } else {
      this.setState({
        marketingCheckboxValue: false
      })

      Mess.publish(`marketing_checkbox_change`, false)
    }
  }

  render() {
    const errorMsg = (this.props.errors && this.props.errors[0] && this.props.errors[0].terms && this.props.errors[0].terms[0])

    return (
      <div className="NewsletterContainer">
        <div className="NewsletterContainer-header">
          Newsletter
        </div>

        <div className="NewsletterContainer-body">
          <div className="NewsletterContainer-body-title">
            Want access to exclusive offers and the latest releases? Sign up to our newsletter!
          </div>

          <div className="NewsletterContainer-body-checkboxRow">
            <div className="lform">
              <input type="checkbox"
                     id="terms-checkbox"
                     value="yes"
                     checked={this.state.termsCheckboxValue}
                     onChange={(event) => this.termsCheckboxClicked(event)} />
              <label htmlFor="terms-checkbox" className="NewsletterContainer-body-checkboxRow-text">
                I accept the <a href="/terms" target="_blank">Terms & Conditions</a>.&nbsp;<span class="red-asterisk">*</span>
                <div className="NewsletterContainer-body-checkboxRow-text-lineBreak" />
                For information on how we use your data and keep it safe, please refer to our 
                &nbsp;<a href="/privacy" target="_blank">privacy policy</a>.
              </label>
            </div>
          </div>
          {
            errorMsg && (
              <div className="NewsletterContainer-body-error">
                { errorMsg }
              </div>
            )
          }
          <div className="NewsletterContainer-body-checkboxRow NewsletterContainer-body-news">
            <div className="lform">
              <input type="checkbox"
                     id="news-checkbox"
                     onChange={(event) => this.newsCheckboxClicked(event)}
                     checked={this.state.newsCheckboxValue} />
               <label htmlFor="news-checkbox" className="NewsletterContainer-body-checkboxRow-text">
                 At Plugin Boutique, we like to keep our customers up to date with the latest product releases and promotions. Please check this box to confirm you are happy for us to email you.
               </label>
            </div>
          </div>

          <div className="NewsletterContainer-body-checkboxRow NewsletterContainer-body-marketing">
            <div className="lform">
              <input id="marketing-checkbox"
                     type="checkbox"
                     onChange={(event) => this.marketingCheckboxClicked(event)}
                     checked={this.state.marketingCheckboxValue} />
               <label htmlFor="marketing-checkbox" className="NewsletterContainer-body-checkboxRow-text">
                 Additionally, you may be interested to receive marketing communications on new releases and promotions from other companies within our group. Group Companies include Loopmasters Ltd. Please tick the box if you wish to receive these offers
               </label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

NewsletterContainer.propTypes = {
  newsletter_subscription: PropTypes.bool.isRequired,
  other_newsletter_subscription: PropTypes.bool.isRequired,
  errors: PropTypes.array
}

export default NewsletterContainer
