import React from 'react'
import ProcessLine from './ProcessLine'
import DistributorMessageDetails from './DistributorMessageDetails'
import csrfToken from '../src/csrf_token'

class DistributorMessage extends React.Component {
	constructor(props) {
		super(props)
		this.state = { expanded: false, details: null, processing: false }
	}

	handle_expand() {
		if (!this.state.details) {
			let message_id = this.props.message.message_id

			this.setState({ processing: true })

			$.ajax({
				url: '/distributor/message_details/' + message_id,
				dataType: 'json',
				headers: {
					"X-CSRF-Token": csrfToken()
				},
				method: 'post',
				success: (data) => this.setState({ processing: false, details: data, expanded: true }),
				error: (xhr) => this.setState({ processing: false })
			})

		} else {
			this.setState({ expanded: true })
		}
	}

	handle_collapse() {
		this.setState({ expanded: false })
	}

	render() {

		var message = this.props.message,
			handle_expand = this.handle_expand.bind(this),
			handle_collapse = this.handle_collapse.bind(this),
			details = this.state.details,
			expanded = this.state.expanded && (details != null),
			clazz = 'distributormessage' + (message.new_message ?  ' _newmessage' : "" )



		return (
			<li className="distributormessage">
				<ProcessLine processing={ this.state.processing }/>

				<div className="distributormessage-header" onClick={ handle_expand }>
					<p className="_date">{ message.created_at }</p>
					<p className="_new">{ message.new_message ? <em>new</em> : null }</p>
					<p className="_subject">{ message.title }</p>
				</div>


				{ expanded ? <DistributorMessageDetails message={ details } onClose={ handle_collapse }/> : null }
			</li>
		)
	}
}
export default DistributorMessage
