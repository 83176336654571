import React from 'react'

class UserProductSerial extends React.Component {
    render() {
        const serial_number = this.props.serial_number
        const values_type_pairs = serial_number.values_type_pairs

        return (
            <div className="userproduct-serial">
                <h4>Serial Number</h4>
                <div className="userproduct-serial-value">
                    {
                        _.map(values_type_pairs, function(value_type_pair, index) {
                            const value = value_type_pair.value

                            if (!value_type_pair.is_url) {
                              return <pre key={index}>{ value }</pre>
                            } else {
                              return <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
                            }
                        })
                    }
                    { serial_number.key_file ? <a href={ serial_number.key_file.url } target="_blank">{ serial_number.key_file.name }</a> : null }
                </div>
            </div>
        )
    }
}

export default UserProductSerial
