import React from 'react'

class AudioPlayerProgress extends React.Component {
  state = { progress: this.props.progress };

  componentWillReceiveProps(nextProps) {
      this.setState({ progress: nextProps.progress })
  }

  handle_click = (event) => {
      var target = event.currentTarget,
          rect = target.getBoundingClientRect(),
          client_x = event.clientX,
          ratio = (client_x - rect.left) / rect.width

      this.props.onChange(ratio)
  };

  handle_drag = (event) => {
      if (event.buttons > 0) {
          var target = event.currentTarget,
              rect = target.getBoundingClientRect(),
              client_x = event.clientX,
              ratio = (client_x - rect.left) / rect.width

          this.props.onChange(ratio)
      }
  };

  render() {
      return (
          <div className="audioplayer-progress" onClick={ this.handle_click } onMouseMove={ this.handle_drag }>
              <div className="audioplayer-progress-bar" style={ { width: this.props.progress + '%' } }>
              </div>
          </div>
      )
  }
}

export default AudioPlayerProgress

