import React from 'react';

class SearchIcon extends React.Component {
    handleClick = () => {
        event.preventDefault()
        Mess.publish('toggle_search')
    };

    render() {
        const topmenuSearchDummy = document.querySelector('.topmenu-items .topmenu-search .topmenu_search_dummy a')
        topmenuSearchDummy.style.display = 'none'

        return (
            <a href="#search" className="topmenu-item _search" title="Open search box" onClick={ this.handleClick }>search</a>
        )
    }
}
export default SearchIcon
