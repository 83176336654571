import React from 'react'

class SamplepackPrice extends React.Component {
    render() {
        var available = this.props.item.has_tokens,
            price = this.props.item.reward_tokens,
            clazz = 'samplepack-price' + (available ? ' _available' : '')

        return <div className={ clazz }>{ price }</div>
    }
}

export default SamplepackPrice
