import React from 'react'

class CourseMovie extends React.Component {
  componentDidMount() {
    this.refs.video.setAttribute("controlsList","nodownload");
    this.refs.video.setAttribute("oncontextmenu","return false;");
  }

  render() {
    return (
      <div className="courseplayer-movie">
        <video ref="video" className="w-100" controls>
          <source type="video/mp4" src={ this.props.module.url }></source>
        </video>
      </div>
    )
  }
}

export default CourseMovie
