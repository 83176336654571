import React from 'react'
import Tick from './Tick'

const DistributorProduct = ({ item_id, name, commision, disabled, onToggleProduct }) => (
    <li className="distributorproduct">
        <Tick
            off={ disabled }
            onClick={ () => onToggleProduct(item_id, disabled) }/>
          { name } | { commision }
    </li>
)
export default DistributorProduct
