import React from "react"
import PropTypes from "prop-types"

/**
 * Radio-button like input with custom styles
 *
 * Props:
 * * options - array of option objects
 * * option structure: [{ label: 'label', value: 'value' }}
 *
 * * selectedIndex - number
 * * selectedValue - string. takes priority over selectedIndex when both given
 * * onChange - func
**/
export default class PibSelectRadio extends React.Component {
  state = {
    selectedIndex: this.props.noInitialValue ? null : this.props.selectedIndex || 0
  }

  callOnChange = (idx) => {
    const { onChange, options } = this.props

    onChange && onChange(options[idx].value)
  }

  handleSelect = (idx) => {
    const { disabled } = this.props

    if (disabled) return

    this.setState({ selectedIndex: idx })
    this.callOnChange(idx)
  }

  componentDidMount() {
    // ensure external state is in sync with local
    const { options, selectedValue } = this.props

    if (selectedValue) {
      options.map((option, idx) => {
        if (option.value === selectedValue) {
          this.setState({ selectedIndex: idx }, () => this.callOnChange(idx))
        }
      })
    } else if (!this.props.noInitialValue) {
      this.callOnChange(this.state.selectedIndex)
    }
  }

  render() {
    const { className, options, disabled } = this.props
    const { selectedIndex } = this.state

    return <div className={`pibSelectRadio ${className} ${disabled && 'disabled'}`}>
      { options.map((option, idx) => {
        return <PibSelectRatioOption key={idx} idx={idx} label={option.label}
                                     handleSelect={this.handleSelect} selected={idx === selectedIndex} />
      })}

      <input type="hidden" value={options[selectedIndex]?.value || ''}/>
    </div>
  }
}

PibSelectRadio.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number,
  selectedValue: PropTypes.string,
  onChange: PropTypes.func,
  noInitialValue: PropTypes.bool,
  disabled: PropTypes.bool
}

const PibSelectRatioOption = ({ label, idx, handleSelect, selected }) => (
  <div onClick={() => handleSelect(idx)} className={`pibSelectRadio-option ${selected ? 'selected' : ''}`}>
    <span className="point" />
    <span className="label">{label}</span>
  </div>
)

PibSelectRatioOption.propTypes = {
  label: PropTypes.string,
  idx: PropTypes.number,
  handleSelect: PropTypes.func,
  selected: PropTypes.bool
}
