import React from 'react'

class UserProductGuide extends React.Component {
    state = { idx: 0 };

    handle_change = (event) => {
        var idx = this.refs._version.value
        this.setState({ idx: idx })
    };

    render() {
        var guides = this.props.guides || [],
            idx = this.state.idx,
            guide = guides[idx],
            single = guides.length == 1

        if (guides.length == 0) {
            return (
                <div className="userproduct-guide">
                </div>
            )
        }

        return (
            <div className="userproduct-guide">
                <a href={ guide.link } target={ guide.target } title={ single ? 'Installation instructions' : 'View documents' } className={ 'button-instructions' + (single ? ' _single' : '') }>{ single ? 'installation instructions' : 'view documents'}</a>

                { single ? null : (
                <div className="userproduct-download-versions">
                    <form className="lform">
                        <div className="select">
                            <select name="version" ref="_version" onChange={ this.handle_change }>
                                { _.map(guides, function(data, idx) {
                                    return <option key={ idx } value={ idx } >{ data.description }</option>
                                }) }
                            </select>
                        </div>
                    </form>
                </div>
                ) }
            </div>
        )
    }
}
export default UserProductGuide
